import Realm from "realm";

export interface RecurringPatternProperties {
    recurring_type: number;
    separation_count: number;
    max_num_of_occurrences?: number;
    end_date?: string;
    // days_of_week?: number[];
}

export class RecurringPattern extends Realm.Object implements RecurringPatternProperties {
    recurring_type!: number;
    separation_count!: number;
    max_num_of_occurrences?: number;
    end_date?: string;
    // days_of_week_list!: Realm.List<number>;

    /*
    get days_of_week(): number[] {
        return this.days_of_week_list.map((value) => value);
    }

    set days_of_week(value: number[]) {
        while (this.days_of_week_list.length > 0) {
            this.days_of_week_list.pop();
        }
        value.forEach((day) => {
            this.days_of_week_list.push(day);
        });
    }
     */

    static schema: Realm.ObjectSchema = {
        name: 'lesson_recurring_pattern',
        embedded: true,
        properties: {
            recurring_type: 'int',
            separation_count: 'int',
            max_num_of_occurrences: 'int?',
            end_date: 'string?',
            // days_of_week_list: 'int[]',
        }
    }
}