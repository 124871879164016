import React, {Fragment} from "react";
import {Helmet} from "react-helmet";
import {ThemeProvider} from "styled-components";
import Sticky from 'react-stickynode';
import {DrawerProvider} from "../common/contexts/DrawerContext";
import Navbar from "../containers/LandingPage/Navbar";
import '../common/assets/css/flaticon.css';
import 'swiper/css/bundle';
import '../common/assets/css/react-slick.css';
import '../common/assets/css/rc-collapse.css';
import '../common/assets/css/rc-drawer.css';
import 'animate.css';
import ResetCSS from '../common/assets/css/style';
import 'rc-collapse/assets/index.css';
import AboutSection from "../containers/LandingPage/AboutSection";
import Footer from "../containers/LandingPage/Footer";
import {ContentWrapper, GlobalStyle} from "../containers/LandingPage/webAppCreative.style";
import {theme} from "../common/theme/webAppCreative";
import Banner from "../containers/LandingPage/Banner";
import Clients from "../containers/LandingPage/Clients";

export default function LandingPage() {
    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <Helmet>
                    <meta name="theme-color" content="#2065D1"/>
                </Helmet>

                <ResetCSS/>
                <GlobalStyle/>

                <ContentWrapper style={{flex: 1}}>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <Banner/>
                    <Clients/>
                    <AboutSection/>
                    <Footer/>
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    )
}