import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import {Timetable} from "../models/Timetable";
import ObjectID from "bson-objectid";
import {Planner} from "../models/Planner";

export interface TimetableInsertOptions {
    plannerId: string;
    title: string;
    color: number;
    start_date?: string;
    end_date?: string;
    scheduling: number;
    time_format: number;
    number_of_periods?: number;
    number_of_weeks?: number;
    start_week_date?: string;
    start_week?: number;
    shift_number_of_days?: number;
    shift_start_day?: string;
    shift_days_of_week: number[];
}

export interface TimetableUpdateOptions {
    title: string;
    color: number;
    start_date?: string;
    end_date?: string;
    scheduling: number;
    time_format: number;
    number_of_periods: number;
    number_of_weeks: number;
    start_week_date: string;
    start_week: number;
    shift_number_of_days: number;
    shift_start_day: string;
    shift_days_of_week: number[];
}

export function useTimetableManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const timetables = useQuery(Timetable, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a homework in the database.
     */
    const createTimetable = useCallback((options: TimetableInsertOptions) => {
        realm.write(() => {
            realm.create(Timetable, {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                planner: realm.objectForPrimaryKey(Planner, options.plannerId) ?? undefined,
                ...options
            });
        });
    }, [realm, user.id]);

    /**
     * Update a homework in the database.
     */
    const updateTimetable = useCallback((objectId: string, options: TimetableUpdateOptions) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Timetable, objectId);
            if (!object) return;

            object.title = options.title;
            object.color = options.color;
            object.start_date = options.start_date;
            object.end_date = options.end_date;
            object.scheduling = options.scheduling;
            object.time_format = options.time_format;
            object.number_of_periods = options.number_of_periods;
            object.number_of_weeks = options.number_of_weeks;
            object.start_week_date = options.start_week_date;
            object.start_week = options.start_week;
            object.shift_number_of_days = options.shift_number_of_days;
            object.shift_start_day = options.shift_start_day;

            while (object.shift_days_of_week.length > 0) {
                object.shift_days_of_week.pop();
            }
            options.shift_days_of_week.forEach(day => object.shift_days_of_week.push(day));
        });
    }, [realm]);

    /**
     * Deletes a timetable from the database.
     */
    const deleteTimetable = useCallback((objectId: string) => {
        realm.write(() => {
            const objects = realm.objects(Timetable).filter(object => object._id === objectId)
            realm.delete(objects);
        });
    }, [realm]);


    return {
        timetables,
        createTimetable,
        updateTimetable,
        deleteTimetable
    }
}