import {useCallback, useEffect, useState} from 'react';
import {useQuery, useRealm, useUser} from '@realm/react';
import {Planner} from '../models/Planner';
import ObjectID from "bson-objectid";

interface PlannerInsertOptions {
    title: string;
}

interface PlannerUpdateOptions {
    title: string;
}

/**
 * Provides functions for managing changes to the planners in the Realm,
 * such as adding, updating, and deleting planners.
 */
export function usePlannerManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const planners = useQuery(Planner, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a planner in the database.
     */
    const createPlanner = useCallback((options: PlannerInsertOptions) => {
        realm.write(() => {
            realm.create(Planner, {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                title: options.title
            });
        });
    }, [realm, user.id]);

    /**
     * Updates a planner in the database.
     */
    const updatePlanner = useCallback((plannerId: string, options: PlannerUpdateOptions) => {
        realm.write(() => {
            const planner = realm.objectForPrimaryKey(Planner, plannerId);
            if (planner) {
                planner.title = options.title;
            }
        });
    }, [realm]);

    /**
     * Deletes a planner from the database.
     */
    const deletePlanner = useCallback((plannerId: string) => {
        realm.write(() => {
            const planner = realm.objectForPrimaryKey(Planner, plannerId);
            if (planner) {
                realm.delete(planner);
            }
        });
    }, [realm]);

    return {
        planners,
        createPlanner,
        updatePlanner,
        deletePlanner
    }
}