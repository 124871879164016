import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {alpha, styled, useTheme} from '@mui/material/styles';
import {Box, Drawer} from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import Logo from '../../../common/components/Logo';
import Scrollbar from '../../../common/components/scrollbar';
import navConfig from "./config";
import NavSection from "../../../common/components/nav-section";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
    openNav: PropTypes.bool,
    onCloseNav: PropTypes.func,
};

export default function Nav(
    props: {
        openNav: boolean,
        onCloseNav: () => void
    }
) {
    const theme = useTheme()
    const {pathname} = useLocation();

    const isDesktop = useResponsive('up', 'md');

    useEffect(() => {
        if (!isDesktop) {
            if (props.openNav) {
                props.onCloseNav();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderContent = (
        <Scrollbar
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: 1,
                backgroundColor: '#ffffff',
                [theme.breakpoints.up('md')]: {
                    backgroundColor: '#fbfbfa'
                }
            }}
        >
            <Box sx={{px: 4, pt: 3, pb: 2, display: 'block'}}>
                <Logo linkTo={'/support'} />
            </Box>

            <NavSection data={navConfig} sx={{mt: 2}}/>

        </Scrollbar>
    );

    return (
        <Box
            component="nav"
            display={'flex'}
            sx={{
                "& .MuiDrawer-root": {
                    display: 'flex',
                    flex: 1
                }
            }}
        >
            {isDesktop ? (
                <Drawer
                    open={props.openNav}
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            display: 'flex',
                            flex: 1,
                            width: NAV_WIDTH,
                            bgcolor: 'background.default',
                            borderColor: '#f5f5f4'
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            ) : (
                <Drawer
                    open={props.openNav}
                    onClose={props.onCloseNav}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    PaperProps={{
                        sx: {width: NAV_WIDTH},
                    }}
                >
                    {renderContent}
                </Drawer>
            )}
        </Box>
    );
}
