import Realm, {ObjectSchema} from 'realm';
import {Planner} from "./Planner";

export class Subject extends Realm.Object {
    _id!: string;
    owner_id!: string;
    planner?: Planner;
    // terms
    // teachers
    name!: string;
    color!: number;
    room?: string;
    note?: string;
    // targets
    created_on?: string;

    static schema: ObjectSchema = {
        name: 'subject',
        primaryKey: '_id',
        properties: {
            _id: 'string',
            owner_id: 'string',
            planner: 'planner',
            name: 'string',
            color: 'int',
            room: 'string?',
            note: 'string?',
            created_on: 'string?',
        }
    }
}