import {useCookies} from "react-cookie";
import {SELECTED_TIMETABLE_ID_NAME} from "../common/Constants";

export const useSelectedTimetableId = () => {
    // Use react-cookies to access our cookies
    const [cookies, setCookie, removeCookie] = useCookies([SELECTED_TIMETABLE_ID_NAME]);

    // This function allows to save any string in our cookies, under the key "authToken"
    // @ts-ignore
    const setSelectedTimetableId = (selectedTimetableId) => {
        removeCookie(SELECTED_TIMETABLE_ID_NAME)
        setCookie(SELECTED_TIMETABLE_ID_NAME, selectedTimetableId, {maxAge: 31536000});
    }

    // This function removes the key "authToken" from our cookies. Useful to logout
    const removeSelectedTimetableId = () => removeCookie(SELECTED_TIMETABLE_ID_NAME);

    return [cookies[SELECTED_TIMETABLE_ID_NAME], setSelectedTimetableId, removeSelectedTimetableId];
};