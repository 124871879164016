import Realm from "realm";
import moment from "moment";

export interface EventRemindAtProps {
    date: moment.Moment | undefined
    time: moment.Moment | undefined
}

export class EventRemindAt extends Realm.Object {
    date?: string;
    time?: string;

    static schema: Realm.ObjectSchema = {
        name: 'event_remind_at',
        embedded: true,
        properties: {
            date: 'string?',
            time: 'string?',
        }
    }
}