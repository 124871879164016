import React from 'react';
import Container from '../../../common/components/UI/Container';
import Text from '../../../common/components/Text';
import Section, {Title} from './clients.style';
import Box from "@mui/material/Box";
import {Stack} from "@mui/material";

const Clients = () => {
    return (
        <Section>
            <Container width="1400px">
                <Title>
                    <Text content="Join over 1 million students on our Google-awarded platform"/>
                </Title>
                <Stack direction="row" justifyContent={'center'} spacing={2} mt={2}>
                    <Box
                        component={'img'}
                        src={'/assets/landing/client-android.svg'}
                        alt="logo"
                        sx={{width: 128, mr: 1}}
                    />
                    <Box
                        component={'img'}
                        src={'/assets/landing/client-google.svg'}
                        alt="logo"
                        sx={{width: 96}}
                    />
                </Stack>
            </Container>
        </Section>
    );
};

export default Clients;
