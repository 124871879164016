import {useTheme} from "@mui/material/styles";
import * as React from "react";
import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import {CloseButton} from "../TaskDialog";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface Props {
    date: moment.Moment | null | undefined
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    clearDate: () => void
}

export default function DateButton(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    const canClear = props.date !== null && props.date !== undefined
    const body = props.date ? props.date.format("ddd, D MMMM YYYY") : t("task_dialog.add_date")

    return (
        <Stack direction='row' alignItems='center'>
            <Button
                color='inherit'
                sx={{width: '100%', height: '48px'}}
                onClick={props.onClick}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{width: '100%'}}
                >
                    <Iconify
                        // @ts-ignore
                        icon="material-symbols:calendar-month-outline-rounded"
                        color={theme.palette.text.secondary}
                        width={18}
                        height={18}
                        sx={{flexShrink: 0}}
                    />
                    <Typography
                        variant='body2'
                        noWrap={true}
                        color={props.date ? theme.palette.text.primary : theme.palette.text.secondary}
                        ml={2}
                    >
                        {body}
                    </Typography>
                </Stack>
            </Button>
            {
                canClear && <CloseButton onClick={props.clearDate}/>
            }
        </Stack>
    )
}