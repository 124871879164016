import {useCookies} from "react-cookie";
import {TOKEN_NAME} from "../common/Constants";

// Custom hook to handle authToken - we use composition to decouple the auth system and its storage
export const useAuthToken = () => {
    // Use react-cookies to access our cookies
    const [
        cookies,
        setCookie,
        removeCookie
    ] = useCookies([TOKEN_NAME]);

    // This function allows to save any string in our cookies, under the key "authToken"
    // @ts-ignore
    const setAuthToken = (authToken) => {
        removeCookie(TOKEN_NAME)
        setCookie(TOKEN_NAME, authToken, {maxAge: 31536000});
    }

    // This function removes the key "authToken" from our cookies. Useful to logout
    const removeAuthToken = () => {
        removeCookie(TOKEN_NAME);
        setCookie(TOKEN_NAME, "", {maxAge: 0});
    }

    return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken];
};