import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useState} from "react";
import {Button, Card, Checkbox, DialogActions, IconButton, Snackbar, Stack, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import {RecurringPatternDecorator, RecurringType} from "../../../../../model/RecurringPatternDecorator";
import {BootstrapDialog, Transition} from "../../../../../common/components/BootstrapDialog";
import Iconify from "../../../../../common/components/iconify";
import {Scheduling, TimetableDecorator} from "../../../../../model/TimetableDecorator";
import SaveButton from "../../../../../common/components/SaveButton";

export default function OccurrenceRepeatDialog(
    props: {
        open: boolean
        handleClose: () => void
        timetable: TimetableDecorator,
        isRecurring: boolean | undefined | null,
        pattern: RecurringPatternDecorator | undefined | null,
        handleOnRepeatChanged: (isRecurring: boolean, pattern: RecurringPatternDecorator | undefined | null) => void
    }
) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    /* State */
    const [
        stateSelection,
        setSelection
    ] = useState<'never' | 'default' | 'daily' | 'weekly' | 'biweekly' | 'monthly' | 'yearly' | 'custom' | undefined>(undefined)

    if (stateSelection === undefined && props.isRecurring !== undefined && props.isRecurring !== null) {
        setSelection(parseSelection(props.isRecurring ?? false, props.pattern))
    }

    // [Errors]
    const [
        error,
        setError
    ] = useState<string | undefined>(undefined)

    // -----------------------------------------------------------------------------------------------------------------
    const handleSaveClick = () => {
        switch (stateSelection) {
            case 'never':
                props.handleOnRepeatChanged(false, undefined)
                break
            case 'daily':
                props.handleOnRepeatChanged(
                    true,
                    new RecurringPatternDecorator({
                        recurring_type: RecurringType.DAILY,
                        separation_count: 0
                    })
                )
                break
            case 'weekly':
                props.handleOnRepeatChanged(
                    true,
                    new RecurringPatternDecorator({
                        recurring_type: RecurringType.WEEKLY,
                        separation_count: 0
                    })
                )
                break
            case 'biweekly':
                props.handleOnRepeatChanged(
                    true,
                    new RecurringPatternDecorator({
                        recurring_type: RecurringType.WEEKLY,
                        separation_count: 1
                    })
                )
                break
            case 'monthly':
                props.handleOnRepeatChanged(
                    true,
                    new RecurringPatternDecorator({
                        recurring_type: RecurringType.MONTHLY,
                        separation_count: 0
                    })
                )
                break
            case 'yearly':
                props.handleOnRepeatChanged(
                    true,
                    new RecurringPatternDecorator({
                        recurring_type: RecurringType.YEARLY,
                        separation_count: 0
                    })
                )
                break
            default:
                props.handleOnRepeatChanged(true, undefined)
                break
        }

        handleClose()
    }

    const handleClose = () => {
        setSelection(undefined)

        props.handleClose()
    }

    const getDefaultDescription = () => {
        switch (props.timetable.getScheduling()) {
            case Scheduling.WEEKLY:
                return t('timetable.lesson.commit_dialog.occurrence.repeat.every_week_format',
                    {count: props.timetable.getNumberOfWeeks()})
            default:
                return t('timetable.lesson.commit_dialog.occurrence.repeat.on_numbered_day')
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            open={props.open}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{pt: 1.5, pb: 1.5, pl: 3, pr: 2, backgroundColor: theme.palette.primary.main}}
            >
                <Typography
                    flex={1}
                    fontWeight={600}
                    fontSize={'1.15em'}
                    noWrap={true}
                    color={theme.palette.primary.contrastText}
                >

                    {t('timetable.lesson.commit_dialog.occurrence.repeat_label')}
                </Typography>
                <IconButton onClick={handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <Divider/>

            <DialogContent>
                <Stack direction="column">
                    <Card
                        variant={'elevation'}
                        sx={{flex: 1, px: 2, py: 1.5, border: 'none !important'}}
                    >
                        <Stack direction="row" alignItems="center" flex={1}>
                            <Stack direction={"column"} alignItems={"flex-start"} flex={1}>
                                <Typography
                                    variant={'body1'}
                                    fontWeight={600}
                                    noWrap={true}
                                    color={theme.palette.text.primary}
                                >
                                    {t('timetable.lesson.commit_dialog.occurrence.repeat.default')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    noWrap={true}
                                    color={theme.palette.text.secondary}
                                >
                                    {getDefaultDescription()}
                                </Typography>
                            </Stack>
                            <Checkbox
                                checked={stateSelection === 'default'}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setSelection('default')
                                    }
                                }}
                            />
                        </Stack>
                    </Card>

                    <Card
                        variant={'elevation'}
                        sx={{flex: 1, mt: 3, py: 1, px: 2, border: 'none !important'}}
                    >
                        <Stack direction="row" alignItems="center" flex={1}>
                            <Stack direction={"column"} alignItems={"flex-start"} flex={1}>
                                <Typography
                                    variant={'body1'}
                                    fontWeight={600}
                                    noWrap={true}
                                    color={theme.palette.text.primary}
                                >
                                    {t('timetable.lesson.commit_dialog.occurrence.repeat.never')}
                                </Typography>
                            </Stack>
                            <Checkbox
                                checked={stateSelection === 'never'}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setSelection('never')
                                    }
                                }}
                            />
                        </Stack>
                    </Card>

                    <Card
                        variant={'elevation'}
                        sx={{flex: 1, mt: 1, py: 1, px: 2, border: 'none !important'}}
                    >
                        <Stack direction="row" alignItems="center" flex={1}>
                            <Stack direction={"column"} alignItems={"flex-start"} flex={1}>
                                <Typography
                                    variant={'body1'}
                                    fontWeight={600}
                                    noWrap={true}
                                    color={theme.palette.text.primary}
                                >
                                    {t('timetable.lesson.commit_dialog.occurrence.repeat.every_day_format', {count: 1})}
                                </Typography>
                            </Stack>
                            <Checkbox
                                checked={stateSelection === 'daily'}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setSelection('daily')
                                    }
                                }}
                            />
                        </Stack>
                    </Card>

                    <Card
                        variant={'elevation'}
                        sx={{flex: 1, mt: 1, py: 1, px: 2, border: 'none !important'}}
                    >
                        <Stack direction="row" alignItems="center" flex={1}>
                            <Stack direction={"column"} alignItems={"flex-start"} flex={1}>
                                <Typography
                                    variant={'body1'}
                                    fontWeight={600}
                                    noWrap={true}
                                    color={theme.palette.text.primary}
                                >
                                    {t('timetable.lesson.commit_dialog.occurrence.repeat.every_week_format', {count: 1})}
                                </Typography>
                            </Stack>
                            <Checkbox
                                checked={stateSelection === 'weekly'}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setSelection('weekly')
                                    }
                                }}
                            />
                        </Stack>
                    </Card>

                    <Card
                        variant={'elevation'}
                        sx={{flex: 1, mt: 1, py: 1, px: 2, border: 'none !important'}}
                    >
                        <Stack direction="row" alignItems="center" flex={1}>
                            <Stack direction={"column"} alignItems={"flex-start"} flex={1}>
                                <Typography
                                    variant={'body1'}
                                    fontWeight={600}
                                    noWrap={true}
                                    color={theme.palette.text.primary}
                                >
                                    {t('timetable.lesson.commit_dialog.occurrence.repeat.every_week_format', {count: 2})}
                                </Typography>
                            </Stack>
                            <Checkbox
                                checked={stateSelection === 'biweekly'}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setSelection('biweekly')
                                    }
                                }}
                            />
                        </Stack>
                    </Card>

                    <Card
                        variant={'elevation'}
                        sx={{flex: 1, mt: 1, py: 1, px: 2, border: 'none !important'}}
                    >
                        <Stack direction="row" alignItems="center" flex={1}>
                            <Stack direction={"column"} alignItems={"flex-start"} flex={1}>
                                <Typography
                                    variant={'body1'}
                                    fontWeight={600}
                                    noWrap={true}
                                    color={theme.palette.text.primary}
                                >
                                    {t('timetable.lesson.commit_dialog.occurrence.repeat.every_month_format', {count: 1})}
                                </Typography>
                            </Stack>
                            <Checkbox
                                checked={stateSelection === 'monthly'}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setSelection('monthly')
                                    }
                                }}
                            />
                        </Stack>
                    </Card>

                    <Card
                        variant={'elevation'}
                        sx={{flex: 1, mt: 1, py: 1, px: 2, border: 'none !important'}}
                    >
                        <Stack direction="row" alignItems="center" flex={1}>
                            <Stack direction={"column"} alignItems={"flex-start"} flex={1}>
                                <Typography
                                    variant={'body1'}
                                    fontWeight={600}
                                    noWrap={true}
                                    color={theme.palette.text.primary}
                                >
                                    {t('timetable.lesson.commit_dialog.occurrence.repeat.every_year_format', {count: 1})}
                                </Typography>
                            </Stack>
                            <Checkbox
                                checked={stateSelection === 'yearly'}
                                onChange={(event, checked) => {
                                    if (checked) {
                                        setSelection('yearly')
                                    }
                                }}
                            />
                        </Stack>
                    </Card>


                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={2}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <SaveButton handleSaveClick={handleSaveClick} isSaving={false} text={t("save")}/>
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='inherit'
                        sx={{boxShadow: 'none'}}
                    >
                        {t("cancel")}
                    </Button>
                </Stack>
            </DialogActions>

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => {
                    setError(undefined)
                }}
                message={error}
            />

        </BootstrapDialog>
    )
}


const parseSelection = (
    isRecurring: boolean,
    pattern: RecurringPatternDecorator | undefined | null
): 'never' | 'default' | 'daily' | 'weekly' | 'biweekly' | 'monthly' | 'yearly' | 'custom' => {
    if (!isRecurring) {
        return 'never'
    }

    if (pattern === undefined || pattern === null) {
        return 'default'
    }

    switch (pattern.getRecurringType()) {
        case RecurringType.DAILY:
            return pattern.getSeparationCount() === 0 ? 'daily' : 'custom'
        case RecurringType.WEEKLY:
            return pattern.getSeparationCount() === 0 ? 'weekly' : pattern.getSeparationCount() === 1 ? 'biweekly' : 'custom'
        case RecurringType.MONTHLY:
            return pattern.getSeparationCount() === 0 ? 'monthly' : 'custom'
        case RecurringType.YEARLY:
            return pattern.getSeparationCount() === 0 ? 'yearly' : 'custom'
        default:
            return 'custom'
    }
}