import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Iconify from "../../../common/components/iconify";
import {Button, DialogActions, IconButton, Stack, TextareaAutosize, Typography} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTranslation} from "react-i18next";
import {BootstrapDialog, Transition} from "../../../common/components/BootstrapDialog";
import {usePlannerManager} from "../../../hooks/usePlannerManager";

// ---------------------------------------------------------------------------------------------------------------------
export interface PlannerDialogUpdateOptionsProps {
    plannerId: string,
    title?: string | undefined | null
}

interface Props {
    open: boolean
    handleClose: () => void
    updateOptions?: PlannerDialogUpdateOptionsProps | undefined | null
}

export default function PlannerDialog(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [
        title,
        setTitle
    ] = React.useState<string | undefined | null>(undefined);

    if (title === undefined && props.updateOptions?.title) {
        setTitle(props.updateOptions.title)
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {createPlanner, updatePlanner} = usePlannerManager();

    // -----------------------------------------------------------------------------------------------------------------
    // Callbacks
    const handleClose = () => {
        setTitle(undefined)

        props.handleClose()
    }

    const handleSaveClick = () => {
        if (!title) return;

        if (props.updateOptions) {
            updatePlanner(props.updateOptions.plannerId, {title: title});
        } else {
            createPlanner({title: title});
        }

        handleClose();
    }

    // -----------------------------------------------------------------------------------------------------------------
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            open={props.open}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{pt: 1.5, pb: 1.5, pl: 3, pr: 2, backgroundColor: theme.palette.primary.main}}
            >
                <Typography
                    flex={1}
                    fontWeight={600}
                    fontSize={'1.15em'}
                    noWrap={true}
                    color={theme.palette.primary.contrastText}
                >
                    {t(props.updateOptions ? 'planner.commit_dialog.title_edit' : 'planner.commit_dialog.title_add')}
                </Typography>
                <IconButton onClick={handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <Divider/>

            <DialogContent>
                <Stack direction="column">
                    <NameTextArea
                        placeholder={t("task_dialog.add_name") || ""}
                        value={title || ''}
                        theme={theme}
                        onChange={(event: React.ChangeEvent) => {
                            // @ts-ignore
                            setTitle(event.target.value)
                        }}
                        sx={{mb: 2}}
                    />
                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={2}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <Button
                        onClick={handleSaveClick}
                        variant='contained'
                        disabled={!title}
                    >
                        {t("save")}
                    </Button>
                    <Button color='inherit' variant='text' onClick={handleClose}>{t("cancel")}</Button>
                </Stack>
            </DialogActions>
        </BootstrapDialog>
    );
}

// ---------------------------------------------------------------------------------------------------------------------
const NameTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    margin: '3px 0px 8px 8px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600
}));