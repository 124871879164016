import Realm, {ObjectSchema} from 'realm';
import {Planner} from "./Planner";
import {Subject} from "./Subject";
import {EventRemindAt} from "./EventRemindAt";
import {EventStep} from "./EventStep";

export class Homework extends Realm.Object {
    _id!: string;
    owner_id!: string;
    planner?: Planner;
    subject?: Subject;
    title!: string;
    date?: string;
    completed_on?: string;
    archived!: boolean;
    note?: string;
    // metadata: Realm.List<event_field>;
    remind_at_list!: Realm.List<EventRemindAt>;
    steps!: Realm.List<EventStep>;
    created_on?: string;

    static schema: ObjectSchema = {
        name: 'homework',
        primaryKey: '_id',
        properties: {
            _id: 'string',
            owner_id: 'string',
            planner: 'planner',
            subject: 'subject',
            title: 'string',
            date: 'string?',
            completed_on: 'string?',
            archived: 'bool',
            note: 'string?',
            // metadata: 'event_field[]',
            remind_at_list: 'event_remind_at[]',
            steps: 'event_step[]',
            created_on: 'string?',
        }
    }
}