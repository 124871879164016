import moment from "moment/moment";
import {isNumber} from "../utils/number";
import {TimeFormat, TimetableDecorator} from "./TimetableDecorator";
import {LessonOccurrenceDecorator} from "./LessonOccurrenceDecorator";
import {isTwelveHourClock} from "../utils/date";
import {TFunction} from "i18next";
import {Lesson} from "../models/Lesson";

export class LessonWithOccurrenceIdAndDate {
    private readonly timetable: TimetableDecorator;
    private readonly lesson: Lesson;
    private readonly occurrence: LessonOccurrenceDecorator;
    private readonly date: moment.Moment;

    constructor(
        timetable: TimetableDecorator,
        lesson: Lesson,
        occurrence: LessonOccurrenceDecorator,
        date: moment.Moment
    ) {
        this.timetable = timetable;
        this.lesson = lesson;
        this.occurrence = occurrence;
        this.date = date;
    }

    getLesson(): Lesson {
        return this.lesson;
    }

    getOccurrence(): LessonOccurrenceDecorator {
        return this.occurrence;
    }

    getTimetable(): TimetableDecorator {
        return this.timetable
    }

    isRecurring(): boolean {
        return this.occurrence.toProps().is_recurring || Boolean(this.occurrence.toProps().recurring_pattern)
    }

    getDate(): moment.Moment {
        return this.date;
    }

    getTitle(): string | undefined | null {
        if (!this.lesson.isValid()) return undefined;

        if (this.lesson.subject) {
            return this.lesson.subject.name;
        } else {
            return this.lesson.title;
        }
    }

    getColor(): number | undefined | null {
        if (!this.lesson.isValid()) return undefined;

        if (this.lesson.subject) {
            return this.lesson.subject.color;
        } else {
            return this.lesson.color;
        }
    }

    getRoom(): string | undefined | null {
        if (!this.lesson.isValid()) return undefined;

        return this.lesson.room
    }

    getNote(): string | undefined | null {
        if (!this.lesson.isValid()) return undefined;

        return this.lesson.note
    }

    formatStartEndTime(t: TFunction<"translation", undefined, "translation">): string {
        switch (this.timetable.getTimeFormat()) {
            case TimeFormat.PERIOD:
                const start = this.occurrence.toProps().time_start_in_periods
                const end = this.occurrence.toProps().time_end_in_periods

                if (start !== null && start !== undefined && isNumber(start) &&
                    end !== null && end !== undefined && isNumber(end)) {
                    return `${t("timetable.lesson.commit_dialog.occurrence.period_format", {val: start})} ➔ 
                        ${t("timetable.lesson.commit_dialog.occurrence.period_format", {val: end})}`
                } else {
                    return ""
                }

            default: {
                const start = this.occurrence.toProps().time_start_in_minutes
                const end = this.occurrence.toProps().time_end_in_minutes

                if (start !== null && start !== undefined && isNumber(start) &&
                    end !== null && end !== undefined && isNumber(end)) {
                    const startAsDate = moment()
                        .startOf('year')    // Daylight saving time
                        .set({"hour": start / 60, "minute": start % 60})
                    const endAsDate = moment()
                        .startOf('year')    // Daylight saving time
                        .set({"hour": end / 60, "minute": end % 60})
                    const timeFormat = isTwelveHourClock() ? "h:mm A" : "H:mm"
                    return `${startAsDate.format(timeFormat)} ➔ ${endAsDate.format(timeFormat)}`
                } else {
                    return ""
                }
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Timetable specific functions

    getTopInUnits(): number | undefined | null {
        switch (this.timetable.getTimeFormat()) {
            case TimeFormat.PERIOD:
                return Math.max(0, (this.occurrence.toProps().time_start_in_periods ?? 0) - 1)
            default: {
                const timeStart = this.occurrence.toProps().time_start_in_minutes
                if (timeStart && isNumber(timeStart)) {
                    return timeStart / 60.0
                }
            }
        }
    }

    getHeightInUnits(): number | undefined | null {
        let height: number | undefined | null = undefined

        switch (this.timetable.getTimeFormat()) {
            case TimeFormat.PERIOD:
                const start = this.occurrence.toProps().time_start_in_periods
                const end = this.occurrence.toProps().time_end_in_periods

                if (start !== null && start !== undefined && isNumber(start) &&
                    end !== null && end !== undefined && isNumber(end)) {
                    height = end - start + 1
                }
                break;
            default: {
                const start = this.occurrence.toProps().time_start_in_minutes
                const end = this.occurrence.toProps().time_end_in_minutes

                if (start !== null && start !== undefined && isNumber(start) &&
                    end !== null && end !== undefined && isNumber(end)) {
                    height = end / 60 - start / 60
                }
                break;
            }
        }

        return height
    }

    intersects(lesson: LessonWithOccurrenceIdAndDate): boolean {
        let start: number | undefined | null
        let end: number | undefined | null
        let otherStart: number | undefined | null
        let otherEnd: number | undefined | null

        switch (this.timetable.getTimeFormat()) {
            case TimeFormat.PERIOD:
                start = this.occurrence.toProps().time_start_in_periods
                end = this.occurrence.toProps().time_end_in_periods
                otherStart = lesson.occurrence.toProps().time_start_in_periods
                otherEnd = lesson.occurrence.toProps().time_end_in_periods
                break;
            default: {
                start = this.occurrence.toProps().time_start_in_minutes
                end = this.occurrence.toProps().time_end_in_minutes
                otherStart = lesson.occurrence.toProps().time_start_in_minutes
                otherEnd = lesson.occurrence.toProps().time_end_in_minutes
                break;
            }
        }

        if (start !== null && start !== undefined && isNumber(start) &&
            end !== null && end !== undefined && isNumber(end) &&
            otherStart !== null && otherStart !== undefined && isNumber(otherStart) &&
            otherEnd !== null && otherEnd !== undefined && isNumber(otherEnd)) {

            if (this.timetable.getTimeFormat() === TimeFormat.PERIOD) {
                return coerceAtLeast(start, otherStart) < (coerceAtMost(end, otherEnd) + 1)
            } else {
                return coerceAtLeast(start, otherStart) < (coerceAtMost(end, otherEnd))
            }
        } else {
            return false
        }
    }
}

function coerceAtLeast(value: number, other: number): number {
    return Math.max(value, other)
}

function coerceAtMost(value: number, other: number): number {
    return Math.min(value, other)
}