import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import ObjectID from "bson-objectid";
import {Planner} from "../models/Planner";
import {EventRemindAtProps} from "../models/EventRemindAt";
import {MONGO_DATE_FORMAT, MONGO_DATE_TIME_FORMAT, MONGO_TIME_FORMAT} from "../utils/date";
import moment from "moment";
import {EventStepProps} from "../models/EventStep";
import {Reminder} from "../models/Reminder";

interface ReminderInsertOptions {
    planner_id: string;
    title: string;
    date?: string;
    archived: boolean;
    note?: string;
    color?: number;
    // metadata: Realm.List<event_field>;
    remind_at?: EventRemindAtProps;
    steps?: EventStepProps[];
    created_on?: string;
}

interface ReminderUpdateOptions {
    title: string;
    date?: string;
    completed_on?: string;
    archived: boolean;
    note?: string;
    color?: number;
    remind_at?: EventRemindAtProps;
    steps?: EventStepProps[];
}

export function useReminderManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const reminders = useQuery(Reminder, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a reminder in the database.
     */
    const createReminder = useCallback((options: ReminderInsertOptions) => {
        realm.write(() => {
            const object = realm.create(Reminder, {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                planner: realm.objectForPrimaryKey(Planner, options.planner_id) ?? undefined,
                ...options
            });

            if (options.remind_at) {
                const date = options.remind_at.date?.format(MONGO_DATE_FORMAT);
                const time = options.remind_at.time?.format(MONGO_TIME_FORMAT);
                if (date && time) { // Ensure both date and time are available
                    // @ts-ignore
                    object.remind_at_list = [{date: date, time: time}]
                }
            }

            if (options.steps) {
                // @ts-ignore
                object.steps = options.steps.map(it => {
                    return {title: it.title, completed_on: it.completed_on}
                });
            }
        });
    }, [realm, user.id]);

    /**
     * Update a reminder in the database.
     */
    const updateReminder = useCallback((objectId: string, options: ReminderUpdateOptions) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Reminder, objectId);
            if (!object) return;

            // Manage simple properties directly
            object.title = options.title;
            object.date = options.date;
            object.color = options.color;
            object.note = options.note;
            object.archived = options.archived;
            object.completed_on = options.completed_on;

            // Manage remind_at_list
            const {remind_at} = options;
            const remindAtDate = remind_at?.date?.format(MONGO_DATE_FORMAT);
            const remindAtTime = remind_at?.time?.format(MONGO_TIME_FORMAT);
            // @ts-ignore
            object.remind_at_list = remindAtDate && remindAtTime ? [{date: remindAtDate, time: remindAtTime}] : [];

            // Manage steps
            // @ts-ignore
            object.steps = options.steps ?? []
        });
    }, [realm]);

    /**
     * Toggles the completed_on property of a reminder.
     */
    const toggleReminderCompletedOn = useCallback((objectId: string) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Reminder, objectId);
            if (!object) return;

            object.completed_on = object.completed_on ? undefined : moment().format(MONGO_DATE_TIME_FORMAT);
        });
    }, [realm]);

    /**
     * Deletes a reminder from the database.
     */
    const deleteReminder = useCallback((objectId: string) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Reminder, objectId)
            if (object) {
                realm.delete(object);
            }
        });
    }, [realm]);

    return {
        reminders,
        createReminder,
        updateReminder,
        toggleReminderCompletedOn,
        deleteReminder
    }
}