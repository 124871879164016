import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import {Homework} from "../models/Homework";
import ObjectID from "bson-objectid";
import {Planner} from "../models/Planner";
import {Subject} from "../models/Subject";
import {EventRemindAtProps} from "../models/EventRemindAt";
import {MONGO_DATE_FORMAT, MONGO_DATE_TIME_FORMAT, MONGO_TIME_FORMAT} from "../utils/date";
import moment from "moment";
import {EventStepProps} from "../models/EventStep";

interface HomeworkInsertOptions {
    planner_id: string;
    subject_id?: string;
    title: string;
    date?: string;
    archived: boolean;
    note?: string;
    // metadata: Realm.List<event_field>;
    remind_at?: EventRemindAtProps;
    steps?: EventStepProps[];
    created_on?: string;
}

interface HomeworkUpdateOptions {
    subject_id?: string;
    title: string;
    date?: string;
    completed_on?: string;
    archived: boolean;
    note?: string;
    remind_at?: EventRemindAtProps;
    steps?: EventStepProps[];
}

export function useHomeworkManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const homework = useQuery(Homework, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a homework in the database.
     */
    const createHomework = useCallback((options: HomeworkInsertOptions) => {
        realm.write(() => {
            const object = realm.create(Homework, {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                planner: realm.objectForPrimaryKey(Planner, options.planner_id) ?? undefined,
                ...(options.subject_id) && {subject: realm.objectForPrimaryKey(Subject, options.subject_id) ?? undefined},
                ...options
            });

            if (options.remind_at) {
                const date = options.remind_at.date?.format(MONGO_DATE_FORMAT);
                const time = options.remind_at.time?.format(MONGO_TIME_FORMAT);
                if (date && time) { // Ensure both date and time are available
                    // @ts-ignore
                    object.remind_at_list = [{date: date, time: time}]
                }
            }

            if (options.steps) {
                // @ts-ignore
                object.steps = options.steps.map(it => {
                    return {title: it.title, completed_on: it.completed_on}
                });
            }
        });
    }, [realm, user.id]);

    /**
     * Update a homework in the database.
     */
    const updateHomework = useCallback((objectId: string, options: HomeworkUpdateOptions) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Homework, objectId);
            if (!object) return;

            // Update subject if provided, otherwise reset to undefined
            object.subject = options.subject_id
                ? realm.objectForPrimaryKey(Subject, options.subject_id) ?? undefined
                : undefined;

            // Manage simple properties directly
            object.title = options.title;
            object.date = options.date;
            object.completed_on = options.completed_on;
            object.archived = options.archived;
            object.note = options.note;

            // Manage remind_at_list
            const {remind_at} = options;
            const remindAtDate = remind_at?.date?.format(MONGO_DATE_FORMAT);
            const remindAtTime = remind_at?.time?.format(MONGO_TIME_FORMAT);
            // @ts-ignore
            object.remind_at_list = remindAtDate && remindAtTime ? [{date: remindAtDate, time: remindAtTime}] : [];

            // Manage steps
            // @ts-ignore
            object.steps = options.steps ?? []
        });
    }, [realm]);

    /**
     * Toggles the completed_on property of a homework.
     */
    const toggleHomeworkCompletedOn = useCallback((objectId: string) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Homework, objectId);
            if (!object) return;

            object.completed_on = object.completed_on ? undefined : moment().format(MONGO_DATE_TIME_FORMAT);
        });
    }, [realm]);

    /**
     * Deletes a homework from the database.
     */
    const deleteHomework = useCallback((objectId: string) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Homework, objectId)
            if (object) {
                realm.delete(object);
            }
        });
    }, [realm]);

    return {
        homework,
        createHomework,
        updateHomework,
        toggleHomeworkCompletedOn,
        deleteHomework
    }
}