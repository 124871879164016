import React, {useState} from "react";
import {Fab, Stack} from "@mui/material";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import {useOutletContext} from "react-router-dom";
import TimetableSelector from "./components/TimetableSelector";
import TimetablePopover from "./components/TimetablePopover";
import {useSelectedTimetableId} from "../../hooks/SelectedTimetableId";
import {LessonWithOccurrenceIdAndDate} from "../../model/LessonWithOccurrenceIdAndDate";
import {TimetableDecorator} from "../../model/TimetableDecorator";
import TimetableCommitDialog from "./dialog/TimetableCommitDialog/TimetableCommitDialog";
import HourColumn from "./components/HourColumn";
import DayColumn from "./components/DayColumn";
import TimetableDeleteConfirmationDialog from "./dialog/TimetableDeleteConfirmationDialog";
import TimetableHeader from "./components/TimetableHeader";
import LessonCommitDialog from "./dialog/LessonCommitDialog";
import LessonDialog from "./dialog/LessonDialog";
import {LessonOccurrenceDecorator} from "../../model/LessonOccurrenceDecorator";
import {useTheme} from "@mui/material/styles";
import {useTimetableLayoutManager} from "./hooks/useTimetableLayoutManager";
import {useSubjectManager} from "../../hooks/useSubjectManager";
import {Timetable} from "../../models/Timetable";
import {Lesson} from "../../models/Lesson";
import {LessonOccurrence} from "../../models/LessonOccurrence";
import {useTimetableManager} from "../../hooks/useTimetableManager";

// ---------------------------------------------------------------------------------------------------------------------
export const WIDTH_HOUR_COLUMN = 40
export const WIDTH_HOUR_COLUMN_PERIOD = 40
export const HEIGHT_HOUR_BLOCK = 64
export const HOUR_COUNT = 24


// ---------------------------------------------------------------------------------------------------------------------
export default function TimetableLayout() {
    // @ts-ignore
    const [selectedPlannerId] = useOutletContext()
    const [selectedTimetableId, setSelectedTimetableId] = useSelectedTimetableId()

    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [
        timetableDialogOpen,
        setTimetableDialogOpen
    ] = useState(false)

    const [
        timetableUpdateOptions,
        setTimetableUpdateOptions
    ] = useState<Timetable | undefined | null>(undefined)

    const [
        deleteTimetableId,
        setDeleteTimetableId
    ] = React.useState<string | undefined | null>();

    const [
        lessonDialogOpen,
        setLessonDialogOpen
    ] = useState(false)

    const [
        lessonUpdateOptions,
        setLessonUpdateOptions
    ] = useState<{
        lesson: Lesson | undefined,
        occurrences: LessonOccurrenceDecorator[] | undefined
    } | undefined | null>(undefined)

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        timetables,
        deleteTimetable
    } = useTimetableManager()

    const {
        subjects
    } = useSubjectManager()

    const timetablesByPlannerId = timetables.filter(it => it.planner?._id === selectedPlannerId)
    const subjectsByPlannerId = subjects.filter(it => it.planner?._id === selectedPlannerId)
    const selectedTimetable: Timetable | undefined = timetables
            ?.find(it => it?._id === selectedTimetableId)
        || timetables?.at(0)

    // -----------------------------------------------------------------------------------------------------------------
    // Callbacks
    const handleOnTimetableDelete = (objectId: string) => {
        setDeleteTimetableId(null);
        deleteTimetable(objectId);
    }

    // -----------------------------------------------------------------------------------------------------------------
    return (
        <>
            <WeeklyLayout
                selectedPlannerId={selectedPlannerId}
                selectedTimetable={selectedTimetable}
                timetables={timetablesByPlannerId}
                handleSelectedTimetableId={(timetableId: string | undefined | null) => {
                    if (timetableId !== undefined && timetableId !== null) {
                        setSelectedTimetableId(timetableId)
                    }
                }}
                handleOnTimetableAdd={() => {
                    setTimetableDialogOpen(true)
                }}
                handleOnTimetableUpdate={(timetableId: string) => {
                    setTimetableDialogOpen(true)
                    setTimetableUpdateOptions(
                        timetables.find(it => it._id === timetableId) || undefined
                    )
                }}
                handleOnTimetableDelete={(timetableId: string) => {
                    setDeleteTimetableId(timetableId)
                }}
                handleOnLessonAdd={() => {
                    setLessonDialogOpen(true)
                }}
                handleOnLessonUpdate={(lesson: Lesson, occurrences: LessonOccurrence[]) => {
                    setLessonDialogOpen(true)
                    setLessonUpdateOptions({
                        lesson: lesson,
                        occurrences: occurrences.map(it => new LessonOccurrenceDecorator(it))
                    })
                }}
            />

            <TimetableCommitDialog
                open={selectedPlannerId && timetableDialogOpen}
                selectedPlannerId={selectedPlannerId}
                updateOptions={timetableUpdateOptions}
                handleClose={() => {
                    setTimetableUpdateOptions(undefined)
                    setTimetableDialogOpen(false)
                }}
            />

            {
                selectedTimetable && <LessonCommitDialog
                    open={lessonDialogOpen}
                    handleClose={() => {
                        setLessonUpdateOptions(undefined)
                        setLessonDialogOpen(false)
                    }}
                    selectedPlannerId={selectedPlannerId}
                    selectedTimetable={new TimetableDecorator(selectedTimetable)}
                    subjects={subjectsByPlannerId}
                    updateOptions={lessonUpdateOptions}
                />
            }

            <TimetableDeleteConfirmationDialog
                open={!!deleteTimetableId}
                onClose={() => setDeleteTimetableId(null)}
                onPositive={() => {
                    if (deleteTimetableId) {
                        handleOnTimetableDelete(deleteTimetableId)
                    }
                }}/>
        </>
    )
}


// ---------------------------------------------------------------------------------------------------------------------
const WeeklyLayout = (
    props: {
        selectedPlannerId: string,
        selectedTimetable: Timetable | undefined | null,
        timetables: Timetable[],
        handleSelectedTimetableId: (timetableId: string | undefined | null) => void,
        handleOnTimetableAdd: () => void
        handleOnTimetableUpdate: (timetable: string) => void
        handleOnTimetableDelete: (timetableId: string) => void,
        handleOnLessonAdd: () => void
        handleOnLessonUpdate: (lesson: Lesson, occurrences: LessonOccurrence[]) => void
    }
) => {
    const theme = useTheme();

    const [weekOffset, setWeekOffset] = useState(0);

    const startOfWeek = moment().add(weekOffset, 'weeks')
        .startOf('week')
        .startOf('day')

    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map(index => (
        moment().add(weekOffset, 'weeks')
            .startOf('week')
            .startOf('day')
            .add(index, 'days')
    ))

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        lessonsByTimetable,
        lessonOccurrencesByTimetable,
        lessonsByDate,
        holidaysByPlanner
    } = useTimetableLayoutManager(
        props.selectedPlannerId,
        props.selectedTimetable ?? undefined,
        daysOfWeek
    )


    // -----------------------------------------------------------------------------------------------------------------
    // State (UI)
    const [
        timetableAnchorEl,
        setTimetableAnchorEl
    ] = React.useState<HTMLButtonElement | null>(null);

    const timetableOpen = Boolean(timetableAnchorEl);
    const popoverTimetableId = timetableOpen ? 'TimetablePopover' : undefined;

    const [lessonDialogOpen, setLessonDialogOpen] = useState(false);
    const [popoverLesson, setPopoverLesson] = useState<LessonWithOccurrenceIdAndDate | undefined | null>(undefined);
    const [lessonAnchorEl, setLessonAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const lessonOpen = Boolean(lessonAnchorEl);
    const popoverLessonId = lessonOpen ? 'LessonPopover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    // Callbacks
    const goToToday = () => setWeekOffset(0)
    const goToNextWeek = () => setWeekOffset(weekOffset + 1)
    const goToPreviousWeek = () => setWeekOffset(weekOffset - 1)

    // -----------------------------------------------------------------------------------------------------------------
    const selectedTimetableDeco = props.selectedTimetable ? new TimetableDecorator(props.selectedTimetable) : undefined

    return (
        <Stack
            sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Stack
                direction={'column'}
                flex={1}
                sx={{
                    position: 'relative',
                    [theme.breakpoints.up('md')]: {
                        px: 2
                    }
                }}
            >
                <TimetableHeader
                    timetable={props.selectedTimetable}
                    holidays={holidaysByPlanner}
                    startOfWeek={startOfWeek.clone()}
                    daysOfWeek={daysOfWeek.map(dayOfWeek => dayOfWeek.clone())}
                    goToToday={goToToday}
                    goToNextWeek={goToNextWeek}
                    goToPreviousWeek={goToPreviousWeek}
                />

                <Stack
                    direction={'column'}
                    alignSelf={'stretch'}
                    flex={1}
                    sx={{overflow: 'scroll', pb: '80px'}}
                >
                    <Stack direction="row">
                        <HourColumn
                            dayOfWeek={startOfWeek.clone()}
                            timetable={selectedTimetableDeco}
                        />
                        {
                            daysOfWeek.map((value, index) => {
                                return (
                                    <DayColumn
                                        key={index}
                                        dayOfWeek={value.clone()}
                                        timetable={selectedTimetableDeco}
                                        lessons={lessonsByDate.get(value) || []}
                                        handleOnLessonPopupOpen={(event: React.MouseEvent<HTMLButtonElement>, lesson: LessonWithOccurrenceIdAndDate) => {
                                            setPopoverLesson(lesson)
                                            setLessonDialogOpen(true)
                                        }}
                                    />
                                )
                            })
                        }
                    </Stack>
                </Stack>

                <Fab
                    onClick={props.handleOnLessonAdd}
                    color="primary"
                    aria-label="add"
                    sx={{position: 'absolute', bottom: '24px', right: '24px'}}
                >
                    <AddIcon/>
                </Fab>

                <Stack
                    direction={'row'}
                    sx={{position: 'absolute', left: '24px', bottom: '16px'}}
                >
                    <TimetableSelector
                        timetables={props.timetables}
                        selectedTimetable={props.selectedTimetable}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setTimetableAnchorEl(event.currentTarget)
                        }}
                    />
                </Stack>
            </Stack>

            <TimetablePopover
                id={popoverTimetableId}
                timetables={props.timetables}
                open={timetableOpen}
                anchorEl={timetableAnchorEl}
                handleClose={() => setTimetableAnchorEl(null)}
                handleOnTimetableAdd={() => {
                    setTimetableAnchorEl(null)
                    props.handleOnTimetableAdd()
                }}
                handleOnTimetableUpdate={(timetableId: string) => {
                    setTimetableAnchorEl(null)
                    props.handleOnTimetableUpdate(timetableId)
                }}
                handleOnTimetableDelete={(timetableId: string) => {
                    setTimetableAnchorEl(null)
                    props.handleOnTimetableDelete(timetableId)
                }}
                handleOnTimetableSelect={(timetableId: string | undefined | null) => {
                    setTimetableAnchorEl(null)
                    props.handleSelectedTimetableId(timetableId)
                }}
            />

            <LessonDialog
                open={lessonDialogOpen && Boolean(popoverLesson)}
                lesson={popoverLesson}
                handleClose={() => setLessonDialogOpen(false)}
                handleOnLessonUpdate={(lessonId: string) => {
                    setLessonDialogOpen(false)
                    const lesson = lessonsByTimetable.find(lesson => lesson._id === lessonId)
                    if (lesson) {
                        props.handleOnLessonUpdate(
                            lesson,
                            lessonOccurrencesByTimetable.filter(it => it.lesson?._id === lessonId)
                        )
                    }
                }}
            />
        </Stack>
    );
}