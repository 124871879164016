import {useTheme} from "@mui/material/styles";
import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import * as React from "react";
import {Color} from "react-color";
import {useTranslation} from "react-i18next";

interface Props {
    color: Color
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export default function ColorButton(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Stack direction='row' alignItems='center'>
            <Button
                color='inherit'
                sx={{width: '100%', height: '48px'}}
                onClick={props.onClick}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{width: '100%'}}
                >
                    <Iconify
                        // @ts-ignore
                        icon="material-symbols:circle"
                        color={props.color}
                        width={18}
                        height={18}
                        sx={{flexShrink: 0}}
                    />
                    <Typography
                        variant='body2'
                        noWrap={true}
                        color={theme.palette.text.secondary}
                        ml={2}
                    >
                        {t("task_dialog.pick_color")}
                    </Typography>
                </Stack>
            </Button>
        </Stack>
    )
}