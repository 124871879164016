import {Card, Container, Fab, Stack, Typography} from "@mui/material";
import {useOutletContext} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Iconify from "../../common/components/iconify";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import HolidayRowPopover from "./components/HolidayRowPopover";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import HolidayCommitDialog from "./dialog/HolidayCommitDialog";
import HolidayDeleteConfirmationDialog from "./dialog/HolidayDeleteConfirmationDialog";
import {useHolidayManager} from "../../hooks/useHolidayManager";
import moment from "moment";
import {Holiday} from "../../models/Holiday";

export default function HolidayLayout() {
    const theme = useTheme()
    // @ts-ignore
    const [selectedPlannerId] = useOutletContext()

    // -----------------------------------------------------------------------------------------------------------------
    // UI State
    const [
        holidayAnchorEl,
        setHolidayAnchorEl
    ] = React.useState<HTMLButtonElement | null>(null);

    const [
        popoverSelectedHolidayId,
        setPopoverSelectedHolidayId
    ] = React.useState<string | undefined>(undefined)

    const [
        holidayUpdateOptions,
        setHolidayUpdateOptions
    ] = React.useState<Holiday | undefined>(undefined)

    const [
        holidayDialogOpen,
        setHolidayDialogOpen
    ] = React.useState<boolean>(false)

    const [
        deleteHolidayId,
        setDeleteHolidayId
    ] = React.useState<string | undefined>(undefined)

    const popoverHolidayOpen = Boolean(holidayAnchorEl);
    const popoverHolidayId = popoverHolidayOpen ? 'HolidayRowPopover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        holidays,
        deleteHoliday
    } = useHolidayManager()

    const data = holidays.filter(it => it.planner?._id === selectedPlannerId)

    // -----------------------------------------------------------------------------------------------------------------
    // Handlers
    const handleAdd = () => {
        setHolidayUpdateOptions(undefined)
        setHolidayDialogOpen(true)
    }

    const handleUpdate = (objectId: string) => {
        const holiday = holidays.find(it => it._id === objectId)
        if (holiday) {
            setHolidayUpdateOptions(holiday)
            setHolidayDialogOpen(true)
        }
    }

    const handleDelete = (objectId: string) => {
        deleteHoliday(objectId)
    }

    // -----------------------------------------------------------------------------------------------------------------
    return (
        <Container maxWidth={'md'}>
            {
                data?.map(holiday => (
                    <Card key={holiday._id} sx={{flex: 1, px: 3, py: 2, mb: 1.5}}>
                        <Stack direction={'row'} alignItems={'center'}>
                            <Typography variant={'h6'}>{holiday.name}</Typography>
                            {
                                holiday.push_schedule && <Iconify
                                    // @ts-ignore
                                    icon="material-symbols:arrow-circle-right-rounded"
                                    width={18}
                                    height={18}
                                    ml={1}
                                    color={theme.palette.primary.main}
                                />
                            }
                            <Box sx={{flex: 1}}/>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation()
                                    setPopoverSelectedHolidayId(holiday._id)
                                    setHolidayAnchorEl(event.currentTarget);
                                }}
                                sx={{
                                    width: '38px',
                                    height: '38px',
                                    color: theme.palette.text.secondary
                                }}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon="carbon:overflow-menu-horizontal"
                                    width={24}
                                    height={24}
                                />
                            </IconButton>
                        </Stack>

                        <Stack direction={'row'} alignItems={'center'} mt={0.5}>
                            <Iconify
                                // @ts-ignore
                                icon="material-symbols:calendar-month-outline-rounded"
                                color={theme.palette.text.secondary}
                                width={18}
                                height={18}
                                mb={0.25}
                            />
                            <Typography
                                variant={'body1'}
                                color={'text.secondary'}
                                ml={1}
                            >
                                {
                                    holiday.start_date && holiday.end_date
                                        ? formatDate(holiday.start_date) + " ➔ " + formatDate(holiday.end_date)
                                        : ""
                                }
                            </Typography>
                        </Stack>
                    </Card>
                ))
            }

            <HolidayRowPopover
                id={popoverHolidayId}
                open={popoverHolidayOpen}
                anchorEl={holidayAnchorEl}
                handleClose={() => {
                    setHolidayUpdateOptions(undefined)
                    setHolidayAnchorEl(null)
                }}
                handleOnUpdate={() => {
                    setHolidayAnchorEl(null)
                    if (popoverSelectedHolidayId) {
                        handleUpdate(popoverSelectedHolidayId)
                    }
                }}
                handleOnDelete={() => {
                    setHolidayAnchorEl(null)
                    if (popoverSelectedHolidayId) {
                        setDeleteHolidayId(popoverSelectedHolidayId)
                    }
                }}
            />

            <HolidayCommitDialog
                open={holidayDialogOpen}
                handleClose={() => {
                    setHolidayUpdateOptions(undefined)
                    setHolidayDialogOpen(false)
                }}
                updateOptions={holidayUpdateOptions}
                selectedPlannerId={selectedPlannerId}/>

            <HolidayDeleteConfirmationDialog
                open={deleteHolidayId !== undefined}
                onClose={() => setDeleteHolidayId(undefined)}
                onPositive={() => {
                    setDeleteHolidayId(undefined)
                    if (deleteHolidayId) {
                        handleDelete(deleteHolidayId)
                    }
                }}
            />

            <Fab
                onClick={() => handleAdd()}
                color="primary"
                aria-label="add"
                sx={{position: 'absolute', bottom: '24px', right: '24px'}}
            >
                <AddIcon/>
            </Fab>

        </Container>
    );
}

// Example of checking the locale and adjusting the format accordingly
const formatDate = (date: string) => {
    const format = moment.locale() === 'en-us' ? 'MMM DD YYYY' : 'DD MMM YYYY';
    return moment(date).format(format);
};