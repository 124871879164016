import {useTheme} from "@mui/material/styles";
import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import {CloseButton} from "../TaskDialog";
import * as React from "react";
import {Category} from "../../../../model/ExamModel";
import {useTranslation} from "react-i18next";

interface Props {
    category: Category | null | undefined
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    clearCategory: () => void
}

export default function CategoryButton(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    let label: string | undefined | null
    switch (props.category) {
        case Category.WRITTEN: {
            label = "task_dialog.written_exam"
            break
        }
        case Category.ORAL: {
            label = "task_dialog.oral_exam"
            break
        }
        case Category.PRACTICAL: {
            label = "task_dialog.practical_exam"
            break
        }
        default: {
            label = undefined
            break
        }
    }

    return (
        <Stack direction='row' alignItems='center'>
            <Button
                color='inherit'
                sx={{width: '100%', height: '48px'}}
                onClick={props.onClick}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{width: '100%'}}
                >
                    <Iconify
                        // @ts-ignore
                        icon="material-symbols:category-outline-rounded"
                        color={theme.palette.text.secondary}
                        width={18}
                        height={18}
                        sx={{flexShrink: 0}}
                    />
                    <Typography
                        variant='body2'
                        noWrap={true}
                        color={label ? theme.palette.text.primary : theme.palette.text.secondary}
                        ml={2}
                    >
                        {t(label ? label : 'task_dialog.add_category')}
                    </Typography>
                </Stack>
            </Button>
            {
                props.category !== undefined && props.category !== null && <CloseButton onClick={props.clearCategory}/>
            }
        </Stack>
    )
}