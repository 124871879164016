import React from "react";
import {Button, Container, Stack, Typography} from "@mui/material";

import '../support.css'
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import Iconify from "../../../common/components/iconify";
import {LinkIndex} from "../SupportLayout";
import {useTheme} from "@mui/material/styles";
import styled from "styled-components";

const ListItem = styled('li')`
    margin-bottom: 16px;
`;

export default function PaymentsLayout() {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Container
            maxWidth={"md"}
            style={{flex: 1, display: 'flex', flexDirection: 'column'}}
        >
            <Typography variant={"h3"}>
                {t("support.payments.drawer")}
            </Typography>

            <Typography variant={"body1"} mt={2}>
                {t("support.payments.sub_header")}
            </Typography>

            <LinkIndex href={"#NotPremium-activation"} underline="none" mt={2}>
                {
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:arrow-circle-right-rounded"}
                        width={24}
                        color={theme.palette.primary.main}
                    />
                }
                {t("support.payments.premium_activation.title")}
            </LinkIndex>

            <Divider sx={{mt: 2}}/>

            <Typography variant={'h4'} id="premium-activation" mt={3}>
                {t("support.payments.premium_activation.title")}
            </Typography>

            <Typography variant={'body1'} mt={1}>
                {t("support.payments.premium_activation.body1")}
            </Typography>

            <ol>
                <ListItem>
                    {t("support.payments.premium_activation.list1")}
                </ListItem>
                <ListItem>
                    {t("support.payments.premium_activation.list2")}
                </ListItem>
                <ListItem>
                    {t("support.payments.premium_activation.list3")}
                </ListItem>
                <div className="card" style={{maxWidth: '480px', marginBottom: '16px'}}>
                    <img
                        src="/assets/support/payment-issues-screenshot-1.webp"
                        className="img-fluid rounded"
                    />
                </div>
                <ListItem>
                    {t("support.payments.premium_activation.list4")}
                </ListItem>
                <ListItem>
                    {t("support.payments.premium_activation.list5")}
                </ListItem>
            </ol>

            <Typography variant={'body1'}>
                {t("support.payments.premium_activation.body2")}
            </Typography>

            <Stack direction={"row"} sx={{mt: 5}}>
                <Button
                    variant={'contained'}
                    size={'large'}
                    href="mailto:support@schoolplanner.io?subject=Request:%20Payment%20issues"
                    sx={{textTransform: 'none'}}
                >
                    {t("support.contact_support")}
                </Button>
            </Stack>

            <div style={{minHeight: '64px'}}></div>

        </Container>
    )
}