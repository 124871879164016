import React from "react";
import {Button, Container, Stack, Typography} from "@mui/material";

import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {LinkIndex} from "../SupportLayout";
import Iconify from "../../../common/components/iconify";

export default function PrivacyLayout() {
    const {t} = useTranslation()

    return (
        <Container
            maxWidth={"md"}
            style={{flex: 1, display: 'flex', flexDirection: 'column'}}
        >
            <Typography variant={"h3"}>
                {t("support.privacy.header")}
            </Typography>

            <Typography variant={"body1"} mt={2}>
                This privacy policy governs your use of the software application School Planner (“Application”) for mobile devices that was created by Andrea Porfiri Dal Cin. The Application is School Planner (link: https://play.google.com/store/apps/details?id=daldev.android.gradehelper).
            </Typography>

            <Typography variant={"h4"} mt={5}>
                What information does the Application obtain and how is it used?
            </Typography>

            <Typography variant={"h6"} mt={2}>
                User Provided Information
            </Typography>

            <Typography variant={"body1"} mt={2}>
                The Application obtains the information you provide when you download and register the Application. Registration with us is optional. However, please keep in mind that you may not be able to use some of the features offered by the Application unless you register with us.
            </Typography>

            <Typography variant={"body1"} mt={2}>
                When you register with us and use the Application, you generally provide (a) your name, email address, age, user name, password and other registration information; (b) transaction-related information, such as when you make purchases, respond to any offers, or download or use applications from us; (c) information you provide us when you contact us for help; (d) credit card information for purchase and use of the Application, and; (e) information you enter into our system when using the Application, such as contact information and project management information.
            </Typography>

            <Typography variant={"body1"} mt={2}>
                We may also use the information you provided us to contact your from time to time to provide you with important information, required notices and marketing promotions.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Automatically Collected Information
            </Typography>

            <Typography variant={"body1"} mt={2}>
                In addition, the Application may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browsers you use, and information about the way you use the Application.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Does the Application collect precise real time location information of the device?
            </Typography>

            <Typography variant={"body1"} mt={2}>
                This Application does not collect precise information about the location of your mobile device.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Do third parties see and/or have access to information obtained by the Application?
            </Typography>

            <Typography variant={"body1"} mt={2}>
                Only aggregated, anonymized data is periodically transmitted to external services to help us improve the Application and our service. We will share your information with third parties only in the ways that are described in this privacy statement.
            </Typography>

            <Typography variant={"body1"} mt={2}>
                We may disclose User Provided and Automatically Collected Information:
            </Typography>

            <ul style={{marginTop: "16px", marginLeft: "16px"}}>
                <li>as required by law, such as to comply with a subpoena, or similar legal process;</li>
                <li>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request;</li>
                <li>with our trusted services providers who work on our behalf, do not have an independent use of the information we disclose to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
                <li>if School Planner is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of this information, as well as any choices you may have regarding this information.</li>
            </ul>

            <Typography variant={"h4"} mt={2}>
                What are my opt-out rights?
            </Typography>

            <Typography variant={"body1"} mt={2}>
                You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at tinygames96@gmail.com
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Data Retention Policy, Managing Your Information
            </Typography>

            <Typography variant={"body1"} mt={2}>
                We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We will retain Automatically Collected information for up to 30 days and thereafter may store it in aggregate. If you’d like us to delete User Provided Data that you have provided via the Application, please contact us at tinygames96@gmail.com and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Children
            </Typography>

            <Typography variant={"body1"} mt={2}>
                We do not use the Application to knowingly solicit data from or market to children under the age of 13. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at tinygames96@gmail.com. We will delete such information from our files within a reasonable time.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Security
            </Typography>

            <Typography variant={"body1"} mt={2}>
                We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and procedural safeguards to protect information we process and maintain. For example, we limit access to this information to authorized employees and contractors who need to know that information in order to operate, develop or improve our Application. Please be aware that, although we endeavor provide reasonable security for information we process and maintain, no security system can prevent all potential security breaches.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Changes
            </Typography>

            <Typography variant={"body1"} mt={2}>
                This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy Policy by posting the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Your Consent
            </Typography>

            <Typography variant={"body1"} mt={2}>
                y using the Application, you are consenting to our processing of your information as set forth in this Privacy Policy now and as amended by us. "Processing,” means using cookies on a computer/hand held device or using or touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in the United States. If you reside outside the United States your information will be transferred, processed and stored there under you country's privacy standards.
            </Typography>

            <Typography variant={"h4"} mt={2}>
                Contact us
            </Typography>

            <Typography variant={"body1"} mt={2}>
                If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at tinygames96@gmail.com
            </Typography>

            <div style={{minHeight: '64px'}}></div>

        </Container>
    )
}