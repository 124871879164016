import {Button, CircularProgress} from "@mui/material";
import * as React from "react";

export default function SaveButton(
    props: {
        text: string,
        disabled?: boolean,
        isSaving: boolean,
        handleSaveClick: () => void
    }
) {
  return (
      <Button
          onClick={props.handleSaveClick}
          variant='contained'
          disabled={props.isSaving || props.disabled}
          sx={{boxShadow: 'none'}}
      >

          {props.text}
          {
              props.isSaving && <CircularProgress color={'inherit'} thickness={6} size={12} sx={{ml: 1}}/>
          }
      </Button>
  );
}