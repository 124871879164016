import Realm from "realm";
import {Timetable} from "./Timetable";
import {Subject} from "./Subject";

export interface LessonProperties {
    timetableId?: string;
    subjectId?: string;
    title?: string;
    color?: number;
    room?: string;
    note?: string;
    // teachers: Teacher[];
    created_on?: string;
}

export class Lesson extends Realm.Object {
    _id!: string;
    owner_id!: string;
    timetable?: Timetable;
    // parent_lesson?: Lesson;
    subject?: Subject;
    title?: string;
    color?: number;
    room?: string;
    note?: string;
    // teachers!: Teacher[];
    created_on?: string;

    static schema: Realm.ObjectSchema = {
        name: 'lesson',
        primaryKey: '_id',
        properties: {
            _id: 'string',
            owner_id: 'string',
            timetable: 'timetable?',
            // parent_lesson: 'lesson?',
            subject: 'subject?',
            title: 'string?',
            color: 'int?',
            room: 'string?',
            note: 'string?',
            // teachers: 'Teacher[]',
            created_on: 'string?',
        }
    }
}