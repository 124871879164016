import moment from "moment/moment";
import {MONGO_DATE_FORMAT} from "../utils/date";
import {RecurringPatternProperties} from "../models/RecurringPattern";

export class RecurringPatternDecorator {
    private readonly pattern: RecurringPatternProperties;

    constructor(pattern: RecurringPatternProperties) {
        this.pattern = pattern;
    }

    get(): RecurringPatternProperties {
        return this.pattern;
    }

    getSeparationCount(): number {
        return this.pattern.separation_count;
    }

    getEndDate(): moment.Moment | null {
        const endDate = moment(this.pattern.end_date, MONGO_DATE_FORMAT, true);
        return endDate.isValid() ? endDate : null;
    }

    getRecurringType(): number {
        return this.pattern.recurring_type;
    }

    getDaysOfWeek(): number[] | undefined | null {
        return undefined;
        // return this.pattern.days_of_week;
    }
}

export const RecurringType = Object.freeze({
    DAILY: 1,
    WEEKLY: 2,
    MONTHLY: 3,
    YEARLY: 4
})