import Realm from "realm";

export interface LessonInstanceExceptionProperties {
    is_rescheduled: boolean;
    is_cancelled: boolean;
    instance_date: string;
    rescheduled_date?: string;
    time_start_in_minutes?: number;
    time_end_in_minutes?: number;
    time_start_in_periods?: number;
    time_end_in_periods?: number;
}

export class LessonInstanceException extends Realm.Object implements LessonInstanceExceptionProperties {
    is_rescheduled!: boolean;
    is_cancelled!: boolean;
    instance_date!: string
    rescheduled_date?: string;
    time_start_in_minutes?: number;
    time_end_in_minutes?: number;
    time_start_in_periods?: number;
    time_end_in_periods?: number;

    static schema: Realm.ObjectSchema = {
        name: 'lesson_instance_exception',
        embedded: true,
        properties: {
            is_rescheduled: 'bool',
            is_cancelled: 'bool',
            instance_date: 'string',
            rescheduled_date: 'string?',
            time_start_in_minutes: 'int?',
            time_end_in_minutes: 'int?',
            time_start_in_periods: 'int?',
            time_end_in_periods: 'int?',
        }
    }
}