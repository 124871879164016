import moment from "moment/moment";
import {MONGO_DATE_FORMAT} from "../utils/date";
import {TFunction} from "i18next";
import {RecurringType} from "./RecurringPatternDecorator";
import {Scheduling, TimeFormat, TimetableDecorator} from "./TimetableDecorator";
import {LessonOccurrenceProperties} from "../models/LessonOccurrence";
import {RecurringPatternProperties} from "../models/RecurringPattern";
import {LessonInstanceExceptionProperties} from "../models/LessonInstanceException";
import {LessonInstanceExceptionDecorator} from "./LessonInstanceExceptionDecorator";

export class LessonOccurrenceDecorator {
    private readonly occurrence: LessonOccurrenceProperties;
    private readonly instanceExceptions: Map<string, LessonInstanceExceptionProperties>;
    private pattern: RecurringPatternProperties | undefined;

    constructor(occurrence: LessonOccurrenceProperties) {
        this.occurrence = {...occurrence};
        this.pattern = occurrence.recurring_pattern || undefined;
        this.instanceExceptions = new Map();

        occurrence.instance_exceptions?.forEach(it => {
            const date = new LessonInstanceExceptionDecorator(it).getInstanceDate();
            if (date) {
                this.instanceExceptions.set(date.format(MONGO_DATE_FORMAT), {...it});
            }
        });
    }


    // -------------------------------------------------------------------------------------------------------------
    // Getters
    toProps(): LessonOccurrenceProperties {
        return {...this.occurrence, recurring_pattern: this.pattern};
    }

    getId(): string {
        return this.occurrence._id;
    }

    getDate(): moment.Moment | undefined {
        const date = moment(this.occurrence.date, MONGO_DATE_FORMAT, true);
        return date.isValid() ? date : undefined;
    }

    getIndexOfWeek(): number {
        return this.occurrence.index_of_week;
    }

    getIndexOfDay(): number {
        return this.occurrence.index_of_day;
    }

    getStartTimeInPeriod(): number | undefined {
        return this.occurrence.time_start_in_periods;
    }

    getEndTimeInPeriod(): number | undefined {
        return this.occurrence.time_end_in_periods;
    }

    getStartTimeInMinutes(): number | undefined {
        return this.occurrence.time_start_in_minutes;
    }

    getEndTimeInMinutes(): number | undefined {
        return this.occurrence.time_end_in_minutes;
    }

    isRecurring(): boolean {
        return this.occurrence.is_recurring;
    }

    getRecurringPattern(): RecurringPatternProperties | undefined {
        return this.pattern ? {...this.pattern} : undefined;
    }

    getInstanceExceptions(): Map<string, LessonInstanceExceptionProperties> {
        return new Map(this.instanceExceptions);
    }


    // -------------------------------------------------------------------------------------------------------------
    // Setters
    setDate(date: moment.Moment) {
        this.occurrence.date = date.format(MONGO_DATE_FORMAT);
    }

    setIndexOfWeek(indexOfWeek: number) {
        this.occurrence.index_of_week = indexOfWeek;
    }

    setIndexOfDay(indexOfDay: number) {
        this.occurrence.index_of_day = indexOfDay;
    }

    setTimeStartInMinutes(time: number | undefined) {
        this.occurrence.time_start_in_minutes = time;
    }

    setTimeEndInMinutes(time: number | undefined) {
        this.occurrence.time_end_in_minutes = time;
    }

    setTimeStartInPeriods(time: number | undefined) {
        this.occurrence.time_start_in_periods = time;
    }

    setTimeEndInPeriods(time: number | undefined) {
        this.occurrence.time_end_in_periods = time;
    }

    setRecurring(isRecurring: boolean) {
        this.occurrence.is_recurring = isRecurring;
    }

    setRecurringPattern(pattern: RecurringPatternProperties | undefined) {
        this.pattern = pattern;
    }

    /*
    setInstanceExceptions(exceptions: LessonInstanceExceptionProperties[]) {
        this.instanceExceptions.clear();

        exceptions.forEach(it => {
            const date = new LessonInstanceExceptionDecorator(it).getInstanceDate();
            if (date) {
                this.instanceExceptions.set(date.format(MONGO_DATE_FORMAT), {...it});
            }
        });
    }
     */


    // -------------------------------------------------------------------------------------------------------------
    // Other methods
    formatRecurrence(
        timetable: TimetableDecorator,
        t: TFunction<"translation", undefined, "translation">
    ): string | undefined | null {
        if (!this.isRecurring()) {
            return t('timetable.lesson.commit_dialog.occurrence.repeat.never');
        }

        if (!this.pattern) {
            switch (timetable.getScheduling()) {
                case Scheduling.WEEKLY:
                    return t(
                        'timetable.lesson.commit_dialog.occurrence.repeat.every_week_format',
                        {count: timetable.getNumberOfWeeks()}
                    );
                default:
                    return t('timetable.lesson.commit_dialog.occurrence.repeat.on_numbered_day');
            }
        }

        const count = this.pattern.separation_count;
        switch (this.pattern.recurring_type) {
            case RecurringType.DAILY:
                return t(
                    'timetable.lesson.commit_dialog.occurrence.repeat.every_day_format',
                    {count: count + 1}
                );
            case RecurringType.WEEKLY:
                return t(
                    'timetable.lesson.commit_dialog.occurrence.repeat.every_week_format',
                    {count: count + 1}
                );
            case RecurringType.MONTHLY:
                return t(
                    'timetable.lesson.commit_dialog.occurrence.repeat.every_month_format',
                    {count: count + 1}
                );
            case RecurringType.YEARLY:
                return t(
                    'timetable.lesson.commit_dialog.occurrence.repeat.every_year_format',
                    {count: count + 1}
                );
        }
    }

    getErrorStatesIfExists(timetable: TimetableDecorator): number[] {
        const errorStates: number[] = [];

        // Validate start date
        if (this.isRecurring() && this.getRecurringPattern() === null) {
            switch (timetable.getScheduling()) {
                case Scheduling.SHIFT:
                    if (this.getIndexOfDay() < 0) {
                        errorStates.push(ErrorState.INVALID_START_DATE);
                    }
                    break;
                case Scheduling.WEEKLY:
                    if (this.getIndexOfWeek() < 0) {
                        errorStates.push(ErrorState.INVALID_START_DATE);
                    }
                    break;
            }
        }

        // Validate time
        switch (timetable.getTimeFormat()) {
            case TimeFormat.HOUR: {
                const start = this.getStartTimeInMinutes();
                const end = this.getEndTimeInMinutes()
                if (start === undefined || start === null) {
                    errorStates.push(ErrorState.START_TIME_MISSING);
                }
                if (end === undefined || end === null) {
                    errorStates.push(ErrorState.END_TIME_MISSING);
                }
                if ((start ?? 0) >= (end ?? 0)) {
                    errorStates.push(ErrorState.END_TIME_BEFORE_START);
                }
                break;
            }
            case TimeFormat.PERIOD: {
                const start = this.getStartTimeInPeriod();
                const end = this.getEndTimeInPeriod();

                if (start === undefined || start === null) {
                    errorStates.push(ErrorState.START_TIME_MISSING);
                }
                if (end === undefined || end === null) {
                    errorStates.push(ErrorState.END_TIME_MISSING);
                }
                if ((start ?? 0) < 1 || (start ?? 0) > timetable.getNumberOfPeriods()) {
                    errorStates.push(ErrorState.START_TIME_OUT_OF_BOUNDS);
                }
                if ((end ?? 0) < 1 || (end ?? 0) > timetable.getNumberOfPeriods()) {
                    errorStates.push(ErrorState.END_TIME_OUT_OF_BOUNDS);
                }
                if ((end ?? 0) < (start ?? 0)) {
                    errorStates.push(ErrorState.END_TIME_BEFORE_START);
                }
                break;
            }
        }

        return errorStates;
    }
}

export const
    ErrorState = Object.freeze({
        INVALID_START_DATE: 0,
        START_TIME_MISSING: 1,
        END_TIME_MISSING: 2,
        START_TIME_OUT_OF_BOUNDS: 3,
        END_TIME_OUT_OF_BOUNDS: 4,
        END_TIME_BEFORE_START: 5
    })