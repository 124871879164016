import {useLessonManager} from "../../../hooks/useLessonManager";
import {useLessonOccurrenceManager} from "../../../hooks/useLessonOccurrenceManager";
import {useHolidayManager} from "../../../hooks/useHolidayManager";
import moment from "moment/moment";
import {LessonWithOccurrenceIdAndDate} from "../../../model/LessonWithOccurrenceIdAndDate";
import {parseByDate} from "../../../utils/timetable";
import {Timetable} from "../../../models/Timetable";

export const useTimetableLayoutManager = (
    selectedPlannerId: string,
    selectedTimetable: Timetable | undefined,
    daysOfWeek: moment.Moment[]
) => {
    const selectedTimetableId = selectedTimetable?._id

    const {lessons,} = useLessonManager()
    const {lessonOccurrences} = useLessonOccurrenceManager()
    const {holidays} = useHolidayManager()

    const lessonsByTimetable = lessons.filter(it => {
        return it.timetable?._id === selectedTimetableId
            && it.timetable?.planner?._id === selectedPlannerId
    });

    const lessonOccurrencesByTimetable = lessonOccurrences.filter(it => {
        return it.lesson?.timetable?._id === selectedTimetableId
            && it.lesson?.timetable?.planner?._id === selectedPlannerId
    });

    const holidaysByPlanner = holidays.filter(it => it.planner?._id === selectedPlannerId);

    let lessonsByDate: Map<moment.Moment, LessonWithOccurrenceIdAndDate[]> = new Map();
    if (selectedTimetable) {
        daysOfWeek.forEach(dayOfWeek => {
            lessonsByDate.set(dayOfWeek, parseByDate(
                lessonsByTimetable,
                lessonOccurrencesByTimetable,
                dayOfWeek.clone(),
                selectedTimetable,
                holidaysByPlanner,
                moment().startOf('week')
            ))
        });
    }

    return {
        lessonsByTimetable,
        lessonOccurrencesByTimetable,
        lessonsByDate,
        holidaysByPlanner
    }
}