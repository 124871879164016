import moment from "moment/moment";
import {MONGO_DATE_FORMAT} from "../utils/date";
import {TimetableProperties} from "../models/Timetable";

export class TimetableDecorator {
    private readonly timetable: TimetableProperties;

    constructor(timetable: TimetableProperties) {
        this.timetable = {...timetable};
    }

    private getMomentDate(date: string): moment.Moment | null {
        const momentDate = moment(date, MONGO_DATE_FORMAT, true);
        return momentDate.isValid() ? momentDate : null;
    }

    getStartDate = (): moment.Moment | null => {
        const date = this.timetable.start_date;
        return date ? this.getMomentDate(date) : null;
    }

    getEndDate = (): moment.Moment | null => {
        const date = this.timetable.end_date;
        return date ? this.getMomentDate(date) : null;
    }

    getTimetableId = (): string => this.timetable._id;
    getTitle = (): string => this.timetable.title;
    getScheduling = (): number => this.timetable.scheduling;
    getTimeFormat = (): number => this.timetable.time_format;
    getNumberOfWeeks = (): number => Math.max(this.timetable.number_of_weeks, 1);
    getStartWeek = (): number => Math.max(this.timetable.start_week, 0);
    getStartWeekDate = (): moment.Moment | null => this.getMomentDate(this.timetable.start_week_date);
    getShiftStartDay = (): moment.Moment | null => this.getMomentDate(this.timetable.shift_start_day);
    getShiftNumberOfDays = (): number => Math.max(this.timetable.shift_number_of_days, 1);
    getNumberOfPeriods = (): number => Math.max(this.timetable.number_of_periods, 2);

    getShiftDaysOfWeek(): Set<number> {
        const set = new Set<number>();
        this.timetable.shift_days_of_week?.forEach(day => {
            if (day) set.add(day);
        });
        return set;
    }

    getWeekNames(): Map<number, string> {
        const map = new Map<number, string>();
        /*
        this.timetable.week_data_list?.forEach(week => {
            if (week && week.name) {
                map.set(week.index_of_week, week.name)
            }
        });
         */
        return map;
    }
}

export const Scheduling = Object.freeze({
    WEEKLY: 1,
    SHIFT: 3
})

export const TimeFormat = Object.freeze({
    HOUR: 1,
    PERIOD: 2
})