import React from 'react';
import {Helmet} from "react-helmet"
import {APP_BASE_URL, APP_ID} from "./common/Constants";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import './common/assets/css/App.css'
import ThemeProvider from "./common/theme/minimals";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {useTranslation} from "react-i18next";
import {AppProvider} from "@realm/react";
import {RouterProvider} from "react-router-dom";
import {router} from "./routes";

// ---------------------------------------------------------------------------------------------------------------------
// Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCw_wMIULnw1lRfy8zIVx5qT380NLDsSGo",
    authDomain: "fiery-palace-113220.firebaseapp.com",
    databaseURL: "https://fiery-palace-113220.firebaseio.com",
    projectId: "fiery-palace-113220",
    storageBucket: "fiery-palace-113220.appspot.com",
    messagingSenderId: "631386366896",
    appId: "1:631386366896:web:1d179ff8e8f690ed8aac75",
    measurementId: "G-TKW4FFH8YN"
};

export const googleAuthProvider = new GoogleAuthProvider();

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const analytics = getAnalytics(firebaseApp);


// ---------------------------------------------------------------------------------------------------------------------
const App = () => {
    const {t} = useTranslation()

    return (
        <AppProvider id={APP_ID} baseUrl={APP_BASE_URL}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Helmet>
                    <title>{t('app_name')}</title>
                    <meta name="theme-color" content="#FFFFFF"/>
                    <meta name="viewport"
                          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
                    <link rel="manifest" href="/manifest.json"></link>
                </Helmet>

                <div className="App">
                    <ThemeProvider>
                        <RouterProvider router={router}/>
                    </ThemeProvider>
                </div>
            </LocalizationProvider>
        </AppProvider>
    )
}

export default App;