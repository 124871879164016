import {BootstrapDialog, Transition} from "../../../../common/components/BootstrapDialog";
import {styled, useTheme} from "@mui/material/styles";
import {COLOR_DEFAULT, colorToHexColor} from "../../../../utils/color";
import {LessonWithOccurrenceIdAndDate} from "../../../../model/LessonWithOccurrenceIdAndDate";
import {Button, DialogActions, IconButton, Stack, TextareaAutosize, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import * as React from "react";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import LessonDeletePopover from "./LessonDeletePopover";
import {MONGO_DATE_FORMAT} from "../../../../utils/date";
import LessonDeleteConfirmationDialog from "../LessonDeleteConfirmationDialog";
import {useTranslation} from "react-i18next";
import {useLessonManager} from "../../../../hooks/useLessonManager";
import {useLessonOccurrenceManager} from "../../../../hooks/useLessonOccurrenceManager";

export default function LessonDialog(
    props: {
        lesson: LessonWithOccurrenceIdAndDate | undefined | null,
        open: boolean,
        handleClose: () => void
        handleOnLessonUpdate: (lessonId: string) => void
    }
) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [
        deleteLessonId,
        setDeleteLessonId
    ] = React.useState<string | undefined>();

    const [
        subjectAnchorEl,
        setSubjectAnchorEl
    ] = React.useState<HTMLButtonElement | null>(null);

    const subjectOpen = Boolean(subjectAnchorEl);
    const popoverSubjectId = subjectOpen ? 'TaskRowPopover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    const {
        deleteLesson
    } = useLessonManager()

    const {
        updateLessonOccurrence
    } = useLessonOccurrenceManager()

    // -----------------------------------------------------------------------------------------------------------------
    // Callbacks
    const handleOnLessonDeleteAll = () => {
        const objectId = deleteLessonId
        if (!objectId) return;

        deleteLesson(objectId)

        setDeleteLessonId(undefined)
        props.handleClose()
    }

    const handleOnLessonDeleteSingle = () => {
        const lesson = props.lesson;
        if (!lesson) return;

        const date = lesson.getDate().format(MONGO_DATE_FORMAT);
        if (!date) return;

        const occurrence = lesson.getOccurrence().toProps();
        if (!occurrence) return;

        const exceptions = lesson.getOccurrence().getInstanceExceptions();
        exceptions.set(date, {instance_date: date, is_cancelled: true, is_rescheduled: false});

        updateLessonOccurrence(occurrence._id, {
            ...occurrence,
            instance_exceptions: Array.from(exceptions.values())
        });

        props.handleClose()
    }

    // -----------------------------------------------------------------------------------------------------------------
    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            TransitionComponent={Transition}
            fullScreen={false}
            open={props.open}
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '16px',
                    border: `0.01em solid rgba(0, 0, 0, 0.7)`,
                    boxSizing: 'border-box',
                }
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{py: 1.5, pl: 3, pr: 2, backgroundColor: getColor(props.lesson)}}
            >
                <Typography
                    flex={1}
                    fontWeight={600}
                    fontSize={'1.15em'}
                    noWrap={true}
                    color={theme.palette.primary.contrastText}
                >
                    {props.lesson?.getTitle()}
                </Typography>
                <IconButton onClick={props.handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <DialogContent>
                <Stack direction="column">
                    <Typography
                        fontWeight={600}
                        variant={'body1'}
                        noWrap={true}
                        color={theme.palette.text.primary}
                        sx={{textTransform: 'capitalize'}}
                    >
                        {props.lesson?.getDate().format('dddd, DD MMM YYYY')}
                    </Typography>
                    <Typography
                        variant={'body2'}
                        noWrap={true}
                        mt={1}
                        mb={3}
                        color={theme.palette.text.secondary}
                    >
                        {props.lesson?.formatStartEndTime(t)}
                    </Typography>
                    {
                        props.lesson?.getRoom() && <Stack direction={'row'} spacing={1}>
                            <Iconify
                                // @ts-ignore
                                icon="material-symbols:location-on-outline-rounded"
                                color={theme.palette.text.secondary}
                                width={18}
                                height={18}
                                mt={0.25}
                            />
                            <Typography
                                variant={'body2'}
                                noWrap={true}
                                color={theme.palette.text.secondary}
                            >
                                {props.lesson?.getRoom()}
                            </Typography>
                        </Stack>
                    }
                    {
                        props.lesson?.getNote() && <Stack
                            direction="row"
                            mt={3}
                            style={{border: `1px solid ${theme.palette.divider}`, minHeight: '64px', borderRadius: '16px'}}
                        >
                            <NoteTextArea
                                value={props.lesson?.getNote() || ''}
                                sx={{pointerEvents: 'none'}}
                                onChange={(event: React.ChangeEvent) => {
                                    // @ts-ignore
                                    setNote(event.target.value)
                                }}
                            />
                        </Stack>
                    }
                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={2}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <Button
                        color='error'
                        variant={'contained'}
                        disabled={false}
                        onClick={(event) => {
                            if (props.lesson?.isRecurring()) {
                                setSubjectAnchorEl(event.currentTarget);
                            } else {
                                setDeleteLessonId(props.lesson?.getLesson()._id)
                            }
                        }}
                        startIcon={
                            <Iconify
                                // @ts-ignore
                                icon="material-symbols:delete"
                                color={theme.palette.error.contrastText}
                                width={18}
                                height={18}
                            />
                        }
                        sx={{boxShadow: 'none'}}
                    >
                        {t('delete')}
                    </Button>
                    <Button
                        onClick={() => {
                            const lessonId = props.lesson?.getLesson()._id
                            if (lessonId) {
                                props.handleOnLessonUpdate(lessonId)
                            }
                        }}
                        color='inherit'
                        variant={'contained'}
                        startIcon={
                            <Iconify
                                // @ts-ignore
                                icon="material-symbols:edit"
                                color={theme.palette.text.primary}
                                width={18}
                                height={18}
                            />
                        }
                        sx={{
                            boxShadow: 'none'
                        }}
                    >
                        {t('edit')}
                    </Button>
                </Stack>
            </DialogActions>

            <LessonDeleteConfirmationDialog
                open={!!deleteLessonId}
                onClose={() => {
                    setDeleteLessonId(undefined)
                }}
                onPositive={() => {
                    handleOnLessonDeleteAll()
                }}
            />

            <LessonDeletePopover
                id={popoverSubjectId}
                open={subjectOpen}
                anchorEl={subjectAnchorEl}
                handleClose={() => setSubjectAnchorEl(null)}
                handleOnDeleteSingle={() => {
                    setSubjectAnchorEl(null)
                    const lessonId = props.lesson?.getLesson()._id
                    if (lessonId) {
                        handleOnLessonDeleteSingle()
                    }
                }}
                handleOnDeleteAll={() => {
                    setSubjectAnchorEl(null)
                    setDeleteLessonId(props.lesson?.getLesson()._id)
                }}
            />

        </BootstrapDialog>
    )
}


const getColor = (lesson: LessonWithOccurrenceIdAndDate | undefined | null) => {
    return lesson?.getColor() ? colorToHexColor(lesson?.getColor() || 0) : COLOR_DEFAULT
}


const NoteTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    margin: '8px 16px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight
}));