import React, {useState} from 'react';
// @mui
import {MenuItem, Popover, Stack} from '@mui/material';
import {StyledMenuItem} from "./AccountPopover";
import {useTranslation} from "react-i18next";

// ----------------------------------------------------------------------

const LANGS = [
    {
        value: 'en',
        label: 'languages.en',
        icon: '/assets/icons/header/ic_united_states.svg',
    },
    {
        value: 'de',
        label: 'languages.de',
        icon: '/assets/icons/header/ic_germany.svg',
    },
    {
        value: 'es',
        label: 'languages.es',
        icon: '/assets/icons/header/ic_spain.svg',
    },
    {
        value: 'fr',
        label: 'languages.fr',
        icon: '/assets/icons/header/ic_france.svg',
    },
    {
        value: 'it',
        label: 'languages.it',
        icon: '/assets/icons/header/ic_italy.svg',
    }
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const {t, i18n} = useTranslation();
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <StyledMenuItem onClick={handleOpen} sx={{mx: 1}}>
                {t('app.header.account_settings.change_language')}
            </StyledMenuItem>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 1,
                        mt: 1.5,
                        ml: 0.75,
                        width: 180,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Stack spacing={0.75}>
                    {LANGS.map((option) => (
                        <MenuItem
                            key={option.value}
                            selected={option.value === i18n.language}
                            onClick={() => {
                                i18n.changeLanguage(option.value);
                            }}
                        >
                            {t(option.label)}
                        </MenuItem>
                    ))}
                </Stack>
            </Popover>
        </>
    );
}
