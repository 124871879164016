import {styled} from "@mui/material/styles";
import React, {useState} from "react";
import Header from "./header";
import Nav, {NAV_WIDTH} from "./nav";
import {Outlet} from "react-router-dom";
import PlannerDialog from "./dialog/PlannerDialog";
import useResponsive from "../../hooks/useResponsive";
import {usePlannerManager} from "../../hooks/usePlannerManager";
import {useSelectedPlannerId} from "../../hooks/SelectedPlannerId";
import PlannerNotSelected from "./components/PlannerNotSelected";

// ---------------------------------------------------------------------------------------------------------------------
const APP_BAR_MOBILE = 48;
const APP_BAR_DESKTOP = 48;

const StyledRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flex: 1,
    minHeight: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default
}));

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 16,
    [theme.breakpoints.up('md')]: {
        paddingTop: APP_BAR_DESKTOP + 16,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${NAV_WIDTH}px`,
        })
    }
}));


// ---------------------------------------------------------------------------------------------------------------------
// Firebase


// ---------------------------------------------------------------------------------------------------------------------
export default function DashboardLayout() {
    const isDesktop = useResponsive('up', 'md');

    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [open, setOpen] = useState(isDesktop);
    const [plannerDialogOpen, setPlannerDialogOpen] = useState(false);
    const [selectedPlannerId, setSelectedPlannerId] = useSelectedPlannerId()

    // -----------------------------------------------------------------------------------------------------------------
    const {
        planners
    } = usePlannerManager();

    let selectedPlanner = planners?.find((planner) => {
        return planner?._id === selectedPlannerId
    }) || planners?.at(0)

    return (
        <>
            <StyledRoot>
                <Header
                    open={open}
                    onOpenNav={() => setOpen(!open)}
                />

                <Nav
                    openNav={open}
                    onCloseNav={() => setOpen(false)}
                    handleAddPlanner={() => setPlannerDialogOpen(true)}
                    handleOnSetSelectedPlannerId={(plannerId: string) => setSelectedPlannerId(plannerId)}
                    planners={planners.map((planner) => planner)}
                    selectedPlannerId={selectedPlanner?._id}
                />

                <Main open={open}>
                    {
                        selectedPlanner ?
                            <Outlet context={[selectedPlanner?._id]}/>
                            : <PlannerNotSelected handleCreatePlanner={() => setPlannerDialogOpen(true)}/>
                    }
                </Main>

                <PlannerDialog
                    open={plannerDialogOpen}
                    handleClose={() => setPlannerDialogOpen(false)}
                />
            </StyledRoot>
        </>
    );
}