import Realm, {ObjectSchema} from 'realm';

export class Planner extends Realm.Object {
    _id!: string;
    owner_id!: string;
    title!: string;
    created_on?: string;

    static schema: ObjectSchema = {
        name: 'planner',
        primaryKey: '_id',
        properties: {
            _id: 'string',
            owner_id: 'string',
            title: 'string',
            created_on: 'string?',
        }
    };
}