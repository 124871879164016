import {Link as RouterLink} from 'react-router-dom';
import {Card, Link, Stack} from '@mui/material';
import Box from "@mui/material/Box";

// ----------------------------------------------------------------------

export default function Logo(
    props: {
        linkTo?: string,
        disableLink?: boolean,
        sx?: any
    }
) {
    const {linkTo = '/app', disableLink = false, sx, ...other} = props;

    const logo = (
        <Card
            id={'nav-logo'}
            component="div"
            sx={{
                borderRadius: 1.5,
                width: 40,
                height: 40,
                display: 'inline-flex',
                ...sx,
            }}
            {...other}
        >
            <Stack direction={"row"} alignItems={"center"} sx={{flex: 1}}>
                <Stack direction={"column"} alignItems={"center"} sx={{flex: 1}}>
                    <Box component={'img'} src={"/logo512.png"} width={32} height={32}/>
                </Stack>
            </Stack>
        </Card>
    );

    if (disableLink) {
        return <>{logo}</>;
    }

    return (
        <Link to={linkTo} component={RouterLink} sx={{display: 'contents'}}>
            {logo}
        </Link>
    );
}