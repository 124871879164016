import {Navigate} from "react-router-dom";
import {Planner} from "./models/Planner";
import React from "react";
import {RealmProvider, UserProvider} from "@realm/react";
import DashboardLayout from "./layouts/dashboard";
import {Homework} from "./models/Homework";
import {Subject} from "./models/Subject";
import {EventRemindAt} from "./models/EventRemindAt";
import {EventStep} from "./models/EventStep";
import {Exam} from "./models/Exam";
import {Reminder} from "./models/Reminder";
import {Holiday} from "./models/Holiday";
import {Timetable} from "./models/Timetable";
import {Lesson} from "./models/Lesson";
import {LessonOccurrence} from "./models/LessonOccurrence";
import {RecurringPattern} from "./models/RecurringPattern";
import {LessonInstanceException} from "./models/LessonInstanceException";

export default function AuthenticatedApp() {
    return (
        <UserProvider fallback={<Navigate to='/login'/>}>
            <RealmProvider
                schema={[
                    Planner,
                    Subject,
                    Timetable,
                    Lesson,
                    LessonOccurrence,
                    RecurringPattern,
                    LessonInstanceException,
                    Homework,
                    Exam,
                    Reminder,
                    EventRemindAt,
                    EventStep,
                    Holiday
                ]}
                sync={{
                    flexible: true,
                    initialSubscriptions: {
                        update: ((mutableSubs, realm) => {
                            mutableSubs.add(realm.objects(Planner), {name: 'all_planners'});
                            mutableSubs.add(realm.objects(Timetable), {name: 'all_timetables'});
                            mutableSubs.add(realm.objects(Lesson), {name: 'all_lessons'});
                            mutableSubs.add(realm.objects(LessonOccurrence), {name: 'all_lesson_occurrences'});
                            mutableSubs.add(realm.objects(Homework), {name: 'all_homework'});
                            mutableSubs.add(realm.objects(Exam), {name: 'all_exams'});
                            mutableSubs.add(realm.objects(Reminder), {name: 'all_reminders'});
                            mutableSubs.add(realm.objects(Subject), {name: 'all_subjects'});
                            mutableSubs.add(realm.objects(Holiday), {name: 'all_holidays'});
                        }),
                    }
                }}
            >
                <DashboardLayout/>
            </RealmProvider>
        </UserProvider>
    )
}