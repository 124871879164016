import {styled} from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import {TransitionProps} from "@mui/material/transitions";
import {Slide} from "@mui/material";

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        '& .MuiPaper-root': {
            borderRadius: '16px',
            border: `0.01em solid rgba(0, 0, 0, 0.7)`,
            boxSizing: 'border-box',
        }
    }
}))

export const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    // eslint-disable-next-line react/jsx-no-undef
    return <Slide direction="up" ref={ref} {...props} />;
});