import {Scheduling, TimeFormat, TimetableDecorator} from "../../../../../model/TimetableDecorator";
import {LessonOccurrenceDecorator} from "../../../../../model/LessonOccurrenceDecorator";
import {isTwelveHourClock} from "../../../../../utils/date";
import moment from "moment/moment";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {
    Button,
    IconButton,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import * as React from "react";
import {MobileTimePicker, StaticDatePicker} from "@mui/x-date-pickers";
import Iconify from "../../../../../common/components/iconify";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

export default function OccurrenceLayout(
    props: {
        timetable: TimetableDecorator,
        occurrence: LessonOccurrenceDecorator
        index: number
        // Date
        handleDateChange: (date: moment.Moment) => void
        handleDayOfWeekChange: (dayOfWeek: number) => void
        handleIndexOfWeekChange: (indexOfWeek: number) => void
        handleIndexOfDayChange: (indexOfDay: number) => void
        // Time
        handleStartTimeInMinutesChange: (timeInMinutes: number) => void
        handleEndTimeInMinutesChange: (timeInPeriods: number) => void
        handleStartTimeInPeriodsChange: (timeInMinutes: number) => void
        handleEndTimeInPeriodsChange: (timeInPeriods: number) => void
        // Events
        handleOnUpdateRepeat: () => void
        handleOnOccurrenceDelete: () => void
    }
) {
    const theme = useTheme()
    const {t} = useTranslation()

    const isDefaultRecurrence = props.occurrence.isRecurring() && !props.occurrence.getRecurringPattern()

    // -----------------------------------------------------------------------------------------------------------------
    /* Constants */
    const indexOfWeek = props.occurrence.getIndexOfWeek() >= 0 && props.occurrence.getIndexOfWeek() < props.timetable.getNumberOfWeeks() ? props.occurrence.getIndexOfWeek() : undefined
    const indexOfDay = props.occurrence.getIndexOfDay() >= 0 && props.occurrence.getIndexOfDay() < props.timetable.getShiftNumberOfDays() ? props.occurrence.getIndexOfDay() : undefined
    const startTime = getStartTime(props.timetable, props.occurrence)
    const endTime = getEndTime(props.timetable, props.occurrence)

    // -----------------------------------------------------------------------------------------------------------------
    /* State */
    const [timePickerOpen, setTimePickerOpen] = React.useState<'start' | 'end' | undefined>(undefined)
    const [timePickerStartTimeInMinutes, setTimePickerStartTimeInMinutes] = React.useState<number | undefined>(undefined)
    const [timePickerEndTimeInMinutes, setTimePickerEndTimeInMinutes] = React.useState<number | undefined>(undefined)

    // -----------------------------------------------------------------------------------------------------------------
    /* Ui */
    const [dateAnchorEl, setDateAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const dateOpen = Boolean(dateAnchorEl);
    const popoverDateId = dateOpen ? 'date-popover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    /* Functions */

    const getTimePickerStartTime = () => {
        const timeInMinutes = timePickerStartTimeInMinutes !== undefined ?
            timePickerStartTimeInMinutes : props.occurrence.getStartTimeInMinutes()

        if (timeInMinutes !== undefined && timeInMinutes !== null) {
            return moment()
                .startOf('year')    // Daylight savings time
                .set({"hour": 0, "minute": 0})
                .add(timeInMinutes, "minutes")
        } else {
            return undefined
        }
    }

    const getTimePickerEndTime = () => {
        const timeInMinutes = timePickerEndTimeInMinutes !== undefined ?
            timePickerEndTimeInMinutes : props.occurrence.getEndTimeInMinutes()

        if (timeInMinutes !== undefined && timeInMinutes !== null) {
            return moment()
                .startOf('year')    // Daylight savings time
                .set({"hour": 0, "minute": 0})
                .add(timeInMinutes, "minutes")
        } else {
            return undefined
        }
    }

    return (
        <>
            {props.index > 0 &&
                <Stack direction={'row'} alignItems={'center'} sx={{mt: 2}}>
                    <Iconify
                        // @ts-ignore
                        icon="material-symbols:calendar-month-outline-rounded"
                        color={theme.palette.primary.main}
                        width={18}
                        height={18}
                        mb={0.25}
                    />
                    <Typography
                        fontWeight={600}
                        variant={'body2'}
                        ml={1.5}
                        noWrap={true}
                        color={theme.palette.text.primary}
                    >
                        {t('timetable.lesson.commit_dialog.occurrence.occurrence_format', {val: props.index + 1})}
                    </Typography>
                    <Box sx={{flex: 1}}/>
                    <IconButton onClick={() => props.handleOnOccurrenceDelete()}>
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:delete-outline"
                            color={theme.palette.error.main}
                            width={18}
                            height={18}
                        />
                    </IconButton>
                </Stack>
            }
            <Stack
                direction={'row'}
                alignItems={'center'}
                mt={2}
            >
                <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                    {t('timetable.lesson.commit_dialog.occurrence.day_label')}
                </Typography>
                <Stack direction={'row'}>
                    {
                        isDefaultRecurrence && props.timetable.getScheduling() === Scheduling.WEEKLY && (
                            <FormControl size="small">
                                <Select
                                    labelId="days-of-week-select-small"
                                    id="days-of-week-select-small"
                                    value={props.occurrence.getDate()?.isoWeekday().toString()}
                                    onChange={(event: SelectChangeEvent) => {
                                        props.handleDayOfWeekChange(parseInt(event.target.value))
                                    }}
                                >
                                    {
                                        Array(7).fill(0).map((_, index) => {
                                            return (
                                                <MenuItem
                                                    key={(index + 1).toString()}
                                                    value={(index + 1).toString()}
                                                >
                                                    <Typography
                                                        variant={'body2'}
                                                        sx={{textTransform: 'capitalize'}}
                                                    >
                                                        {moment().day(index + 1).format('dddd')}
                                                    </Typography>
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )
                    }
                    {
                        isDefaultRecurrence &&
                        props.timetable.getScheduling() === Scheduling.WEEKLY &&
                        props.timetable.getNumberOfWeeks() > 1 &&
                        <FormControl size="small">
                            <Select
                                labelId="index-of-week-select-small"
                                id="index-of-week-select-small"
                                value={(indexOfWeek ?? 0).toString()}
                                onChange={(event: SelectChangeEvent) => {
                                    props.handleIndexOfWeekChange(parseInt(event.target.value))
                                }}
                                sx={{ml: 1}}
                            >
                                {
                                    Array(props.timetable.getNumberOfWeeks()).fill(0).map((_, index) => {
                                        return (
                                            <MenuItem
                                                key={index.toString()}
                                                value={index.toString()}
                                            >
                                                <Typography
                                                    variant={'body2'}
                                                    sx={{textTransform: 'capitalize'}}
                                                >
                                                    {t('timetable.lesson.commit_dialog.occurrence.week_format', {val: index + 1})}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    }
                    {
                        isDefaultRecurrence &&
                        props.timetable.getScheduling() === Scheduling.SHIFT &&
                        <FormControl size="small">
                            <Select
                                labelId="index-of-day-select-small"
                                id="index-of-day-select-small"
                                value={(indexOfDay ?? 0).toString()}
                                onChange={(event: SelectChangeEvent) => {
                                    props.handleIndexOfDayChange(parseInt(event.target.value))
                                }}
                                sx={{ml: 1}}
                            >
                                {
                                    Array(props.timetable.getShiftNumberOfDays()).fill(0).map((_, index) => {
                                        return (
                                            <MenuItem
                                                key={index.toString()}
                                                value={index.toString()}
                                            >
                                                <Typography
                                                    variant={'body2'}
                                                    sx={{textTransform: 'capitalize'}}
                                                >
                                                    {t('timetable.lesson.commit_dialog.occurrence.shift_day_format', {val: index + 1})}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    }
                    {
                        !isDefaultRecurrence && <Button
                            variant={'outlined'}
                            color={'inherit'}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setDateAnchorEl(event.currentTarget);
                            }}
                            sx={{py: '8.5px'}}
                        >
                            <Typography variant={'body2'}>
                                {props.occurrence.getDate()?.format('DD MMM YYYY') || (t('choose'))}
                            </Typography>
                        </Button>
                    }
                </Stack>
            </Stack>

            <Stack
                direction={"row"}
                alignItems={'center'}
                mt={2}
            >
                <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                    {t('timetable.lesson.commit_dialog.occurrence.time_label')}
                </Typography>
                <Stack direction={'row'} alignItems={'center'}>
                    {
                        props.timetable.getTimeFormat() === TimeFormat.HOUR && <>
                            <Button
                                variant={'outlined'}
                                color={'inherit'}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    setTimePickerOpen('start')
                                }}
                                sx={{py: '8.5px'}}
                            >
                                <Typography variant={'body2'}>
                                    {startTime ?? t('timetable.lesson.commit_dialog.occurrence.time_not_set')}
                                </Typography>
                            </Button>
                            <Iconify
                                // @ts-ignore
                                icon={'mdi:arrow-right'}
                                color={theme.palette.text.secondary}
                                sx={{ml: 1, mr: 1}}
                            />
                            <Button
                                variant={'outlined'}
                                color={'inherit'}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    setTimePickerOpen('end')
                                }}
                                sx={{py: '8.5px'}}
                            >
                                <Typography variant={'body2'}>
                                    {endTime ?? t('timetable.lesson.commit_dialog.occurrence.time_not_set')}
                                </Typography>
                            </Button>
                        </>
                    }
                    {
                        props.timetable.getTimeFormat() === TimeFormat.PERIOD && <>
                            <FormControl size="small">
                                <Select
                                    labelId="start-time-period-select-small"
                                    id="start-time-period-select-small"
                                    value={props.occurrence.getStartTimeInPeriod()?.toString() || '1'}
                                    onChange={(event: SelectChangeEvent) => {
                                        props.handleStartTimeInPeriodsChange(parseInt(event.target.value))
                                    }}
                                >
                                    {
                                        Array(props.timetable.getNumberOfPeriods()).fill(0)
                                            .map((_, index) => {
                                                return (
                                                    <MenuItem
                                                        key={(index + 1).toString()}
                                                        value={(index + 1).toString()}
                                                    >
                                                        <Typography
                                                            variant={'body2'}
                                                            sx={{textTransform: 'capitalize'}}
                                                        >
                                                            {t('timetable.lesson.commit_dialog.occurrence.period_format', {val: index + 1})}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                    }
                                </Select>
                            </FormControl>
                            <Iconify
                                // @ts-ignore
                                icon={'mdi:arrow-right'}
                                color={theme.palette.text.secondary}
                                sx={{ml: 1, mr: 1}}
                            />
                            <FormControl size="small">
                                <Select
                                    labelId="end-time-period-select-small"
                                    id="end-time-period-select-small"
                                    value={props.occurrence.getEndTimeInPeriod()?.toString() || '1'}
                                    onChange={(event: SelectChangeEvent) => {
                                        props.handleEndTimeInPeriodsChange(parseInt(event.target.value))
                                    }}
                                >
                                    {
                                        Array(props.timetable.getNumberOfPeriods()).fill(0)
                                            .map((_, index) => {
                                                return (
                                                    <MenuItem
                                                        key={(index + 1).toString()}
                                                        value={(index + 1).toString()}
                                                    >
                                                        <Typography
                                                            variant={'body2'}
                                                            sx={{textTransform: 'capitalize'}}
                                                        >
                                                            {t('timetable.lesson.commit_dialog.occurrence.period_format', {val: index + 1})}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                    }
                                </Select>
                            </FormControl>
                        </>
                    }
                </Stack>
            </Stack>

            <Stack
                direction={"row"}
                alignItems={'center'}
                mt={2}
            >
                <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                    {t('timetable.lesson.commit_dialog.occurrence.repeat_label')}
                </Typography>
                <Button
                    variant={'outlined'}
                    color={'inherit'}
                    onClick={props.handleOnUpdateRepeat}
                    sx={{py: '8.5px'}}
                >
                    <Typography variant={'body2'}>
                        {props.occurrence.formatRecurrence(props.timetable, t)}
                    </Typography>
                </Button>
            </Stack>

            <Divider sx={{mt: 2}}/>

            <Popover
                id={popoverDateId}
                open={dateOpen}
                anchorEl={dateAnchorEl}
                onClose={() => setDateAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={props.occurrence.getDate()}
                    onChange={(newDate: moment.Moment | null) => {
                        if (newDate) {
                            props.handleDateChange(newDate)
                        }
                        setDateAnchorEl(null)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Popover>

            <MobileTimePicker
                open={!!timePickerOpen}
                onClose={() => setTimePickerOpen(undefined)}
                value={
                    timePickerOpen === 'start' ? getTimePickerStartTime() :
                        (timePickerOpen === 'end' ? getTimePickerEndTime() : undefined)
                }
                onChange={(value) => {
                    if (value && value.isValid()) {
                        const timeInMinutes = value.hour() * 60 + value.minute()
                        if (timePickerOpen === 'start') {
                            setTimePickerStartTimeInMinutes(timeInMinutes)
                        } else if (timePickerOpen === 'end') {
                            setTimePickerEndTimeInMinutes(timeInMinutes)
                        }
                    }
                }}
                onAccept={() => {
                    if (timePickerOpen === 'start') {
                        const start = getTimePickerStartTime()
                        if (start) {
                            props.handleStartTimeInMinutesChange(start.hour() * 60 + start.minute())
                        }
                    } else if (timePickerOpen === 'end') {
                        const end = getTimePickerEndTime()
                        if (end) {
                            props.handleEndTimeInMinutesChange(end.hour() * 60 + end.minute())
                        }
                    }
                }}
                renderInput={() => <></>}
            />
        </>
    )
}


// --------------------------------------------
// Helper functions
const getStartTime = (
    timetable: TimetableDecorator,
    occurrence: LessonOccurrenceDecorator
): string | undefined => {
    const isTwelveHour = isTwelveHourClock()

    if (timetable.getTimeFormat() === TimeFormat.PERIOD) {
        const startInPeriod = occurrence.getStartTimeInPeriod()
        if (startInPeriod !== undefined && startInPeriod !== null) {
            return "Period " + startInPeriod
        }
    } else {
        const startInMinutes = occurrence.getStartTimeInMinutes()
        if (startInMinutes !== undefined && startInMinutes !== null) {
            return moment()
                .startOf('year')    // Daylight savings time
                .set({
                    "hour": startInMinutes / 60,
                    "minute": startInMinutes % 60
                })
                .format(isTwelveHour ? "h:mm A" : "H:mm")
        }
    }
}

const getEndTime = (
    timetable: TimetableDecorator,
    occurrence: LessonOccurrenceDecorator
): string | undefined => {
    const isTwelveHour = isTwelveHourClock()

    if (timetable.getTimeFormat() === TimeFormat.PERIOD) {
        const endInPeriod = occurrence.getEndTimeInPeriod()
        if (endInPeriod !== undefined && endInPeriod !== null) {
            return "Period " + endInPeriod
        }
    } else {
        const endInMinutes = occurrence.getEndTimeInMinutes()
        if (endInMinutes !== undefined && endInMinutes !== null) {
            return moment()
                .startOf('year')    // Daylight savings time
                .set({
                    "hour": endInMinutes / 60,
                    "minute": endInMinutes % 60
                })
                .format(isTwelveHour ? "h:mm A" : "H:mm")
        }
    }
}