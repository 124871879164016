import Heading from '../../../common/components/Heading';
import A from '../../../common/components/Link';
import Link from '../../../common/components/Link';
import Text from '../../../common/components/Text';
import Container from '../../../common/components/UI/Container';
import {footerTop} from '../../../common/data';
import React from 'react';
import {Icon} from 'react-icons-kit';
import {paperPlane} from 'react-icons-kit/fa/paperPlane';
import {ic_place} from 'react-icons-kit/md/ic_place';
import {AboutUs, ContactInfo, FooterWidget, Grid, InfoItem} from './footer.style';
import Logo from "../../../common/components/Logo";
import {useTranslation} from "react-i18next";

const FooterTop = (props) => {
    const {about, widgets, contactInfo} = footerTop || {};
    const {t} = useTranslation()

    return (
        <Container {...props}>
            <Grid>
                <AboutUs>
                    <Link href="/">
                        <Logo linkTo={'/'} />
                    </Link>
                    <Text content={t(about?.text)} mt={2} />
                </AboutUs>
                {widgets?.length ? widgets?.map((item) => (
                        <FooterWidget key={item?.id}>
                            <h4>{t(item?.title)}</h4>
                            {item?.list?.length ?
                                <ul>
                                    {item?.list?.map((item) => (
                                        <li className="widgetListItem" key={item.id}>
                                            <A href={item?.link}>{t(item?.title)}</A>
                                        </li>
                                    ))}
                                </ul>
                                :
                                ""
                            }
                        </FooterWidget>
                    ))
                    :
                    ""
                }
                <ContactInfo>
                    <Heading as="h4" content={t(contactInfo?.title)}/>
                    <InfoItem>
                        <Icon icon={ic_place} size={24}/>
                        <Text content={t(contactInfo?.address)}/>
                    </InfoItem>
                    <InfoItem>
                        <Icon icon={paperPlane} size={22}/>
                        <Text content={t(contactInfo?.email)}/>
                    </InfoItem>
                </ContactInfo>
            </Grid>
        </Container>
    );
};

export default FooterTop;
