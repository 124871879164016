import React from "react";
import {Button, Container, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({theme}) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 4),
}));

// ----------------------------------------------------------------------

interface Props {
    handleCreatePlanner: () => void
}

// ----------------------------------------------------------------------
export default function PlannerNotSelected(props: Props) {
    const {t} = useTranslation();

    return (
        <>
            <Container>
                <StyledContent
                    sx={{
                        textAlign: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        component="img"
                        src="/assets/illustrations/ic_empty_state_01.svg"
                        sx={{height: '224px', mx: 'auto', mb: {xs: 6, sm: 8}}}
                    />

                    <Typography variant="h4" paragraph>
                        {t('planner.empty_state.title')}
                    </Typography>

                    <Typography sx={{color: 'text.secondary'}}>
                        {t('planner.empty_state.subtitle')}
                    </Typography>

                    <Button
                        onClick={props.handleCreatePlanner}
                        size={"large"}
                        variant={"contained"}
                        color={'primary'}
                        sx={{mt: 5, px: 3, borderRadius: '24px'}}
                    >
                        {t('planner.empty_state.button')}
                    </Button>
                </StyledContent>
            </Container>
        </>
    );
}