import React from "react";
import {styled, useTheme} from "@mui/material/styles";
import Nav from "./nav";
import {Outlet} from "react-router-dom";
import Header, {HEADER_DESKTOP, HEADER_MOBILE} from "./header";
import {Link} from "@mui/material";
import {NAV_WIDTH} from "../dashboard/nav";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

export const LinkIndex = styled(Link)(({theme}) => ({
    display: "flex",
    minHeight: "40px",
    alignItems: "center",
    transition: "0.15s ease-in-out",
    color: theme.palette.text.primary,
    "svg": {
        marginRight: "8px"
    }
}));

const StyledRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flex: 1,
    minHeight: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default
}));

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    display: 'flex',
    flex: 1,
    overflow: 'auto',
    marginTop: HEADER_MOBILE + 'px',
    paddingTop: '16px',
    [theme.breakpoints.up('md')]: {
        marginTop: HEADER_DESKTOP + 'px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${NAV_WIDTH}px`,
        })
    }
}));

// ----------------------------------------------------------------------

export default function SupportLayout() {
    const theme = useTheme()
    const [open, setOpen] = React.useState(true);

    return (
        <StyledRoot>
            <Header
                open={open}
                onOpenNav={() => setOpen(!open)}
            />

            <Nav
                openNav={open}
                onCloseNav={() => setOpen(false)}
            />

            <Main open={open}>
                <Outlet/>
            </Main>
        </StyledRoot>
    );
}