import Link from '../../../common/components/Link';
import Text from '../../../common/components/Text';
import Container from '../../../common/components/UI/Container';
import {footer} from '../../../common/data';
import React from 'react';
import {FooterBottom, FooterNav} from './footer.style';
import {useTranslation} from "react-i18next";

const Footer_Bottom = (props) => {
    const {copyright, nav} = footer || {}
    const {t} = useTranslation()

    return (
        <Container {...props}>
            <FooterBottom>
                <Text content={t(copyright)}/>
                <FooterNav>
                    {nav?.map((item, index) => (
                        <li key={index}>
                            <Link href={item?.link}>{t(item.title)}</Link>
                        </li>
                    ))}
                </FooterNav>
            </FooterBottom>
        </Container>
    );
};

export default Footer_Bottom;
