import {Button, Card, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import React from "react";
import {useTheme} from "@mui/material/styles";
import SvgColor from "../../../../common/components/svg-color";
import {useTranslation} from "react-i18next";
import {Timetable} from "../../../../models/Timetable";

function TimetableSelector(
    props: {
        timetables: Timetable[] | undefined | null,
        selectedTimetable: Timetable | undefined | null,
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    }
) {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <>
            <Card
                variant={'outlined'}
                sx={{minWidth: '160px', height: '48px'}}
            >
                <Button
                    onClick={props.onClick}
                    color={'inherit'}
                    sx={{width: '100%', height: '100%'}}
                >
                    {
                        props.selectedTimetable && <SvgColor
                            // @ts-ignore
                            src={`/assets/icons/navbar/ic_timetable.svg`}
                            sx={{width: '20px', height: '20px', ml: 1.5, color: theme.palette.primary.main}}
                        />
                    }
                    <Typography
                        variant='body2'
                        fontWeight={600}
                        noWrap={true}
                        textAlign={'left'}
                        sx={{
                            flex: 1,
                            ml: 1.5,
                            color: theme.palette.text.primary,
                            textTransform: 'none'
                        }}
                    >
                        {
                            props.selectedTimetable ? props.selectedTimetable.title : t("timetable.selector.choose")
                        }

                    </Typography>
                    <Iconify
                        // @ts-ignore
                        icon="material-symbols:arrow-drop-down"
                        color={theme.palette.text.secondary}
                        ml={0.5}
                        mr={1}
                    />
                </Button>
            </Card>
        </>
    )
}

export default TimetableSelector