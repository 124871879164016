import {useTheme} from "@mui/material/styles";
import {Popover} from "@mui/material";
import * as React from "react";
import {ChromePicker, Color, ColorChangeHandler} from "react-color";

interface Props {
    id: string | undefined
    color: Color
    open: boolean
    anchorEl: Element | null | undefined
    handleClose: () => void
    onChange: ColorChangeHandler
}

export default function ColorPopover(props: Props) {
    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <ChromePicker
                color={props.color} onChange={props.onChange} />
        </Popover>
    )
}