import moment from "moment/moment";
import {MONGO_DATE_FORMAT} from "../utils/date";
import {Holiday} from "../models/Holiday";

export class HolidayDecorator {
    private readonly holiday: Holiday;

    constructor(holiday: Holiday) {
        this.holiday = holiday;
    }

    private getMomentDate(date: string): moment.Moment | null {
        const momentDate = moment(date, MONGO_DATE_FORMAT, true);
        return momentDate.isValid() ? momentDate : null;
    }

    getId = (): string => this.holiday._id;
    getTitle = (): string => this.holiday.name;
    getStartDate = (): moment.Moment | null => this.getMomentDate(this.holiday.start_date);
    getEndDate = (): moment.Moment | null => this.getMomentDate(this.holiday.end_date);
    getPushSchedule = (): boolean => this.holiday.push_schedule;
}