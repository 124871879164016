import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import ObjectID from "bson-objectid";
import {Planner} from "../models/Planner";
import {Holiday} from "../models/Holiday";

export interface HolidayInsertOptions {
    planner_id: string;
    name: string;
    start_date: string;
    end_date: string;
    push_schedule: boolean;
}

export interface HolidayUpdateOptions {
    name: string;
    start_date: string;
    end_date: string;
    push_schedule: boolean;
}

export function useHolidayManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const holidays = useQuery(Holiday, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a holiday in the database.
     */
    const createHoliday = useCallback((options: HolidayInsertOptions) => {
        realm.write(() => {
            realm.create(Holiday, {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                planner: realm.objectForPrimaryKey(Planner, options.planner_id) ?? undefined,
                ...options
            });
        });
    }, [realm, user.id]);

    /**
     * Update a holiday in the database.
     */
    const updateHoliday = useCallback((objectId: string, options: HolidayUpdateOptions) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Holiday, objectId);
            if (!object) return;

            object.name = options.name;
            object.start_date = options.start_date;
            object.end_date = options.end_date;
            object.push_schedule = options.push_schedule;
        });
    }, [realm]);

    /**
     * Deletes a holiday from the database.
     */
    const deleteHoliday = useCallback((objectId: string) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Holiday, objectId)
            if (object) {
                realm.delete(object);
            }
        });
    }, [realm]);

    return {
        holidays,
        createHoliday,
        updateHoliday,
        deleteHoliday
    }
}