import {styled, useTheme} from "@mui/material/styles";
import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import {colorToHexColor} from "../../../../utils/color";
import React from "react";
import {Subject} from "../../../../models/Subject";

interface Props {
    subject: Subject
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export default function SubjectChip(props: Props) {
    const theme = useTheme();

    return (
        <StyledContent>
            <StyledButton color={'inherit'} onClick={props.onClick} sx={{maxWidth: 240}}>
                <Stack
                    direction='row'
                    alignItems='top'
                    sx={{flex: 1, marginTop: '1px'}}
                >
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:circle"}
                        color={colorToHexColor(props.subject.color)}
                        width={12}
                        height={12}
                        sx={{marginTop: '3px'}}
                    />
                    <Typography
                        variant='caption'
                        color={theme.palette.text.secondary}
                        noWrap={true}
                        textAlign={"start"}
                        sx={{flex: 1, marginLeft: '6px'}}
                    >
                        {props.subject?.name || ""}
                    </Typography>
                </Stack>
            </StyledButton>
        </StyledContent>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
const StyledButton = styled(Button)(() => ({
    display: "inline-block",
    flex: "0 0 0px",
    minHeight: "28px",
    minWidth: 0,
    borderRadius: "14px",
    padding: "2px 14px",
    backgroundColor: "rgba(0,0,0,0.03)"
}));

const StyledContent = styled('div')(() => ({
    display: 'flex',
    alignItems: 'end',
    alignContent: 'end',
    flexDirection: 'column',
}));