/**
 * Constants
 */

/* Realm
    * The Realm App ID and Base URL are used to initialize the Realm App.
 */
export const APP_ID = "school-planner-waybi"
export const APP_BASE_URL = "https://europe-west1.gcp.realm.mongodb.com"

/* Auth */
export const TOKEN_NAME = "authToken";

/* Local Storage */
export const SELECTED_PLANNER_ID_NAME = "selectedPlannerId"
export const SELECTED_TIMETABLE_ID_NAME = "selectedTimetableId"

/* URLs */
export const GOOGLE_PLAY_APP_URL = "https://play.google.com/store/apps/details?id=daldev.android.gradehelper"
export const MAC_OS_ELECTRON_LATEST_URL = "https://github.com/andreadalcin/school-planner-electron/releases/download/v1.0.3/School-Planner-1.0.3-universal.dmg"
export const WINDOWS_ELECTRON_LATEST_URL = "https://github.com/andreadalcin/school-planner-electron/releases/download/v1.0.3/School-Planner-Setup-1.0.3.exe"
export const DESKTOP_CLIENT_URL = "https://schoolplanner.io"

/* GraphQL */
export const GRAPHQL_URI = `https://europe-west1.gcp.realm.mongodb.com/api/client/v2.0/app/school-planner-waybi/graphql`;
export const GLOBAL_POLL_INTERVAL = 1000 * 60       // 60 seconds