import React, {useState} from 'react';
import Container from '../../../common/components/UI/Container';
import Heading from '../../../common/components/Heading';
import Text from '../../../common/components/Text';
import Section, {BannerContent, BannerContentWrapper, Figure, Subscribe,} from './banner.style';
import Box from "@mui/material/Box";
import {Button, MenuItem, Popover, Stack, Typography} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import Iconify from "../../../common/components/iconify";
import useMediaQuery from "@mui/material/useMediaQuery";
import {bannerData} from "../../../common/data";
import {useTranslation} from "react-i18next";
import {GOOGLE_PLAY_APP_URL, MAC_OS_ELECTRON_LATEST_URL, WINDOWS_ELECTRON_LATEST_URL} from "../../../common/Constants";
import PropTypes from "prop-types";

export const CustomButton = styled(Button)(({theme}) => ({
    minHeight: '40px',
    boxShadow: 'none',
    fontWeight: 600,
    paddingRight: '12px',
    fontSize: theme.typography.body1.fontSize,
    textTransform: 'none'
}));

export const DownloadPopover = ({isDesktop}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <CustomButton
                variant={'contained'}
                color={'primary'}
                onClick={handleOpen}
                sx={{
                    ml: isDesktop ? 2 : 0,
                    mt: isDesktop ? 0 : 2,
                    borderRadius: 1.5,
                }}
            >
                {t('landing.banner.get')}
                <Iconify
                    icon={'material-symbols:arrow-right-alt-rounded'}
                    color={'#fff'}
                    width={24}
                    height={24}
                    ml={1}
                />
            </CustomButton>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    sx: {
                        p: 1,
                        mt: 1.5,
                        width: 260,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <Stack spacing={0.75}>
                    <MenuItem
                        onClick={() => {
                            window.open(GOOGLE_PLAY_APP_URL, '_blank', 'noreferrer')
                        }}
                        style={{minHeight: '40px'}}
                    >
                        <Iconify
                            icon={'ph:android-logo-fill'}
                            width={24}
                            height={38}
                            color={'#65db84'}
                            ml={0.5}
                            mr={2}
                        />
                        <Typography
                            variant={'body1'}
                            fontWeight={600}
                        >
                            Android
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            window.open(MAC_OS_ELECTRON_LATEST_URL, '_blank', 'noreferrer')
                        }}
                        style={{minHeight: '40px'}}
                    >
                        <Iconify
                            icon={'mdi:apple'}
                            width={24}
                            height={38}
                            color={'#8c8c8c'}
                            ml={0.5}
                            mr={2}
                        />
                        <Typography
                            variant={'body1'}
                            fontWeight={600}
                        >
                            macOS
                        </Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            window.open(WINDOWS_ELECTRON_LATEST_URL, '_blank', 'noreferrer')
                        }}
                        style={{minHeight: '40px'}}
                    >
                        <Iconify
                            icon={'simple-icons:windows11'}
                            width={18}
                            height={18}
                            color={'#3376cc'}
                            ml={0.5}
                            mr={2.5}
                        />
                        <Typography variant={'body1'} fontWeight={600}>Windows</Typography>
                    </MenuItem>
                </Stack>
            </Popover>
        </>
    );
}

DownloadPopover.propTypes = {
    isDesktop: PropTypes.bool,
    sx: PropTypes.object
};


const Banner = () => {
    const {t} = useTranslation()
    const {title, text, get} = bannerData;
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Section id="home">
            <Container width="1400px">
                <BannerContentWrapper>
                    <BannerContent>
                        <Heading
                            className="animate__animated animate__fadeInUp"
                            content={t(title)}
                        />
                        <Text
                            className="animate__animated animate__fadeInUp"
                            content={t(text)}
                        />
                        <Subscribe className="animate__animated animate__fadeInUp">
                            <DownloadPopover isDesktop={isDesktop}/>
                        </Subscribe>
                    </BannerContent>
                    <Figure className="animate__animated animate__fadeInUp animate__fast">
                        <Box
                            component={"img"}
                            src={'/assets/landing/preview.webp'}
                            alt="dashboard"
                            sx={{
                                maxWidth: isDesktop ? '100%' : 'none',
                                maxHeight: isDesktop ? 'none' : 384,
                                marginLeft: isDesktop ? 'auto' : '-24px'
                            }}
                        />
                    </Figure>
                </BannerContentWrapper>
            </Container>
        </Section>
    );
};

export default Banner;
