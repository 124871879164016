import {
    Button,
    DialogActions,
    IconButton,
    Popover,
    Snackbar,
    Stack,
    Switch,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import Iconify from "../../../common/components/iconify";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import {useState} from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {styled, useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {StaticDatePicker} from "@mui/x-date-pickers";
import {MONGO_DATE_FORMAT} from "../../../utils/date";
import {BootstrapDialog, Transition} from "../../../common/components/BootstrapDialog";
import SaveButton from "../../../common/components/SaveButton";
import {useHolidayManager} from "../../../hooks/useHolidayManager";
import {Holiday} from "../../../models/Holiday";

// ---------------------------------------------------------------------------------------------------------------------
interface Props {
    open: boolean
    handleClose: () => void
    selectedPlannerId: string
    updateOptions?: Holiday | undefined
}

// ---------------------------------------------------------------------------------------------------------------------
export default function HolidayCommitDialog(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [
        stateName,
        setName
    ] = React.useState<string | undefined>(undefined)

    if (stateName === undefined && props.updateOptions?.name) {
        setName(props.updateOptions?.name)
    }

    const [
        stateStartDate,
        setStartDate
    ] = React.useState<moment.Moment | null | undefined>(undefined)

    if (stateStartDate === undefined && props.updateOptions?.start_date) {
        const startDate = moment(props.updateOptions?.start_date, MONGO_DATE_FORMAT)
        if (startDate.isValid()) {
            setStartDate(startDate)
        }
    }

    const [
        stateEndDate,
        setEndDate
    ] = React.useState<moment.Moment | null | undefined>(undefined)

    if (stateEndDate === undefined && props.updateOptions?.end_date) {
        const endDate = moment(props.updateOptions?.end_date, MONGO_DATE_FORMAT)
        if (endDate.isValid()) {
            setEndDate(endDate)
        }
    }

    const [
        statePushSchedule,
        setPushSchedule
    ] = React.useState<boolean | undefined>(undefined)

    if (statePushSchedule === undefined && props.updateOptions?.push_schedule) {
        setPushSchedule(props.updateOptions?.push_schedule)
    }

    const name = stateName ?? t('holiday.commit_dialog.untitled_holiday') ?? ''
    const startDate = stateStartDate ?? moment().startOf('day')
    const endDate = stateEndDate ?? moment().startOf('day').add(1, 'week')
    const pushSchedule = statePushSchedule === undefined ? true : statePushSchedule

    const [startDateAnchorEl, setStartDateAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const startDateOpen = Boolean(startDateAnchorEl);
    const popoverStartDateId = startDateOpen ? 'start-date-popover' : undefined;

    const [endDateAnchorEl, setEndDateAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const endDateOpen = Boolean(endDateAnchorEl);
    const popoverEndDateId = endDateOpen ? 'end-date-popover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    // Errors
    const [
        error,
        setError
    ] = useState<string | undefined | null>(undefined)

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        createHoliday,
        updateHoliday
    } = useHolidayManager()

    const createHolidayCallback = () => {
        const result = validate_create_update(startDate, endDate)
        if (result) return result

        createHoliday({
            planner_id: props.selectedPlannerId,
            name: name,
            start_date: startDate.format(MONGO_DATE_FORMAT),
            end_date: endDate.format(MONGO_DATE_FORMAT),
            push_schedule: pushSchedule
        })

        return Promise.resolve()
    }

    const updateHolidayCallback = () => {
        const objectId = props.updateOptions?._id
        if (!objectId) return Promise.reject('holiday.commit_dialog.error.object_id_not_found')

        const result = validate_create_update(startDate, endDate)
        if (result) return result

        updateHoliday(objectId, {
            name: name,
            start_date: startDate.format(MONGO_DATE_FORMAT),
            end_date: endDate.format(MONGO_DATE_FORMAT),
            push_schedule: pushSchedule
        })

        return Promise.resolve()
    }

    // -----------------------------------------------------------------------------------------------------------------
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // -----------------------------------------------------------------------------------------------------------------
    const handleClose = () => {
        setName(undefined)
        setStartDate(undefined)
        setEndDate(undefined)
        setPushSchedule(undefined)

        props.handleClose()
    }

    const handleSaveClick = () => {
        if (props.updateOptions) {
            updateHolidayCallback().then(
                () => {
                    handleClose()
                },
                (reason) => {
                    setError(t(reason.toString()))
                }
            )
        } else {
            createHolidayCallback().then(
                () => {
                    handleClose()
                },
                (reason) => {
                    setError(t(reason.toString()))
                }
            )
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            open={props.open}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{pt: 1.5, pb: 1.5, pl: 3, pr: 2, backgroundColor: theme.palette.primary.main}}
            >
                <Typography
                    flex={1}
                    fontWeight={600}
                    fontSize={'1.15em'}
                    noWrap={true}
                    color={theme.palette.primary.contrastText}
                    sx={{textTransform: 'capitalize'}}
                >
                    {t(props.updateOptions ? 'holiday.commit_dialog.title_edit' : 'holiday.commit_dialog.title_add')}
                </Typography>
                <IconButton onClick={handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <Divider/>

            <DialogContent>
                <Stack direction="column">
                    <TitleTextArea
                        placeholder={t("task_dialog.add_title") || undefined}
                        value={name || ''}
                        onChange={(event: React.ChangeEvent) => {
                            // @ts-ignore
                            setName(event.target.value)
                        }}
                    />

                    <Stack direction={'row'} alignItems={'center'} mt={2}>
                        <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                            {t('holiday.commit_dialog.start_date')}
                        </Typography>
                        <Button
                            variant={'outlined'}
                            color={'inherit'}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setStartDateAnchorEl(event.currentTarget);
                            }}
                            sx={{py: '8.5px'}}
                        >
                            <Typography variant={'body2'}>
                                {
                                    startDate ? (startDate.format('DD MMM YYYY')) : ('Choose...')
                                }
                            </Typography>
                        </Button>
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} mt={2}>
                        <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                            {t('holiday.commit_dialog.end_date')}
                        </Typography>
                        <Button
                            variant={'outlined'}
                            color={'inherit'}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setEndDateAnchorEl(event.currentTarget);
                            }}
                            sx={{py: '8.5px'}}
                        >
                            <Typography variant={'body2'}>
                                {
                                    endDate ? (endDate.format('DD MMM YYYY')) : ('Choose...')
                                }
                            </Typography>
                        </Button>
                    </Stack>

                    <Divider sx={{mt: 2}}/>

                    <Stack direction={'row'} alignItems={'center'} mt={2}>
                        <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                            {t('holiday.commit_dialog.push_schedule')}
                        </Typography>
                        <Switch
                            checked={pushSchedule}
                            onChange={(event) => {
                                setPushSchedule(event.target.checked)
                            }}
                        />
                    </Stack>

                    <Typography variant={'caption'} mt={2} color={theme.palette.text.secondary}>
                        {t('holiday.commit_dialog.push_schedule_description')}
                    </Typography>

                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={1.5}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <SaveButton
                        handleSaveClick={handleSaveClick}
                        isSaving={false}
                        text={t("save")}
                    />
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='inherit'
                        sx={{boxShadow: 'none'}}
                    >
                        {t("cancel")}
                    </Button>
                </Stack>
            </DialogActions>

            <Popover
                id={popoverStartDateId}
                open={startDateOpen}
                anchorEl={startDateAnchorEl}
                onClose={() => setStartDateAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={startDate}
                    onChange={(newDate: moment.Moment | null) => {
                        if (newDate) {
                            setStartDate(newDate)
                        }
                        setStartDateAnchorEl(null)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Popover>

            <Popover
                id={popoverEndDateId}
                open={endDateOpen}
                anchorEl={endDateAnchorEl}
                onClose={() => setEndDateAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={endDate}
                    onChange={(newDate: moment.Moment | null) => {
                        if (newDate) {
                            setEndDate(newDate)
                        }
                        setEndDateAnchorEl(null)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Popover>

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => {
                    setError(undefined)
                }}
                message={error}
            />

        </BootstrapDialog>
    )
}

// ---------------------------------------------------------------------------------------------------------------------
const TitleTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    border: 'none',
    outline: 'none',
    resize: 'none',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: theme.typography.h4.fontWeight
}));

// ---------------------------------------------------------------------------------------------------------------------
const validate_create_update = (
    startDate: moment.Moment | undefined,
    endDate: moment.Moment | undefined
) => {
    if (startDate === undefined || endDate === undefined) {
        return Promise.reject('holiday.commit_dialog.error.start_date_end_date_empty')
    }

    if (startDate.isAfter(endDate)) {
        return Promise.reject('holiday.commit_dialog.error.start_date_after_end_date')
    }
}