import {styled, useTheme} from "@mui/material/styles";
import moment from "moment";
import {Button, Stack, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import Iconify from "../../../../common/components/iconify";
import React from "react";
import {useTranslation} from "react-i18next";
import TaskRow from "../TaskRow";
import Box from "@mui/material/Box";

// =====================================================================================================================

export const HEADER_HEIGHT = 40;
export const ROW_HEIGHT = 72;
export const ADD_HEIGHT = 56;
const MARGIN_BOTTOM = 8;

// =====================================================================================================================

const ContainerWrapper = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        padding: '0 24px'
    },
    maxWidth: '852px',
    marginLeft: 'auto',
    marginRight: 'auto'
}));

const StickyHeader = styled(Stack)(({theme}) => ({
    position: 'sticky',
    top: 0,
    zIndex: 1,
    textTransform: 'capitalize'
}));

export default function GroupRow(props: any) {
    const theme = useTheme();
    const {t} = useTranslation()

    const {index, style, data} = props;
    const {
        items,
        handleToggleHomeworkCompletedOn,
        handleToggleReminderCompletedOn,
        handleOnUpdateHomework,
        handleOnUpdateExam,
        handleOnUpdateReminder,
        handleOnUpdateSubject,
        handleOnDeleteHomework,
        handleOnDeleteExam,
        handleOnDeleteReminder,
        handleAddTask,
    } = data;

    const rowData = items[index];
    const date: moment.Moment = rowData.date
    const today = moment()
    const dateFormat = date.year() !== today.year() ? "D MMM YYYY" : "D MMM"

    const getHeaderLabel = () => {
        if (date.isSame(today, "day")) {
            return `${date.format(dateFormat)} • ${t('today')} • ${date.format("dddd")}`
        } else if (date.isSame(today.add(1, 'days'), "day")) {
            return `${date.format(dateFormat)}  •  ${t('tomorrow')}  •  ${date.format("dddd")}`
        } else {
            return `${date.format(dateFormat)} - ${date.format("dddd")}`
        }
    }

    const isEmpty = rowData.homeworkList.length === 0
        && rowData.examList.length === 0
        && rowData.reminderList.length === 0

    return (
        <div className="group" style={style}>
            <Stack direction="column">
                <StickyHeader
                    direction="column-reverse"
                    sx={{
                        height: HEADER_HEIGHT + 'px',
                        backgroundColor: theme.palette.background.default
                    }}
                >
                    <Divider/>
                    <Typography
                        fontSize="0.9rem"
                        fontWeight={600}
                        color={isEmpty ? theme.palette.text.disabled : theme.palette.text.primary}
                        sx={{marginLeft: '4px', marginRight: '4px', paddingTop: '8px', paddingBottom: '8px'}}
                    >
                        {getHeaderLabel()}
                    </Typography>
                </StickyHeader>
                {
                    // @ts-ignore
                    rowData.homeworkList.map(homework => (
                        <TaskRow
                            key={homework._id}
                            _id={homework._id}
                            title={homework.title}
                            completedOn={homework.completed_on}
                            subject={homework.subject}
                            hasCompletedOn={true}
                            handleToggleCompletedOn={() => {
                                handleToggleHomeworkCompletedOn(homework, homework.completed_on)
                            }}
                            handleOnUpdate={() => handleOnUpdateHomework(homework._id)}
                            handleOnDelete={() => handleOnDeleteHomework(homework._id)}
                            handleOnSubject={() => handleOnUpdateSubject(homework.subject._id)}
                        />
                    ))
                }
                {
                    // @ts-ignore
                    rowData.examList.map(exam => (
                        <TaskRow
                            key={exam._id}
                            _id={exam._id}
                            title={exam.title}
                            subject={exam.subject}
                            hasCompletedOn={false}
                            handleOnUpdate={() => handleOnUpdateExam(exam._id)}
                            handleOnDelete={() => handleOnDeleteExam(exam._id)}
                            handleOnSubject={() => handleOnUpdateSubject(exam.subject._id)}
                        />
                    ))
                }
                {
                    // @ts-ignore
                    rowData.reminderList.map(reminder => (
                        <TaskRow
                            key={reminder._id}
                            _id={reminder._id}
                            title={reminder.title}
                            completedOn={reminder.completed_on}
                            subject={null}
                            hasCompletedOn={true}
                            handleToggleCompletedOn={() => {
                                handleToggleReminderCompletedOn(reminder, reminder.completed_on)
                            }}
                            handleOnUpdate={() => handleOnUpdateReminder(reminder._id)}
                            handleOnDelete={() => handleOnDeleteReminder(reminder._id)}
                        />
                    ))
                }
                <Button
                    onClick={() => handleAddTask(date)}
                    color="inherit"
                    sx={{width: '100%', height: (ADD_HEIGHT - MARGIN_BOTTOM) + 'px'}}
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        sx={{width: '100%'}}
                    >
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:add"
                            color={theme.palette.text.secondary}
                            width={18}
                            height={18}
                            sx={{flexShrink: 0}}
                        />
                        <Typography
                            variant='body2'
                            noWrap={true}
                            color={theme.palette.text.secondary}
                            ml={1}
                        >
                            {t("add")}
                        </Typography>
                    </Stack>
                </Button>
            </Stack>
        </div>
    );
}