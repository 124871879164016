import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import ObjectID from "bson-objectid";
import {Planner} from "../models/Planner";
import {Subject} from "../models/Subject";
import {EventRemindAtProps} from "../models/EventRemindAt";
import {MONGO_DATE_FORMAT, MONGO_TIME_FORMAT} from "../utils/date";
import {EventStepProps} from "../models/EventStep";
import {Exam} from "../models/Exam";

export interface ExamInsertOptions {
    planner_id: string;
    subject_id?: string;
    title: string;
    date?: string;
    category?: number;
    archived: boolean;
    note?: string;
    remind_at?: EventRemindAtProps;
    steps?: EventStepProps[];
    created_on?: string;
}

export interface ExamUpdateOptions {
    subject_id?: string;
    title: string;
    date?: string;
    category?: number;
    archived: boolean;
    note?: string;
    remind_at?: EventRemindAtProps;
    steps?: EventStepProps[];
}

export function useExamManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const exams = useQuery(Exam, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a homework in the database.
     */
    const createExam = useCallback((options: ExamInsertOptions) => {
        realm.write(() => {
            const object = realm.create(Exam, {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                planner: realm.objectForPrimaryKey(Planner, options.planner_id) ?? undefined,
                ...(options.subject_id) && {subject: realm.objectForPrimaryKey(Subject, options.subject_id) ?? undefined},
                ...options
            });

            if (options.remind_at) {
                const date = options.remind_at.date?.format(MONGO_DATE_FORMAT);
                const time = options.remind_at.time?.format(MONGO_TIME_FORMAT);
                if (date && time) { // Ensure both date and time are available
                    // @ts-ignore
                    object.remind_at_list = [{date: date, time: time}]
                }
            }

            if (options.steps) {
                // @ts-ignore
                object.steps = options.steps.map(it => {
                    return {title: it.title, completed_on: it.completed_on}
                });
            }
        });
    }, [realm, user.id]);

    /**
     * Update a homework in the database.
     */
    const updateExam = useCallback((objectId: string, options: ExamUpdateOptions) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Exam, objectId);
            if (!object) return;

            // Update subject if provided, otherwise reset to undefined
            object.subject = options.subject_id
                ? realm.objectForPrimaryKey(Subject, options.subject_id) ?? undefined
                : undefined;

            // Manage simple properties directly
            object.title = options.title;
            object.date = options.date;
            object.category = options.category;
            object.archived = options.archived;
            object.note = options.note;

            // Manage remind_at_list
            const {remind_at} = options;
            const remindAtDate = remind_at?.date?.format(MONGO_DATE_FORMAT);
            const remindAtTime = remind_at?.time?.format(MONGO_TIME_FORMAT);
            // @ts-ignore
            object.remind_at_list = remindAtDate && remindAtTime ? [{date: remindAtDate, time: remindAtTime}] : [];

            // Manage steps
            // @ts-ignore
            object.steps = options.steps ?? []
        });
    }, [realm]);

    /**
     * Deletes a homework from the database.
     */
    const deleteExam = useCallback((objectId: string) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Exam, objectId)
            if (object) {
                realm.delete(object);
            }
        });
    }, [realm]);

    return {
        exams,
        createExam,
        updateExam,
        deleteExam
    }
}