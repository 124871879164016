import {Button, DialogActions, DialogContentText, DialogTitle, Stack} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean
    onClose: () => void
    onPositive: () => void
}

export default function HolidayDeleteConfirmationDialog(props: Props) {
    const {t} = useTranslation()

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-delete-title"
            aria-describedby="alert-dialog-delete-description"
        >
            <DialogTitle id="alert-dialog-delete-title">
                {t("task.delete_dialog.title")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-delete-description">
                    This holiday cannot be recovered after being deleted
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={2}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <Button
                        variant={'contained'}
                        color={'error'}
                        autoFocus
                        onClick={props.onPositive}
                    >
                        {t("delete")}
                    </Button>
                    <Button variant={'contained'} color={'inherit'} onClick={props.onClose}>{t("cancel")}</Button>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}