import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import {Lesson, LessonProperties} from "../models/Lesson";
import ObjectID from "bson-objectid";
import {Timetable} from "../models/Timetable";
import {Subject} from "../models/Subject";

export function useLessonManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const lessons = useQuery(Lesson, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a lesson in the database.
     */
    const createLesson = useCallback((options: LessonProperties) => {
        const lessonId = ObjectID().toHexString();

        realm.write(() => {
            realm.create(Lesson, {
                _id: lessonId,
                owner_id: user.id,
                timetable: realm.objectForPrimaryKey(Timetable, options.timetableId) ?? undefined,
                subject: realm.objectForPrimaryKey(Subject, options.subjectId) ?? undefined,
                ...options
            });
        });

        return lessonId;
    }, [realm, user.id]);

    /**
     * Update a lesson in the database.
     */
    const updateLesson = useCallback((objectId: string, options: LessonProperties) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Lesson, objectId)
            if (object) {
                object.subject = options.subjectId
                    ? realm.objectForPrimaryKey(Subject, options.subjectId) ?? undefined
                    : undefined;

                object.title = options.title;
                object.color = options.color;
                object.room = options.room;
                object.note = options.note;
            }
        });
    }, [realm]);

    /**
     * Deletes a homework from the database.
     */
    const deleteLesson = useCallback((objectId: string) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(Lesson, objectId)
            if (object) {
                realm.delete(object);
            }
        });
    }, [realm]);

    return {
        lessons,
        createLesson,
        updateLesson,
        deleteLesson
    }
}