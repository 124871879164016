import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import * as React from "react";
import {CloseButton} from "../TaskDialog";
import {useTheme} from "@mui/material/styles";
import {colorToHexColor} from "../../../../utils/color";
import {useTranslation} from "react-i18next";

interface Props {
    selectedSubjectId: string | undefined | null
    name: string | null | undefined
    color: number | null | undefined
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    clearSelectedSubjectId: () => void
}

export default function SubjectButton(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    const canClear = props.selectedSubjectId != null

    return (
        <Stack direction='row' alignItems='center'>
            <Button
                color='inherit'
                sx={{width: '100%', height: '48px'}}
                onClick={props.onClick}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{width: '100%'}}
                >
                    <Iconify
                        // @ts-ignore
                        icon={
                            canClear && props.color ?
                                "material-symbols:circle" : "material-symbols:school-outline-rounded"
                        }
                        color={
                            canClear && props.color ?
                                colorToHexColor(props.color) : theme.palette.text.secondary
                        }
                        width={18}
                        height={18}
                        sx={{flexShrink: 0}}
                    />
                    <Typography
                        variant='body2'
                        noWrap={true}
                        color={canClear ? theme.palette.text.primary : theme.palette.text.secondary}
                        ml={2}
                    >
                        {canClear && props.name ? props.name : t("task_dialog.add_subject")}
                    </Typography>
                </Stack>
            </Button>
            {
                canClear && <CloseButton onClick={props.clearSelectedSubjectId}/>
            }
        </Stack>
    )
}