import {LessonWithOccurrenceIdAndDate} from "./LessonWithOccurrenceIdAndDate";

export class LessonWithSpanAndLength {
    private readonly lesson: LessonWithOccurrenceIdAndDate;
    private startSpan: number;
    private lengthInSpans: number;
    private numberOfSpans: number;

    constructor(
        lesson: LessonWithOccurrenceIdAndDate,
        startSpan: number,
        lengthInSpans: number,
        numberOfSpans: number
    ) {
        this.lesson = lesson;
        this.startSpan = startSpan;
        this.lengthInSpans = lengthInSpans;
        this.numberOfSpans = numberOfSpans;
    }

    getLesson(): LessonWithOccurrenceIdAndDate {
        return this.lesson;
    }

    getStartSpan(): number {
        return this.startSpan;
    }

    setStartSpan(startSpan: number) {
        this.startSpan = startSpan;
    }

    getNumberOfSpans(): number {
        return this.numberOfSpans;
    }

    setNumberOfSpans(numberOfSpans: number) {
        this.numberOfSpans = numberOfSpans;
    }

    getLengthInSpans(): number {
        return this.lengthInSpans;
    }

    setLengthInSpans(lengthInSpans: number) {
        this.lengthInSpans = lengthInSpans;
    }


    getTopInUnits(): number | undefined | null {
        return this.lesson.getTopInUnits()
    }

    getHeightInUnits(): number | undefined | null {
        return this.lesson.getHeightInUnits()
    }
}