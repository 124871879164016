import {
    Button,
    Checkbox,
    DialogActions,
    FormControlLabel,
    FormGroup,
    IconButton,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import FormControl from "@mui/material/FormControl";
import moment from "moment";
import {StaticDatePicker} from "@mui/x-date-pickers";
import {COLOR_DEFAULT, colorToSigned24Bit} from "../../../../utils/color";
import {MONGO_DATE_FORMAT} from "../../../../utils/date";
import {Scheduling, TimeFormat} from "../../../../model/TimetableDecorator";
import {BootstrapDialog, Transition} from "../../../../common/components/BootstrapDialog";
import SaveButton from "../../../../common/components/SaveButton";
import {useTimetableManager} from "../../../../hooks/useTimetableManager";
import {Timetable} from "../../../../models/Timetable";

// ---------------------------------------------------------------------------------------------------------------------
const TIME_FORMAT_SUBTITLES = {
    'hour': 'timetable.add.time_management.subtitle_recommended',
    'period': 'timetable.add.time_management.subtitle_period'
}


// ---------------------------------------------------------------------------------------------------------------------
interface Props {
    open: boolean
    handleClose: () => void
    selectedPlannerId: string
    updateOptions?: Timetable | undefined | null
}

export default function TimetableCommitDialog(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    const [
        stateTimetableName,
        setTimetableName
    ] = React.useState<string | undefined>(undefined)
    if (props.open && stateTimetableName === undefined && props.updateOptions?.title) {
        setTimetableName(props.updateOptions?.title)
    }

    const timetableName = stateTimetableName ?? t('timetable.add.advanced.untitled') ?? ''

    // State(Scheduling)
    const [
        stateScheduling,
        setScheduling
    ] = React.useState<'one-week' | 'n-weeks' | 'shift' | undefined>(undefined)

    if (props.open && stateScheduling === undefined && props.updateOptions?.scheduling) {
        setScheduling(props.updateOptions?.scheduling === Scheduling.SHIFT ?
            'shift' :
            (props.updateOptions?.number_of_weeks > 1 ? 'n-weeks' : 'one-week')
        )
    }
    const [
        stateNumberOfWeeks,
        setNumberOfWeeks
    ] = React.useState<2 | 3 | 4 | undefined>(undefined)

    if (props.open && stateNumberOfWeeks === undefined && props.updateOptions?.number_of_weeks) {
        setNumberOfWeeks(props.updateOptions?.number_of_weeks as 2 | 3 | 4 | undefined)
    }
    const [
        stateCurrentWeek,
        setCurrentWeek
    ] = React.useState<0 | 1 | 2 | 3 | undefined>(undefined)

    if (props.open && stateCurrentWeek === undefined && props.updateOptions?.start_week) {
        setCurrentWeek(props.updateOptions?.start_week as 0 | 1 | 2 | 3 | undefined)
    }

    const [
        stateShiftNumberOfDays,
        setShiftNumberOfDays
    ] = React.useState<number | undefined>(undefined)

    if (props.open && stateShiftNumberOfDays === undefined && props.updateOptions?.shift_number_of_days) {
        setShiftNumberOfDays(props.updateOptions?.shift_number_of_days)
    }

    const [
        stateShiftStartDay,
        setShiftStartDay
    ] = React.useState<moment.Moment | null | undefined>(undefined)

    if (props.open && stateShiftStartDay === undefined && props.updateOptions?.shift_start_day) {
        const date = moment(props.updateOptions?.shift_start_day, MONGO_DATE_FORMAT, true)
        if (date.isValid()) {
            setShiftStartDay(date)
        }
    }

    const [
        stateShiftDaysOfWeek,
        setShiftDaysOfWeek
    ] = React.useState<Set<number> | undefined>(undefined)

    /*
    if (props.open && stateShiftDaysOfWeek === undefined && props.updateOptions?.getShiftDaysOfWeek()) {
        setShiftDaysOfWeek(props.updateOptions?.getShiftDaysOfWeek())
    }
    */

    const scheduling = stateScheduling ?? 'one-week'
    const numberOfWeeks = stateNumberOfWeeks ?? 2
    const currentWeek = stateCurrentWeek ?? 0
    const shiftNumberOfDays = stateShiftNumberOfDays ?? 6
    const shiftStartDay = stateShiftStartDay ?? moment().startOf('day')
    const shiftDaysOfWeek = stateShiftDaysOfWeek ?? new Set([1, 2, 3, 4, 5])

    // State (Time Format)
    const [
        stateTimeFormat,
        setTimeFormat
    ] = React.useState<'hour' | 'period' | undefined>(undefined)

    if (props.open && stateTimeFormat === undefined && props.updateOptions?.time_format) {
        setTimeFormat(props.updateOptions?.time_format === TimeFormat.PERIOD ? 'period' : 'hour')
    }

    const [
        stateNumberOfPeriods,
        setNumberOfPeriods
    ] = React.useState<number | undefined>(undefined)

    if (props.open && stateNumberOfPeriods === undefined && props.updateOptions?.number_of_periods) {
        setNumberOfPeriods(props.updateOptions?.number_of_periods)
    }

    const timeFormat = stateTimeFormat ?? 'hour'
    const numberOfPeriods = stateNumberOfPeriods ?? 12

    const adjustedNumberOfWeeks = scheduling === 'one-week' ? 1 : numberOfWeeks
    const adjustedCurrentWeek = currentWeek ? Math.min(numberOfWeeks ?? 0, currentWeek) : 0
    const adjustedNumberOfDays = shiftNumberOfDays ? Math.max(2, shiftNumberOfDays) : 2
    const daysOfWeek = Array(7).fill(0).map((_, index) =>
        moment().startOf('week').add(index, 'days')
    )

    /* State (UI) */
    const [
        dateAnchorEl,
        setDateAnchorEl
    ] = React.useState<HTMLButtonElement | null>(null);
    const dateOpen = Boolean(dateAnchorEl);
    const popoverDateId = dateOpen ? 'date-popover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        createTimetable,
        updateTimetable
    } = useTimetableManager()

    // -----------------------------------------------------------------------------------------------------------------
    const createTimetableCallback = () => {
        const insertTimeFormat = timeFormat === 'period' ? TimeFormat.PERIOD : TimeFormat.HOUR
        const insertScheduling = scheduling === 'shift' ? Scheduling.SHIFT : Scheduling.WEEKLY

        createTimetable({
            plannerId: props.selectedPlannerId,
            title: timetableName,
            color: colorToSigned24Bit(COLOR_DEFAULT),
            scheduling: insertScheduling,
            time_format: insertTimeFormat,
            number_of_periods: numberOfPeriods,
            number_of_weeks: adjustedNumberOfWeeks,
            start_week_date: moment().format(MONGO_DATE_FORMAT),
            start_week: adjustedCurrentWeek,
            shift_number_of_days: adjustedNumberOfDays,
            shift_start_day: shiftStartDay.format(MONGO_DATE_FORMAT),
            shift_days_of_week: Array.from(shiftDaysOfWeek)
        })
    }

    const updateTimetableCallback = () => {
        const updateTimeFormat = timeFormat === 'period' ? TimeFormat.PERIOD : TimeFormat.HOUR
        const updateScheduling = scheduling === 'shift' ? Scheduling.SHIFT : Scheduling.WEEKLY

        const objectId = props.updateOptions?._id
        if (!objectId) return

        updateTimetable(objectId, {
            title: timetableName,
            color: colorToSigned24Bit(COLOR_DEFAULT),
            scheduling: updateScheduling,
            time_format: updateTimeFormat,
            number_of_periods: numberOfPeriods,
            number_of_weeks: adjustedNumberOfWeeks,
            start_week_date: moment().format(MONGO_DATE_FORMAT),
            start_week: adjustedCurrentWeek,
            shift_number_of_days: adjustedNumberOfDays,
            shift_start_day: shiftStartDay.format(MONGO_DATE_FORMAT),
            shift_days_of_week: Array.from(shiftDaysOfWeek)
        })
    }

    const handleOnSchedulingChange = (event: SelectChangeEvent) => {
        const scheduling = event.target.value as 'one-week' | 'n-weeks' | 'shift' | undefined
        setScheduling(scheduling ?? 'one-week')
    }

    const handleOnTimeFormatChange = (event: SelectChangeEvent) => {
        const timeFormat = event.target.value as 'period' | 'hour' | undefined
        setTimeFormat(timeFormat ?? 'hour')
    }

    const handleOnNumberOfWeeksChange = (event: SelectChangeEvent) => {
        const numberOfWeeks = parseInt(event.target.value) as 2 | 3 | 4 | undefined
        setNumberOfWeeks(numberOfWeeks ?? 2)
    }

    const handleOnCurrentWeekChange = (event: SelectChangeEvent) => {
        const currentWeek = parseInt(event.target.value) as 0 | 1 | 2 | 3 | undefined
        setCurrentWeek(currentWeek ?? 0)
    }

    const handleOnNumberOfDaysChange = (event: SelectChangeEvent) => {
        const numberOfDays = parseInt(event.target.value) as 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | undefined
        setShiftNumberOfDays(numberOfDays ?? 2)
    }

    const handleOnNumberOfPeriodsChange = (event: SelectChangeEvent) => {
        const numberOfPeriods = parseInt(event.target.value) as 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | undefined
        setNumberOfPeriods(numberOfPeriods ?? 12)
    }

    const handleClose = () => {
        setTimetableName(undefined)
        setScheduling(undefined)
        setNumberOfWeeks(undefined)
        setCurrentWeek(undefined)
        setShiftNumberOfDays(undefined)
        setShiftStartDay(undefined)
        setShiftDaysOfWeek(undefined)
        setTimeFormat(undefined)
        setNumberOfPeriods(undefined)

        props.handleClose()
    }

    const handleSaveClick = () => {
        if (props.updateOptions) {
            updateTimetableCallback()
            handleClose()
        } else {
            createTimetableCallback()
            handleClose()
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            open={props.open}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{pt: 1.5, pb: 1.5, pl: 3, pr: 2, backgroundColor: theme.palette.primary.main}}
            >
                <Typography
                    flex={1}
                    fontWeight={600}
                    fontSize={'1.15em'}
                    noWrap={true}
                    color={theme.palette.primary.contrastText}
                    sx={{textTransform: 'capitalize'}}
                >
                    {props.updateOptions ? t('timetable.add.title_edit') : t('timetable.add.title_add')}
                </Typography>
                <IconButton onClick={handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <Divider/>

            <DialogContent>
                <Stack direction="column">
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                    >
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:calendar-month-outline-rounded"
                            color={theme.palette.primary.main}
                            width={18}
                            height={18}
                            mb={0.25}
                        />
                        <Typography
                            fontWeight={600}
                            variant={'body2'}
                            ml={1.5}
                            noWrap={true}
                            color={theme.palette.text.primary}
                        >
                            {t('timetable.add.scheduling.title')}
                        </Typography>
                    </Stack>
                    <Stack direction={fullScreen ? "column" : "row"} alignItems={fullScreen ? 'left' : 'center'} mt={2}>
                        <Typography noWrap={true} flex={1} variant={'body2'}>
                            {t('timetable.add.scheduling.body')}
                        </Typography>
                        <FormControl size="small" sx={{...(fullScreen) && {mt: 2}}}>
                            <Select
                                labelId="scheduling-select-small"
                                id="scheduling-select-small"
                                value={scheduling}
                                onChange={(event: SelectChangeEvent) => handleOnSchedulingChange(event)}
                            >
                                <MenuItem key={'one-week'} value={'one-week'}>
                                    <Typography variant={'body2'}>
                                        {t('timetable.add.scheduling.every_week')}
                                    </Typography>
                                </MenuItem>
                                <MenuItem key={'n-weeks'} value={'n-weeks'}>
                                    <Typography variant={'body2'}>
                                        {t('timetable.add.scheduling.every_n_weeks')}
                                    </Typography>
                                </MenuItem>
                                <MenuItem key={'shift'} value={'shift'}>
                                    <Typography variant={'body2'}>
                                        {t('timetable.add.scheduling.every_numbered_day')}
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    {
                        scheduling === 'n-weeks' && (
                            <>
                                <Stack direction="row" alignItems={'center'} mt={2}>
                                    <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                                        {t('timetable.add.scheduling.number_of_weeks')}
                                    </Typography>
                                    <FormControl size="small">
                                        <Select
                                            labelId="number-of-weeks-select-small"
                                            id="number-of-weeks-select-small"
                                            value={numberOfWeeks?.toString()}
                                            onChange={(event: SelectChangeEvent) => handleOnNumberOfWeeksChange(event)}
                                        >
                                            <MenuItem value={'2'}>
                                                <Typography variant={'body2'}>
                                                    {t('timetable.add.scheduling.number_of_weeks_format', {val: 2})}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={'3'}>
                                                <Typography variant={'body2'}>
                                                    {t('timetable.add.scheduling.number_of_weeks_format', {val: 3})}
                                                </Typography>
                                            </MenuItem>
                                            <MenuItem value={'4'}>
                                                <Typography variant={'body2'}>
                                                    {t('timetable.add.scheduling.number_of_weeks_format', {val: 4})}
                                                </Typography>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction="row" alignItems={'center'} mt={2}>
                                    <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                                        {t('timetable.add.scheduling.current_week')}
                                    </Typography>
                                    <FormControl size="small">
                                        <Select
                                            labelId="current-week-select-small"
                                            id="current-week-select-small"
                                            value={adjustedCurrentWeek?.toString()}
                                            onChange={(event: SelectChangeEvent) => handleOnCurrentWeekChange(event)}
                                        >
                                            {
                                                (numberOfWeeks ?? 0) >= 1 && (
                                                    <MenuItem value={'0'}>
                                                        <Typography variant={'body2'}>
                                                            {t('timetable.add.scheduling.current_week_format', {val: 1})}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            }
                                            {
                                                (numberOfWeeks ?? 0) >= 2 && (
                                                    <MenuItem value={'1'}>
                                                        <Typography variant={'body2'}>
                                                            {t('timetable.add.scheduling.current_week_format', {val: 2})}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            }
                                            {
                                                (numberOfWeeks ?? 0) >= 3 && (
                                                    <MenuItem value={'2'}>
                                                        <Typography variant={'body2'}>
                                                            {t('timetable.add.scheduling.current_week_format', {val: 3})}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            }
                                            {
                                                (numberOfWeeks ?? 0) >= 4 && (
                                                    <MenuItem value={'3'}>
                                                        <Typography variant={'body2'}>
                                                            {t('timetable.add.scheduling.current_week_format', {val: 4})}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </Stack>
                            </>
                        )
                    }
                    {
                        scheduling === 'shift' && (
                            <>
                                <Stack direction="row" alignItems={'center'} mt={2}>
                                    <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                                        {t('timetable.add.scheduling.number_of_days')}
                                    </Typography>
                                    <FormControl size="small">
                                        <Select
                                            labelId="number-of-days-select-small"
                                            id="number-of-days-select-small"
                                            value={adjustedNumberOfDays.toString()}
                                            onChange={(event: SelectChangeEvent) => handleOnNumberOfDaysChange(event)}
                                        >
                                            {
                                                Array(20 - (2 - 1)).fill(0).map((_, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={(index + 2).toString()}
                                                            value={(index + 2).toString()}
                                                        >
                                                            <Typography variant={'body2'}>
                                                                {t('timetable.add.scheduling.number_of_days_format', {val: (index + 2)})}
                                                            </Typography>
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} mt={2}>
                                    <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                                        {t('timetable.add.scheduling.start_day')}
                                    </Typography>
                                    <Button
                                        variant={'outlined'}
                                        color={'inherit'}
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            setDateAnchorEl(event.currentTarget);
                                        }}
                                        sx={{py: '8.5px'}}
                                    >
                                        <Typography variant={'body2'}>
                                            {
                                                shiftStartDay ? (shiftStartDay.format('DD MMM YYYY')) : (t('choose'))
                                            }
                                        </Typography>
                                    </Button>
                                </Stack>
                                <Stack direction={'row'} mt={3}>
                                    <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                                        {t('timetable.add.scheduling.days_of_week')}
                                    </Typography>
                                    <Stack direction={'column'}>
                                        <FormGroup>
                                            {
                                                daysOfWeek.map((value) => (
                                                    <FormControlLabel
                                                        label={value.format('dddd')}
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                checked={shiftDaysOfWeek.has(value.isoWeekday())}
                                                                onChange={(event) => {
                                                                    const newSet = new Set(shiftDaysOfWeek)
                                                                    if (event.target.checked) {
                                                                        newSet.add(value.isoWeekday())
                                                                    } else {
                                                                        newSet.delete(value.isoWeekday())
                                                                    }
                                                                    setShiftDaysOfWeek(newSet)
                                                                }}
                                                            />
                                                        }
                                                    />
                                                ))
                                            }
                                        </FormGroup>
                                    </Stack>
                                </Stack>
                            </>
                        )
                    }

                    <Divider sx={{mt: 3}}/>

                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        mt={3}
                    >
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:timer-outline"
                            color={theme.palette.primary.main}
                            width={18}
                            height={18}
                            mb={0.25}
                        />
                        <Typography
                            fontWeight={600}
                            variant={'body2'}
                            ml={1.5}
                            noWrap={true}
                            color={theme.palette.text.primary}
                        >
                            {t('timetable.add.time_management.title')}
                        </Typography>
                    </Stack>


                    <Stack direction={fullScreen ? "column" : "row"} alignItems={fullScreen ? 'left' : 'center'} mt={3}>
                        <Typography noWrap={true} flex={1} variant={'body2'}>
                            {t('timetable.add.time_management.time_of_classes')}
                        </Typography>
                        <FormControl size="small" sx={{...(fullScreen) && {mt: 2}}}>
                            <Select
                                labelId="time-format-small"
                                id="time-format-small"
                                value={timeFormat}
                                onChange={(event: SelectChangeEvent) => handleOnTimeFormatChange(event)}
                            >
                                <MenuItem key={'hour'} value={'hour'}>
                                    <Typography variant={'body2'}>
                                        {t('timetable.add.time_management.recommended')}
                                    </Typography>
                                </MenuItem>
                                <MenuItem key={'period'} value={'period'}>
                                    <Typography variant={'body2'}>
                                        {t('timetable.add.time_management.period')}
                                    </Typography>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    {
                        TIME_FORMAT_SUBTITLES[timeFormat] && (
                            <Typography
                                variant={'body2'}
                                color={'text.secondary'}
                                mt={1}
                                textAlign={'right'}
                            >
                                {t(TIME_FORMAT_SUBTITLES[timeFormat])}
                            </Typography>
                        )
                    }
                    {
                        timeFormat === 'period' && (
                            <Stack direction="row" alignItems={'center'} mt={2}>
                                <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                                    {t('timetable.add.time_management.number_of_periods')}
                                </Typography>
                                <FormControl size="small">
                                    <Select
                                        labelId="number-of-weeks-select-small"
                                        id="number-of-weeks-select-small"
                                        value={numberOfPeriods?.toString()}
                                        onChange={(event: SelectChangeEvent) => handleOnNumberOfPeriodsChange(event)}
                                    >
                                        {
                                            Array(24 - (2 - 1)).fill(0).map((_, index) => {
                                                return (
                                                    <MenuItem
                                                        key={(index + 2).toString()}
                                                        value={(index + 2).toString()}
                                                    >
                                                        <Typography variant={'body2'}>
                                                            {t('timetable.add.time_management.number_of_periods_format', {val: (index + 2)})}
                                                        </Typography>
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                        )
                    }

                    <Divider sx={{mt: 3}}/>

                    <Stack direction={'row'} alignItems={'center'} mt={3}>
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:settings-outline-rounded"
                            color={theme.palette.primary.main}
                            width={18}
                            height={18}
                            mb={0.25}
                        />
                        <Typography fontWeight={600} variant={'body2'} ml={1.5} noWrap={true}
                                    color={theme.palette.text.primary}>
                            {t('timetable.add.advanced.title')}
                        </Typography>
                    </Stack>

                    <TextField
                        fullWidth
                        size={'medium'}
                        label={t("timetable.add.advanced.add_name")}
                        value={timetableName}
                        onChange={(event) => setTimetableName(event.target.value)}
                        sx={{mt: 3}}
                    />

                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={1.5}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <SaveButton
                        handleSaveClick={handleSaveClick}
                        isSaving={false}
                        text={t("save")}
                    />
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='inherit'
                        sx={{boxShadow: 'none'}}
                    >
                        {t("cancel")}
                    </Button>
                </Stack>
            </DialogActions>

            <Popover
                id={popoverDateId}
                open={dateOpen}
                anchorEl={dateAnchorEl}
                onClose={() => setDateAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={shiftStartDay}
                    onChange={(newDate: moment.Moment | null) => {
                        if (newDate) {
                            setShiftStartDay(newDate)
                        }
                        setDateAnchorEl(null)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Popover>

        </BootstrapDialog>
    )
}