import {useHomeworkManager} from "../../../hooks/useHomeworkManager";
import {useExamManager} from "../../../hooks/useExamManager";
import {useReminderManager} from "../../../hooks/useReminderManager";
import {useSubjectManager} from "../../../hooks/useSubjectManager";

export const useTaskManager = (selectedPlannerId: string) => {
    const {
        homework,
        toggleHomeworkCompletedOn,
        deleteHomework
    } = useHomeworkManager()

    const {
        exams,
        deleteExam
    } = useExamManager()

    const {
        reminders,
        toggleReminderCompletedOn,
        deleteReminder
    } = useReminderManager()

    const {
        subjects
    } = useSubjectManager()

    const homeworkByDate = homework
        .filter(it => it.planner?._id === selectedPlannerId)
        .reduce((map, homework) => {
            if (homework?.date) {
                map[homework.date] = map[homework.date] || [];
                map[homework.date].push(homework);
                return map;
            } else {
                return [];
            }
        }, Object.create(null));

    const examsByDate = exams
        .filter(it => it.planner?._id === selectedPlannerId)
        .reduce((map, exam) => {
            if (exam?.date) {
                map[exam.date] = map[exam.date] || [];
                map[exam.date].push(exam);
                return map;
            } else {
                return [];
            }
        }, Object.create(null));

    const remindersByDate = reminders
        .filter(it => it.planner?._id === selectedPlannerId)
        .reduce((map, reminder) => {
            if (reminder?.date) {
                map[reminder.date] = map[reminder.date] || [];
                map[reminder.date].push(reminder);
                return map;
            } else {
                return [];
            }
        }, Object.create(null));

    return {
        homeworkByPlanner: homework.filter(it => it.planner?._id === selectedPlannerId),
        examsByPlanner: exams.filter(it => it.planner?._id === selectedPlannerId),
        remindersByPlanner: reminders.filter(it => it.planner?._id === selectedPlannerId),
        subjectsByPlanner: subjects.filter(it => it.planner?._id === selectedPlannerId),
        homeworkByDate,
        examsByDate,
        remindersByDate,
        toggleHomeworkCompletedOn,
        deleteHomework,
        deleteExam,
        toggleReminderCompletedOn,
        deleteReminder
    }
}