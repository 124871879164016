// component
import SvgColor from '../../../common/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>;

const navConfig = [
    {
        title: "General",
        children: [
            {
                title: 'drawer.agenda',
                path: '/app/agenda',
                icon: icon('ic_agenda'),
            },
            {
                title: 'drawer.timetable',
                path: '/app/timetable',
                icon: icon('ic_timetable'),
            },
            {
                title: 'drawer.holidays',
                path: '/app/holidays',
                icon: icon('ic_holidays'),
            }
        ]
    }
];

export default navConfig;
