import {rgba} from 'polished';
import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

const Section = styled.section`
  background: url('/assets/landing/dashboard-pattern.webp') no-repeat center bottom;
  position: relative;
  z-index: 0;
  @media (max-width: 768px) {
    background-size: 720px;
  }
  @media (min-width: 768px) and (max-width: 1280px) {
    background-size: contain;
  }
  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }
`;

export const BannerContentWrapper = styled.div`
  @media (min-width: 1280px) {
    min-height: 100vh;
  }
`;

export const BannerContent = styled.div`
  padding-top: 210px;
  max-width: 870px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 1024px) {
    max-width: 660px;
    padding-top: 170px;
  }
  @media (max-width: 768px) {
    max-width: 550px;
    padding-top: 150px;
  }
  @media (max-width: 480px) {
    padding-top: 128px;
  }

  h2 {
    color: ${themeGet('colors.headingColor')};
    font-weight: 700;
    font-size: 54px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: -1px;
    @media (max-width: 1024px) {
      font-size: 40px;
    }
    @media (max-width: 768px) {
      font-size: 32px;
    }
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 2.11;
    color: ${themeGet('colors.textColor')};
    max-width: 600px;
    margin: 0 auto;

    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 1.6;
    }
  }
`;

export const Subscribe = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  max-width: 540px;
  margin: 28px auto 0;
  @media screen and (max-width: 1366px) {
    max-width: 80%;
  }
  @media only screen and (max-width: 768px) {
    max-width: 80%;
    margin: 30px auto 0;
  }
  @media only screen and (max-width: 600px) {
    max-width: initial;
    display: block;
  }
`;

export const Figure = styled.figure`
  margin: 48px 0 0;

  img {
    @media (min-width: 1280px) {
      max-width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media (min-width: 768px) {
    margin-top: 80px;
  }
`;

export default Section;
