import {Button, Popover, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import moment from "moment/moment";
import {isTwelveHourClock} from "../../../../utils/date";
import {useTranslation} from "react-i18next";

const BUTTON_HEIGHT = '36px'
const POPOVER_MIN_WIDTH = '224px'

interface Props {
    id: string | undefined
    open: boolean
    anchorEl: Element | null | undefined
    handleClose: () => void
    handleOnPickDateTime: () => void
    handleOnRemindAtSelect: (date: moment.Moment, time: moment.Moment) => void
}

const RemindDateButton = (props: {
    label: string,
    date: moment.Moment | null | undefined,
    icon: string,
    onClick: () => void
}) => {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Button
            color='inherit'
            sx={{width: '100%', height: BUTTON_HEIGHT}}
            onClick={props.onClick}
        >
            <Stack
                direction='row'
                alignItems='center'
                sx={{width: '100%', px: 1}}
            >
                <Iconify
                    // @ts-ignore
                    icon={props.icon}
                    color={theme.palette.text.primary}
                    width={18}
                    height={18}
                    sx={{flexShrink: 0}}
                />
                <Typography
                    variant='body2'
                    noWrap={true}
                    color={theme.palette.text.primary}
                    ml={1.5}
                    sx={{textTransform: 'none'}}
                >
                    {t(props.label)}
                </Typography>
                {
                    props.date && <Typography
                        variant='caption'
                        noWrap={true}
                        color={theme.palette.text.secondary}
                        ml={2}
                        sx={{flexGrow: 1, textAlign: 'right'}}
                    >
                        {
                            isTwelveHourClock() ? props.date.format("ddd, h:mm A") : props.date.format("ddd, h:mm")
                        }
                    </Typography>
                }
            </Stack>
        </Button>
    )
}

export default function RemindPopover(props: Props) {
    const theme = useTheme()

    const laterToday = moment().endOf("hour").add(1, "minute").add(2, "hour")
    const tomorrow = moment().add(1, "day").startOf("day").add(9, "hour")
    const nextWeek = moment().add(1, "week").startOf("day").add(9, "hour")

    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
        >
            <Stack direction='column' py={1} sx={{minWidth: '224px'}}>
                <RemindDateButton
                    label={"task_dialog.later_today"}
                    date={laterToday}
                    icon={"material-symbols:timer-outline"}
                    onClick={() => props.handleOnRemindAtSelect(laterToday, laterToday)}
                />

                <RemindDateButton
                    label={"task_dialog.tomorrow"}
                    date={tomorrow}
                    icon={"material-symbols:timer-outline"}
                    onClick={() => props.handleOnRemindAtSelect(tomorrow, tomorrow)}
                />

                <RemindDateButton
                    label={"task_dialog.next_week"}
                    date={nextWeek}
                    icon={"material-symbols:timer-outline"}
                    onClick={() => props.handleOnRemindAtSelect(nextWeek, nextWeek)}
                />

                <RemindDateButton
                    label={"task_dialog.pick_date_time"}
                    date={null}
                    icon={"material-symbols:calendar-month-outline-rounded"}
                    onClick={() => props.handleOnPickDateTime()}
                />
            </Stack>
        </Popover>
    )
}