import Realm from "realm";
import {Planner} from "./Planner";

export interface TimetableProperties {
    _id: string;
    owner_id: string;
    // planner?: Planner;
    title: string;
    color: number;
    start_date?: string;
    end_date?: string;
    created_on?: string;
    scheduling: number;
    time_format: number;
    number_of_periods: number;
    number_of_weeks: number;
    start_week_date: string;
    start_week: number;
    shift_number_of_days: number;
    shift_start_day: string;
    shift_days_of_week: Realm.List<number>;
}

export class Timetable extends Realm.Object implements TimetableProperties {
    _id!: string;
    owner_id!: string;
    planner?: Planner;
    title!: string;
    color!: number;
    start_date?: string;
    end_date?: string;
    created_on?: string;
    scheduling!: number;
    time_format!: number;
    // -----------------------------------------------------------------------------------------------------------------
    // Time format
    number_of_periods!: number;
    // -----------------------------------------------------------------------------------------------------------------
    // Weekly
    number_of_weeks!: number;
    start_week_date!: string;
    start_week!: number;
    // week_data_list
    // -----------------------------------------------------------------------------------------------------------------
    // Shift
    shift_number_of_days!: number;
    shift_start_day!: string;
    shift_days_of_week!: Realm.List<number>;

    static schema: Realm.ObjectSchema = {
        name: 'timetable',
        primaryKey: '_id',
        properties: {
            _id: 'string',
            owner_id: 'string',
            planner: 'planner?',
            title: 'string',
            color: 'int',
            start_date: 'string?',
            end_date: 'string?',
            created_on: 'string?',
            scheduling: 'int',
            time_format: 'int',
            number_of_periods: 'int',
            number_of_weeks: 'int',
            start_week_date: 'string',
            start_week: 'int',
            shift_number_of_days: 'int',
            shift_start_day: 'string',
            shift_days_of_week: 'int[]',
        }
    }
}