import PropTypes from 'prop-types';
import {NavLink as RouterLink} from 'react-router-dom';
// @mui
import {Box, List, ListItemText, Typography} from '@mui/material';
//
import {StyledNavItem, StyledNavItemIcon} from './styles';
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {rgba} from 'polished';

// ----------------------------------------------------------------------

NavSection.propTypes = {
    data: PropTypes.array,
};

export default function NavSection({data = [], ...other}) {
    const theme = useTheme()

    return (
        <Box {...other}>
            {
                data.map((section) => {
                    return (
                        <div key={section.title}>
                            <Typography
                                variant={"caption"}
                                fontWeight={700}
                                sx={{
                                    color: theme.palette.text.secondary,
                                    textTransform: "uppercase",
                                    px: 4
                                }}
                            >
                                {section.title}
                            </Typography>
                            <List disablePadding sx={{px: 2, py: 1}}>
                                {section.children.map((item) => (
                                    <NavItem key={item.title} item={item}/>
                                ))}
                            </List>
                        </div>
                    )
                })
            }
        </Box>
    );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
    item: PropTypes.object,
};

function NavItem({item}) {
    const theme = useTheme()
    const {title, path, icon, info} = item;
    const {t} = useTranslation()

    return (
        <StyledNavItem
            component={RouterLink}
            to={path}
            sx={{
                mb: 0.5,
                ":hover": {
                    color: theme.palette.text.secondary
                },
                '&.active': {
                    color: theme.palette.primary.main,
                    backgroundColor: rgba(theme.palette.primary.main, 0.1),
                    fontWeight: 600,
                }
            }}
        >
            <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

            <ListItemText disableTypography primary={t(title)}/>

            {info && info}
        </StyledNavItem>
    );
}
