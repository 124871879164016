import styled, {createGlobalStyle, keyframes} from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

const Fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const FadeUp = keyframes`
  0% {
    opacity: 0;
    transform: scale(.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const shake = keyframes`
  16% {
    transform: skew(-14deg)
  }
  33% {
    transform: skew(12deg)
  }
  49% {
    transform: skew(-8deg)
  }
  66% {
    transform: skew(6deg)
  }
  83% {
    transform: skew(-4deg)
  }
`;

export const GlobalStyle = createGlobalStyle`
  :root {
    --shakeAnim: ${shake} 600ms ease-in-out;
  }

  .custom-shape-divider-top-1680342428 {
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1680342428 svg {
    position: relative;
    display: block;
    width: calc(133% + 1.3px);
    height: 32px;
  }

  .custom-shape-divider-top-1680342428 .shape-fill {
    fill: #FFFFFF;
  }

  .custom-shape-divider-bottom-1680343255 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1680343255 svg {
    position: relative;
    display: block;
    width: calc(133% + 1.3px);
    height: 32px;
  }

  .custom-shape-divider-bottom-1680343255 .shape-fill {
    fill: #FFFFFF;
  }

  body {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    height: auto;
  }

  .App {
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: 'Inter', sans-serif;
  }

  section {
    position: relative;
  }

  .drawer-content-wrapper, .rc-drawer-content-wrapper {
    @media (max-width: 767px) {
      width: 300px !important;
    }

    .drawer-content, .rc-drawer-content {
      padding: 60px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 767px) {
        padding: 50px 40px 30px 40px;
      }

      .mobile-menu-drawer-bottom {
        flex-direction: column;
        align-items: center;

        .navbar_button {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
        }

        .navbar_button:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .mobile_menu {
        margin-bottom: 40px;
        flex: auto;
        @media (max-width: 767px) {
          margin-bottom: 30px;
        }

        li {
          margin-bottom: 35px;
          @media (max-width: 767px) {
            margin-bottom: 25px;
          }

          a {
            font-size: 20px;
            font-weight: 700;
            color: #343d48;
            position: relative;
            transition: 0.15s ease-in-out;
            @media (max-width: 767px) {
              font-size: 18px;
            }

            &:hover {
              color: ${themeGet('colors.primary')};
            }

            &:before {
              content: '';
              width: 7px;
              height: 7px;
              background: ${themeGet('colors.primary')};
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: -15px;
              transform: translateY(-50%);
              opacity: 0;
            }
          }

          &.is-current {
            a {
              color: ${themeGet('colors.primary')};
            }
          }
        }
      }

      .navbar_drawer_button button {
        width: 100%;
      }
    }

    .reusecore-drawer__close {
      width: 34px;
      height: 34px;
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @media (max-width: 767px) {
        top: 15px;
        right: 15px;
      }

      &:before {
        content: '\f10b';
        font-family: Flaticon;
        font-size: 26px;
        color: ${themeGet('colors.primary')};
        transform: rotate(45deg);
        display: block;
      }
    }
  }

  .video-modal {
    background: rgba(0, 0, 0, 0.6) !important;
    border: 0 !important;
    animation: ${Fade} .5s ease-out forwards !important;

    .innerRndComponent {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      animation: ${FadeUp} .7s ease-out forwards !important;
    }
  }

  .reuseModalOverlay,
  .modalCloseBtn {

    animation: ${Fade} .5s ease-out forwards !important;
  }

  .video-modal {
    background: transparent !important;
    border: 0 !important;

    .innerRndComponent {
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
  }

  .reuseModalOverlay,
  .reuseModalParentWrapper {
    z-index: 99999 !important;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 20px;
    @media (max-width: 991px) {
      justify-content: center;
    }
  }
`;

export const ContentWrapper = styled.div`
  overflow: hidden;

  .scrollspy__menu {
    display: flex;
    height: 40px;
  }

  .scrollspy__menu li {
    height: 40px;
    padding: 0 !important;
  }

  .scrollspy__menu li a {
    padding: 0 !important;
  }

  .scrollspy__menu li a button {
    height: 40px;
    padding: 0 16px;
    font-size: 15px;
  }

  .web_app_creative_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 30px 0;
    padding-top: 15px;
    font-size: 16px;
    @media (min-width: 991px) and (max-width: 1023px) {
      font-size: 13px;
    }

    .mainMenuWrapper {
      flex: 1 1 100%;

      @media (max-width: 991px) {
        flex: 0 0 auto;
        margin-left: auto;
      }
    }

    .main-logo {
      margin-left: -9px;

      > img {
        @media (min-width: 991px) and (max-width: 1023px) {
          max-width: 120px;
        }
      }
    }

    .main_menu {
      margin-left: 75px;
      @media (min-width: 991px) and (max-width: 1023px) {
        margin-left: 15px;
        margin-right: 20px;
      }

      li {
        display: inline-flex;
        padding-left: 10px;
        padding-right: 10px;
        @media (max-width: 1024px) {
          padding-left: 8px;
          padding-right: 8px;
        }
        @media (min-width: 991px) and (max-width: 1023px) {
          padding-left: 5px;
          padding-right: 5px;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.is-current {
          a {
            color: ${themeGet('colors.primary')};
          }
        }

        a {
          font-weight: 700;
          color: ${themeGet('colors.black')};
          padding: 5px;
          @media (min-width: 991px) and (max-width: 1023px) {
            padding: 3px;
          }
          transition: 0.15s ease-in-out;
        }
      }

      @media (max-width: 990px) {
        display: none;
      }
    }

    .navbar_button_one {
      margin-left: auto;
      display: flex;
      color: ${themeGet('colors.black')};
      align-items: center;
      transition: 0.15s ease-in-out;

      svg {
        transition: 0.15s ease-in-out;
      }

      > img {
        margin-right: 9px;
        @media (min-width: 991px) and (max-width: 1023px) {
          display: none;
        }
      }

      @media (max-width: 990px) {
        display: none;
      }
    }

    .navbar_button_two {
      margin-left: 16px;

      @media (max-width: 990px) {
        display: none;
      }
    }

    .reusecore-drawer__handler {
      @media (min-width: 991px) {
        display: none !important;
      }

      .hamburgMenu__bar {
        > span {
          background-color: ${themeGet('colors.headingColor')};
        }
      }
    }
  }

  .sticky-nav-active {
    .web_app_creative_navbar {
      background-color: ${themeGet('colors.white')};
      box-shadow: 0px 3px 8px 0px rgba(43, 83, 135, 0.08);
      padding: 15px 0;

      .main_menu li a {
        color: ${themeGet('colors.headingColor')};
      }

      .main_menu li.is-current a {
        color: #2065D1;
      }

      .reusecore-drawer__handler {
        .hamburgMenu__bar {
          > span {
            background-color: ${themeGet('colors.headingColor')};
          }
        }
      }

      .navbar_button_one button {
        color: ${themeGet('colors.headingColor')};
      }

      .navbar_button_two button {
      }
    }
  }
`;

export const CombinedSection = styled.section`
  background: #373754 url('/assets/landing/pattern3.png') no-repeat center;
  position: relative;
  z-index: 0;
  @media only screen and (max-width: 1024px) {
    background-size: contain;
    background-repeat: repeat;
  }
`;

export const CornerPattern = styled.div`
  background: url('/assets/landing/pattern5.png') left top no-repeat, url('/assets/landing/pattern6.png') right top no-repeat, url(url('/assets/landing/pattern7.png')) right bottom no-repeat;
  position: absolute;
  left: 0;
  top: -30px;
  bottom: -30px;
  right: 0;
  z-index: -1;
  @media only screen and (max-width: 1024px) {
    background-size: 60px, 60px, 70px;
    top: -20px;
    bottom: -20px;
  }
  @media only screen and (max-width: 480px) {
    background-size: 50px, 50px, 70px;
    top: -20px;
    bottom: -20px;
  }
`;
