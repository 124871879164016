import {Button, Popover, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import Iconify from "../../../../common/components/iconify";
import {useTranslation} from "react-i18next";

const BUTTON_HEIGHT = '40px'
const POPOVER_MIN_WIDTH = '224px'

interface Props {
    id: string | undefined
    open: boolean
    anchorEl: Element | null | undefined
    handleClose: () => void
    handleOnDeleteSingle: () => void
    handleOnDeleteAll: () => void
}

export default function LessonDeletePopover(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Stack direction='column' padding={1} sx={{minWidth: POPOVER_MIN_WIDTH}}>
                <Button
                    onClick={props.handleOnDeleteSingle}
                    color='inherit'
                    sx={{width: '100%', height: BUTTON_HEIGHT}}
                >
                    <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:delete"
                            color={theme.palette.text.primary}
                            width={18}
                            height={18}
                        />
                        <Typography
                            variant='body2'
                            fontWeight={600}
                            color={theme.palette.text.primary}
                            sx={{marginLeft: '12px'}}
                        >
                            {t('timetable.lesson.delete_popover.only_class')}
                        </Typography>
                    </Stack>
                </Button>
                <Button
                    onClick={props.handleOnDeleteAll}
                    color='inherit'
                    sx={{width: '100%', height: BUTTON_HEIGHT}}
                >
                    <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:delete"
                            color={theme.palette.error.main}
                            width={18}
                            height={18}
                        />
                        <Typography
                            variant='body2'
                            fontWeight={600}
                            color={theme.palette.error.main}
                            sx={{marginLeft: '12px'}}
                        >
                            {t('timetable.lesson.delete_popover.all_classes')}
                        </Typography>
                    </Stack>
                </Button>
            </Stack>
        </Popover>
    )
}