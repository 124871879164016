import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import RcDrawer from 'rc-drawer';
import motionProps from './motion';
import 'rc-drawer/assets/index.css';
import Iconify from "../iconify";
import {IconButton, Stack} from "@mui/material";
import {useTheme} from "@mui/material/styles";

const Drawer = ({className, children, closeButton, closeButtonStyle, drawerHandler, toggleHandler, open, ...props}) => {
    const theme = useTheme()
    // Add all classs to an array
    const addAllClasses = ['reusecore__drawer'];
    // const addAllClasses = ['rc-drawer-content-wrapper'];

    // className prop checking
    if (className) {
        addAllClasses.push(className);
    }

    return (
        <Fragment>
            <RcDrawer
                open={open}
                onClose={toggleHandler}
                className={addAllClasses.join(' ')}
                {...props} // Motion
                {...motionProps}
                style={{display: "flex", padding: 0, zIndex: 1}}
            >
                <Stack direction={"row-reverse"} sx={{pt: "12px", px: "12px", zIndex: 1}}>
                    <IconButton onClick={toggleHandler}>
                        <Iconify
                            icon={"majesticons:close"}
                            color={theme.palette.text.secondary}
                            width={32}
                            height={32}
                        />
                    </IconButton>
                </Stack>
                <Stack direction={"column"} style={{flex: 1, padding: "24px 32px", zIndex: 1}}>
                    {children}
                </Stack>
            </RcDrawer>
            <div className="reusecore-drawer__handler" style={{display: 'inline-block'}} onClick={toggleHandler}
                 role="button" tabIndex="0">
                {drawerHandler}
            </div>
        </Fragment>
    );
};

Drawer.propTypes = {
    /** ClassName of the Drawer */
    className: PropTypes.string,

    /** Used to render icon, button, text or any elements inside the closeButton prop. */
    closeButton: PropTypes.element,

    /** Set drawer width. Default value is 300px. */
    width: PropTypes.string,

    /** Set drawer position left || right || top || bottom. */
    placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),

    /** drawerHandler could be button, icon, string or any component */
    // drawerHandler: PropTypes.element.isRequired
    drawerHandler: PropTypes.element,
};

Drawer.defaultProps = {
    width: '300px',
    handler: false,
    level: null,
};

export default Drawer;
