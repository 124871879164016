import {styled, useTheme} from "@mui/material/styles";
import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../../common/components/iconify";
import React from "react";
import moment from "moment/moment";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import {MONGO_DATE_FORMAT} from "../../../utils/date";
import {useTranslation} from "react-i18next";

const StyledRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column'
}));

const StyledRow = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row'
}));

const LeftArrowButton = styled(Button)(({theme}) => ({
    padding: 0,
    height: '32px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: '0.5px',
    minWidth: '36px'
}));

const RightArrowButton = styled(Button)(({theme}) => ({
    padding: 0,
    height: '32px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: '0.5px',
    minWidth: '36px'
}));

const TabButton = styled(Button)(({theme}) => ({
    flex: 1,
    flexDirection: 'column',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '0px',
    paddingRight: '0px',
    minWidth: '0px'
}));

interface Props {
    weekOffset: number
    selectedDay: moment.Moment | null
    goToPreviousWeek: () => void
    goToNextWeek: () => void
    goToToday: () => void
    setSelectedDay: (day: moment.Moment) => void
    handleOnShowDaySelector: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

interface DayButtonProps {
    dayOfWeek: moment.Moment
    selected: boolean
    onClick: (day: moment.Moment) => void
}

function DayButton(props: DayButtonProps) {
    const theme = useTheme()

    const today = moment().startOf("day")
    const isToday = today.isSame(props.dayOfWeek)
    const isBeforeToday = props.dayOfWeek.isBefore(today)

    return (
        <TabButton
            color={isBeforeToday ? "inherit" : (isToday ? "primary" : "inherit")}
            disabled={isBeforeToday}
            onClick={() => {
                if (!isBeforeToday) {
                    props.onClick(props.dayOfWeek)
                }
            }}
            sx={{
                borderBottomColor: theme.palette.primary.main,
                borderBottomLeftRadius: '0px',
                borderBottomRightRadius: '0px',
                paddingTop: '6px',
                paddingBottom: '6px',
                "& .MuiTouchRipple-root": {
                    borderBottom: props.selected ? `2px solid ${theme.palette.primary.main}` : "0px"
                }
            }}
        >
            <Typography
                variant="caption"
                color={theme.palette.text.secondary}
            >
                {props.dayOfWeek.format('ddd')}
            </Typography>
            <Typography
                variant="body1"
                fontSize="1.05rem"
                fontWeight={
                    (props.selected || isToday) ? theme.typography.h6.fontWeight : theme.typography.body1.fontWeight
                }
            >
                {props.dayOfWeek.format('D')}
            </Typography>
        </TabButton>
    )
}

export default function TaskHeader(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    const startOfWeek = moment().add(props.weekOffset, 'weeks')
        .startOf('week')
        .startOf('day')

    const daysOfWeek = [0, 1, 2, 3, 4, 5, 6].map(index => (
        moment().add(props.weekOffset, 'weeks')
            .startOf('week')
            .startOf('day')
            .add(index, 'days')
    ))

    return (
        <StyledRoot>
            <Stack direction='row' alignItems='center'>
                <Button onClick={props.handleOnShowDaySelector} color="inherit" sx={{height: '36px', mr: 1}}>
                    <Typography
                        noWrap={true}
                        variant="h5"
                    >
                        {startOfWeek.format("MMMM yyyy")}
                    </Typography>
                </Button>
                <div style={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'row'
                }}>
                    <div style={{flex: 1}}/>
                    <LeftArrowButton
                        color="inherit"
                        variant="outlined"
                        onClick={props.goToPreviousWeek}
                    >
                        {
                            // @ts-ignore
                            <Iconify icon="material-symbols:chevron-left"
                                     color={theme.palette.text.secondary}
                                     width={18}
                                     height={18}
                            />
                        }
                    </LeftArrowButton>
                    <RightArrowButton
                        color="inherit"
                        variant="outlined"
                        onClick={props.goToNextWeek}
                        sx={{mr: 1}}
                    >
                        {
                            // @ts-ignore
                            <Iconify icon="material-symbols:chevron-right"
                                     color={theme.palette.text.secondary}
                                     width={18}
                                     height={18}
                            />
                        }
                    </RightArrowButton>
                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={props.goToToday}
                        sx={{height: '32px'}}
                    >
                        <Typography variant='body2' color={theme.palette.text.secondary}>
                            {t("today")}
                        </Typography>
                    </Button>
                </div>
            </Stack>
            <Box sx={{mt: 2, display: 'flex'}}>
                {
                    daysOfWeek.map(dayOfWeek => (
                        <DayButton
                            key={dayOfWeek.format(MONGO_DATE_FORMAT)}
                            onClick={props.setSelectedDay}
                            selected={dayOfWeek.isSame(props.selectedDay)}
                            dayOfWeek={dayOfWeek}/>
                    ))
                }
            </Box>
            <Divider/>
        </StyledRoot>
    )
}