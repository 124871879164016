import moment, {Moment} from "moment/moment";
import {TimeFormat, TimetableDecorator} from "../../../../model/TimetableDecorator";
import {LessonWithOccurrenceIdAndDate} from "../../../../model/LessonWithOccurrenceIdAndDate";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {COLOR_DEFAULT, colorToHexColor} from "../../../../utils/color";
import {Button, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Iconify from "../../../../common/components/iconify";
import {HEIGHT_HOUR_BLOCK, HOUR_COUNT} from "../../TimetableLayout";
import {computeSpanAndLength} from "../../../../utils/timetable";

const LESSON_BUTTON_MARGIN_RIGHT = 2

export default function DayColumn(
    props: {
        dayOfWeek: moment.Moment
        timetable: TimetableDecorator | undefined,
        lessons: LessonWithOccurrenceIdAndDate[]
        handleOnLessonPopupOpen: (
            event: React.MouseEvent<HTMLButtonElement>,
            lesson: LessonWithOccurrenceIdAndDate
        ) => void
    }
) {
    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [
        currentTime,
        setCurrentTime
    ] = useState(moment());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment());
        }, 10000);
        return () => clearInterval(interval);
    });

    const isFirstDayOfWeek =
        props.dayOfWeek.weekday() === props.dayOfWeek.clone().startOf('week').weekday()

    const blockCount =
        props.timetable?.getTimeFormat() === TimeFormat.PERIOD ? props.timetable.getNumberOfPeriods() : HOUR_COUNT

    const lessons = computeSpanAndLength(props.lessons)

    const buttons = lessons.map((lesson, index) => {
        const top = (lesson.getTopInUnits() || 0) * HEIGHT_HOUR_BLOCK + 'px'
        const height = ((lesson.getHeightInUnits() || 0) * HEIGHT_HOUR_BLOCK - 2) + 'px'
        const left = (100 / lesson.getNumberOfSpans() * lesson.getStartSpan()) + '%'
        const width = (100 / lesson.getNumberOfSpans() * (lesson.getLengthInSpans())) + '%'

        return (
            <Button
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                    props.handleOnLessonPopupOpen(event, lesson.getLesson())
                }
                key={index}
                color={'inherit'}
                sx={{
                    display: 'flex',
                    position: 'absolute',
                    minWidth: 0,
                    top: top,
                    left: left,
                    width: `calc(${width} - ${LESSON_BUTTON_MARGIN_RIGHT}px)`,
                    height: height,
                    padding: 0,
                    px: 0.5,
                    borderRadius: '8px',
                    textAlign: 'left',
                    backgroundColor: getColor(lesson.getLesson()),
                    "&:hover": {
                        backgroundColor: getColor(lesson.getLesson())
                    }
                }}
            >
                <Typography
                    variant="caption"
                    fontWeight={600}
                    lineHeight={1.15}
                    pt={0.5}
                    color={"white"}
                    sx={{width: '100%', height: '100%', wordWrap: 'break-word', overflow: 'hidden'}}
                >
                    {lesson.getLesson().getTitle() || ''}
                </Typography>
            </Button>
        )
    })

    // -----------------------------------------------------------------------------------------------------------------
    const theme = useTheme()
    const borderColor = theme.palette.divider

    return (
        <Stack
            direction="column"
            sx={{
                position: 'relative',
                flex: 1,
                borderLeft: isFirstDayOfWeek ? 'none' : `1px solid ${borderColor}`
            }}
        >
            {
                Array.from(Array(blockCount).keys()).map((value, index) => {
                    return (
                        <Box key={index}
                             sx={{
                                 height: HEIGHT_HOUR_BLOCK + 'px',
                                 ...(index !== 0) && {
                                     borderTop: `1px solid ${borderColor}`
                                 }
                             }}
                        >
                        </Box>
                    )
                })
            }
            {buttons}
            {
                props.timetable?.getTimeFormat() === TimeFormat.HOUR &&
                props.dayOfWeek.isSame(currentTime, 'day') &&
                <CurrentDayIndicator currentTime={currentTime}/>
            }
        </Stack>
    )
}

const CurrentDayIndicator = (
    props: {
        currentTime: Moment,
    }
) => {
    const theme = useTheme()

    const getCurrentTimeTop = () => {
        return (props.currentTime.minutes() + props.currentTime.hours() * 60) / 60
    }

    return (
        <Box
            sx={{
                color: theme.palette.primary.main,
                position: 'absolute',
                top: getCurrentTimeTop() * HEIGHT_HOUR_BLOCK + 'px',
                left: 0,
                right: 0,
                height: '2px',
                backgroundColor: theme.palette.primary.main
            }}
        >
            <Iconify
                // @ts-ignore
                icon="mdi:circle"
                color={theme.palette.primary.main}
                width={14}
                height={14}
                sx={{position: 'absolute', top: '-6px', left: '-8px'}}
            />
        </Box>
    )
}


const getColor = (lesson: LessonWithOccurrenceIdAndDate) => {
    return lesson.getColor() ? colorToHexColor(lesson.getColor() || 0) : COLOR_DEFAULT
}
