import {useTranslation} from "react-i18next";
import {Container, Link, Stack, Typography} from "@mui/material";
import React from "react";
import Iconify from "../../../common/components/iconify";
import {LinkIndex} from "../SupportLayout";
import {useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {DownloadPopover} from "../../../containers/LandingPage/Banner";

export default function CloudSyncLayout() {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <Container
            maxWidth={"md"}
            style={{flex: 1, display: 'flex', flexDirection: 'column'}}
        >
            <Typography variant={"h3"}>
                {t("support.cloud_sync.drawer")}
            </Typography>

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.intro.text1")}
            </Typography>

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.intro.text2")}
            </Typography>

            <Link href={"https://schoolplanner.io/app"} underline="none" mt={0.5}>
                https://schoolplanner.io/app
            </Link>

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.intro.text3")}
            </Typography>

            <LinkIndex href={"#enable-sync"} underline="none" mt={2}>
                {
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:arrow-circle-right-rounded"}
                        width={24}
                        color={theme.palette.primary.main}
                    />
                }
                {t("support.cloud_sync.contents.enable_sync.title")}
            </LinkIndex>

            <LinkIndex href={"#upload-sync"} underline="none" mt={0.5}>
                {
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:arrow-circle-right-rounded"}
                        width={24}
                        color={theme.palette.primary.main}
                    />
                }
                {t("support.cloud_sync.contents.upload_sync.title")}
            </LinkIndex>

            <LinkIndex href={"#sync-data"} underline="none" mt={0.5}>
                {
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:arrow-circle-right-rounded"}
                        width={24}
                        color={theme.palette.primary.main}
                    />
                }
                {t("support.cloud_sync.contents.sync_data.title")}
            </LinkIndex>

            <Divider sx={{mt: 2}}/>

            <Typography variant={'h4'} id="enable-sync" mt={3}>
                {t("support.cloud_sync.contents.enable_sync.title")}
            </Typography>

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.contents.enable_sync.text1")}
            </Typography>

            <Typography variant={"body1"} mt={2} mb={2}>
                {t("support.cloud_sync.contents.enable_sync.text2")}
            </Typography>

            {/*<img*/}
            {/*    src="/assets/support/payment-issues-screenshot-1.webp"*/}
            {/*    className="img-fluid rounded"*/}
            {/*/>*/}

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.contents.enable_sync.text3")}
            </Typography>

            <Divider sx={{mt: 2}}/>

            <Typography variant={'h4'} id="upload-sync" mt={3}>
                {t("support.cloud_sync.contents.upload_sync.title")}
            </Typography>

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.contents.upload_sync.text1")}
            </Typography>

            {/*<img*/}
            {/*    src="/assets/support/payment-issues-screenshot-1.webp"*/}
            {/*    className="img-fluid rounded"*/}
            {/*/>*/}

            <Divider sx={{mt: 2}}/>

            <Typography variant={'h4'} id="sync-data" mt={3}>
                {t("support.cloud_sync.contents.sync_data.title")}
            </Typography>

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.contents.sync_data.text1")}
            </Typography>

            <Link href={"https://schoolplanner.io/app"} underline="none" mt={0.5}>
                https://schoolplanner.io/app
            </Link>

            <Typography variant={"body1"} mt={2}>
                {t("support.cloud_sync.contents.sync_data.text2")}
            </Typography>

            <Stack direction={'row'} mt={1}>
                <DownloadPopover isDesktop={false}/>
            </Stack>

            <Box sx={{minHeight: '48px'}} />

        </Container>
    )
}