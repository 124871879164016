import {useCookies} from "react-cookie";
import {SELECTED_PLANNER_ID_NAME} from "../common/Constants";

export const useSelectedPlannerId = () => {
    // Use react-cookies to access our cookies
    const [cookies, setCookie, removeCookie] = useCookies([SELECTED_PLANNER_ID_NAME]);

    // This function allows to save any string in our cookies, under the key "authToken"
    // @ts-ignore
    const setSelectedPlannerId = (selectedPlannerId) => {
        removeCookie(SELECTED_PLANNER_ID_NAME)
        setCookie(SELECTED_PLANNER_ID_NAME, selectedPlannerId, {maxAge: 31536000});
    }

    // This function removes the key "authToken" from our cookies. Useful to logout
    const removeSelectedPlannerId = () => removeCookie(SELECTED_PLANNER_ID_NAME);

    return [cookies[SELECTED_PLANNER_ID_NAME], setSelectedPlannerId, removeSelectedPlannerId];
};