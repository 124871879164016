import {useTheme} from "@mui/material/styles";
import {Button, IconButton, Stack, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import React, {Fragment} from "react";
import {ROW_HEIGHT} from "../GroupRow";
import Iconify from "../../../../common/components/iconify";
import SubjectChip from "../SubjectButton";
import TaskRowPopover from "../TaskRowPopover";
import {Subject} from "../../../../models/Subject";

interface Props {
    _id: string,
    title: string
    subject: Subject | undefined | null
    completedOn?: string | undefined | null
    hasCompletedOn: boolean | undefined | null
    handleToggleCompletedOn?: () => void | undefined | null
    handleOnUpdate: () => void
    handleOnDelete: () => void
    handleOnSubject?: () => void | undefined | null
}

export default function TaskRow(props: Props) {
    const theme = useTheme();

    const [subjectAnchorEl, setSubjectAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const subjectOpen = Boolean(subjectAnchorEl);
    const popoverSubjectId = subjectOpen ? 'TaskRowPopover' : undefined;

    return (
        <>
            <Button
                onClick={(event) => {
                    event.stopPropagation()
                    props.handleOnUpdate()
                }}
                color='inherit'
                sx={{height: (ROW_HEIGHT - 1) + 'px', paddingLeft: '2px', py: '2px'}}
            >
                <Stack
                    direction='row'
                    sx={{
                        flex: 1,
                        minWidth: 0,
                        height: '100%'
                    }}
                >
                    {props.hasCompletedOn ?
                        <IconButton
                            onClick={(event) => {
                                event.stopPropagation()
                                if (props.handleToggleCompletedOn) {
                                    props.handleToggleCompletedOn()
                                }
                            }}
                            sx={{
                                width: '40px',
                                height: '40px',
                                padding: 0
                            }}
                        >
                            <Iconify
                                // @ts-ignore
                                icon={
                                    props.completedOn ?
                                        "material-symbols:check-circle-rounded" :
                                        "material-symbols:circle-outline"
                                }
                                color={props.completedOn ? theme.palette.primary.main : theme.palette.text.secondary}
                                width={24}
                                height={24}
                            />
                        </IconButton>
                        :
                        <IconButton
                            sx={{width: '40px', height: '40px', color: 'text.primary', padding: 0}}
                        >
                            <Iconify
                                // @ts-ignore
                                icon={"material-symbols:calendar-month-outline-rounded"}
                                color={theme.palette.text.secondary}
                                width={20}
                                height={20}
                            />
                        </IconButton>
                    }
                    <Stack
                        direction='column'
                        sx={{
                            flex: 1,
                            minWidth: 0,
                            marginTop: '8px',
                            marginLeft: '8px'
                        }}
                    >
                        <Typography
                            variant='body1'
                            color={theme.palette.text.primary}
                            noWrap={true}
                            textAlign={"start"}
                            sx={{
                                marginBottom: "2px",
                                textOverflow: "ellipsis"
                            }}
                        >
                            {props.title}
                        </Typography>
                        {
                            props.subject && <SubjectChip
                                subject={props.subject}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    if (props.handleOnSubject) {
                                        props.handleOnSubject()
                                    }
                                }}
                            />
                        }
                    </Stack>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation()
                            setSubjectAnchorEl(event.currentTarget);
                        }}
                        sx={{
                            width: '38px',
                            height: '38px',
                            color: theme.palette.text.secondary
                        }}
                    >
                        <Iconify
                            // @ts-ignore
                            icon="carbon:overflow-menu-horizontal"
                            width={24}
                            height={24}
                        />
                    </IconButton>
                </Stack>
            </Button>

            <Divider/>

            <TaskRowPopover
                id={popoverSubjectId}
                open={subjectOpen}
                anchorEl={subjectAnchorEl}
                handleClose={() => setSubjectAnchorEl(null)}
                handleOnUpdate={() => {
                    setSubjectAnchorEl(null)
                    props.handleOnUpdate()
                }}
                handleOnDelete={() => {
                    setSubjectAnchorEl(null)
                    props.handleOnDelete()
                }}
            />
        </>
    )
}