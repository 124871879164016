import {useTheme} from "@mui/material/styles";
import * as React from "react";
import {Button, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import {CloseButton} from "../TaskDialog";
import {useTranslation} from "react-i18next";
import {isTwelveHourClock} from "../../../../utils/date";
import {EventRemindAtProps} from "../../../../models/EventRemindAt";

interface Props {
    remindAt: EventRemindAtProps | undefined
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
    clearRemindAt: () => void
}

export default function RemindAtButton(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    const dateAsMoment = props.remindAt?.date
    const timeAsMoment = props.remindAt?.time
    const canClear = dateAsMoment && timeAsMoment
    const body = dateAsMoment && timeAsMoment ?
        (isTwelveHourClock() ?
                dateAsMoment.format("ddd, D MMMM") + " • " + timeAsMoment.format("h:mm A") :
                dateAsMoment.format("ddd, D MMMM") + " • " + timeAsMoment.format("HH:mm")
        ) : t("task_dialog.remind_me")

    return (
        <Stack direction='row' alignItems='center'>
            <Button
                color='inherit'
                sx={{width: '100%', height: '48px'}}
                onClick={props.onClick}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{width: '100%'}}
                >
                    <Iconify
                        // @ts-ignore
                        icon="mdi:bell-outline"
                        color={theme.palette.text.secondary}
                        width={18}
                        height={18}
                        sx={{flexShrink: 0}}
                    />
                    <Typography
                        variant='body2'
                        noWrap={true}
                        color={canClear ? theme.palette.text.primary : theme.palette.text.secondary}
                        ml={2}
                    >
                        {body}
                    </Typography>
                </Stack>
            </Button>
            {
                canClear && <CloseButton onClick={props.clearRemindAt}/>
            }
        </Stack>
    )
}