import {Button, Popover, Stack, Typography} from "@mui/material";
import Iconify from "../iconify";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {COLOR_DEFAULT, colorToHexColor} from "../../../utils/color";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import SubjectOptionsPopover from "./SubjectOptionsPopover";
import {Subject} from "../../../models/Subject";

const BUTTON_HEIGHT = '40px'
const POPOVER_MIN_WIDTH = '240px'

interface Props {
    id: string | undefined
    subjects: Subject[] | undefined | null
    open: boolean
    anchorEl: Element | null | undefined
    handleClose: () => void
    handleOnSubjectAdd: () => void
    handleOnSubjectSelect: (subjectId: string) => void
    handleOnSubjectUpdate: (subjectId: string) => void
    handleOnSubjectDelete: (subjectId: string) => void
}

export default function SubjectPopover(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    const [subjectId, setSubjectId] = React.useState<string | undefined | null>(undefined)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const rowOpen = Boolean(anchorEl) && Boolean(subjectId);
    const popoverId = rowOpen ? 'SubjectPopoverRowPopover' : undefined;

    return (
        <>
            <Popover
                id={props.id}
                open={props.open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Stack direction='column' sx={{minWidth: POPOVER_MIN_WIDTH}}>
                    <Stack
                        direction='column'
                        alignItems='center'
                        sx={{width: '100%', p: 1}}
                    >
                        {
                            props.subjects?.map(subject => (
                                <Stack direction={'row'} alignItems={'center'} sx={{width: '100%'}}>
                                    <Button
                                        key={subject?._id}
                                        onClick={() => props.handleOnSubjectSelect(subject?._id)}
                                        color='inherit'
                                        sx={{width: '100%', height: BUTTON_HEIGHT}}
                                    >
                                        <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                                            <Iconify
                                                // @ts-ignore
                                                icon="material-symbols:circle"
                                                color={subject?.color ? colorToHexColor(subject?.color) : COLOR_DEFAULT}
                                                width={14}
                                                height={14}
                                                ml={0.25}
                                            />
                                            <Typography
                                                variant='body2'
                                                color={theme.palette.text.primary}
                                                sx={{ml: 1.75}}
                                            >
                                                {subject?.name}
                                            </Typography>
                                        </Stack>
                                    </Button>
                                    <IconButton
                                        onClick={(event) => {
                                            setAnchorEl(event.currentTarget)
                                            setSubjectId(subject?._id)
                                        }}
                                        sx={{
                                            flex: 0,
                                            width: '38px',
                                            height: '38px',
                                            color: theme.palette.text.secondary
                                        }}
                                    >
                                        <Iconify
                                            // @ts-ignore
                                            icon="carbon:overflow-menu-horizontal"
                                            width={24}
                                            height={24}
                                        />
                                    </IconButton>
                                </Stack>
                            ))
                        }
                    </Stack>

                    <Divider/>

                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        sx={{width: '100%', backgroundColor: '#fbfbfa'}}
                    >
                        <Button
                            onClick={props.handleOnSubjectAdd}
                            color='inherit'
                            sx={{width: '100%', height: BUTTON_HEIGHT, m: 1}}
                        >
                            <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                                <Iconify
                                    // @ts-ignore
                                    icon="material-symbols:add"
                                    color={theme.palette.text.secondary}
                                    width={18}
                                    height={18}
                                />
                                <Typography
                                    variant='body2'
                                    color={theme.palette.text.secondary}
                                    sx={{marginLeft: '12px'}}
                                >
                                    {t('task_dialog.add_subject')}
                                </Typography>
                            </Stack>
                        </Button>
                    </Stack>
                </Stack>
            </Popover>

            <SubjectOptionsPopover
                id={popoverId}
                open={rowOpen}
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                handleOnUpdate={() => {
                    setAnchorEl(null)
                    if (subjectId) {
                        props.handleOnSubjectUpdate(subjectId)
                    }
                }}
                handleOnDelete={() => {
                    setAnchorEl(null)
                    if (subjectId) {
                        props.handleOnSubjectDelete(subjectId)
                    }
                }}
            />
        </>
    )
}