import moment from "moment";
import {RecurringPatternDecorator, RecurringType} from "../../model/RecurringPatternDecorator";
import {LessonWithOccurrenceIdAndDate} from "../../model/LessonWithOccurrenceIdAndDate";
import {LessonOccurrenceDecorator} from "../../model/LessonOccurrenceDecorator";
import {HolidayDecorator} from "../../model/HolidayDecorator";
import {Scheduling, TimeFormat, TimetableDecorator} from "../../model/TimetableDecorator";
import {getEpochDay, MONGO_DATE_FORMAT, nextOrSameDay} from "../date";
import {LessonWithSpanAndLength} from "../../model/LessonWithSpanAndLength";
import {Timetable} from "../../models/Timetable";
import {Holiday} from "../../models/Holiday";
import {LessonOccurrence} from "../../models/LessonOccurrence";
import {Lesson} from "../../models/Lesson";

export function parseByDate(
    lessons: Lesson[],
    occurrences: LessonOccurrence[],
    date: moment.Moment,
    timetable: Timetable,
    holidays: Holiday[],
    startOfWeek: moment.Moment
): LessonWithOccurrenceIdAndDate[] {
    const timetableDeco = new TimetableDecorator(timetable);
    const holidayDeco = holidays.map((holiday) => new HolidayDecorator(holiday));

    // Skip parsing if outside of bounds
    if (timetableDeco.getStartDate() && date.isBefore(timetableDeco.getStartDate())) return [];
    if (timetableDeco.getEndDate() && date.isAfter(timetableDeco.getEndDate())) return [];

    // Skip if date is a holiday
    if (holidayDeco.filter((holiday) => {
        return holiday.getStartDate()?.isValid() &&
            holiday.getEndDate()?.isValid() &&
            date.isSameOrAfter(holiday.getStartDate()) &&
            date.isSameOrBefore(holiday.getEndDate());
    }).length > 0) {
        return [];
    }

    const result: LessonWithOccurrenceIdAndDate[] = [];

    lessons.forEach((lesson) => {
        const mongoDate = date.format(MONGO_DATE_FORMAT);

        const occurrencesByLesson = occurrences
            .filter(it => it.lesson?._id === lesson._id)
            .map(it => new LessonOccurrenceDecorator(it))
            .filter(it => {
                const exception = it.getInstanceExceptions().get(mongoDate);
                return !(exception && exception.is_cancelled);
            });

        function registerOccurrence(occurrence: LessonOccurrenceDecorator) {
            result.push(
                new LessonWithOccurrenceIdAndDate(
                    timetableDeco,
                    lesson,
                    occurrence,
                    date
                ))
            ;
        }

        // -------------------------------------------------------------------------------------------------------------
        // Parse non-recurring occurrences
        occurrencesByLesson.filter((occurrence) => !occurrence.isRecurring())
            .forEach((occurrence) => {
                if (occurrence.getDate()?.isSame(date)) {
                    registerOccurrence(occurrence);
                }
            });

        // -------------------------------------------------------------------------------------------------------------
        // Parse occurrences with recurring pattern
        occurrencesByLesson
            .filter(it => it.isRecurring() && it.getRecurringPattern())
            .forEach(it => {
                const pattern = new RecurringPatternDecorator(it.getRecurringPattern()!!);
                const distance = pattern.getSeparationCount();

                // Validation
                if (distance < 0) return;

                // ---------------------------------------------------------------------------------------------------------
                // Do not add occurrence if query date is before occurrence start date
                if (date.isBefore(it.getDate())) return;

                // ---------------------------------------------------------------------------------------------------------
                // Do not add occurrence if query date is after occurrence end date
                if (pattern.getEndDate() && date.isAfter(pattern.getEndDate())) return;

                let shouldRegister = false;
                switch (pattern.getRecurringType()) {
                    case RecurringType.DAILY:
                        const diffInDays = it.getDate()?.diff(date, "days");
                        shouldRegister = distance === 0 ||
                            (diffInDays !== null && diffInDays !== undefined && diffInDays % (distance + 1) === 0);
                        break;

                    case RecurringType.WEEKLY:
                        const diffInWeeks = it.getDate()?.startOf('week').diff(
                            date.clone().startOf('week'),   // otherwise it will change the original date
                            "weeks"
                        );
                        const distChk = distance === 0 ||
                            (diffInWeeks !== null && diffInWeeks !== undefined && diffInWeeks % (distance + 1) === 0);

                        let dayOfWeekChk = false;
                        if (!pattern.getDaysOfWeek()) {
                            dayOfWeekChk = it.getDate()?.isoWeekday() === date.isoWeekday()
                        } else {
                            dayOfWeekChk = pattern.getDaysOfWeek()?.includes(date.isoWeekday()) || false;
                        }

                        shouldRegister = distChk && dayOfWeekChk;
                        break;

                    case RecurringType.MONTHLY:
                        const diffInMonths = 12 * (it.getDate()?.year() || 0 - date.year()) +
                            (it.getDate()?.month() || 0) - date.month();
                        shouldRegister = it.getDate()?.day() === date.day() &&
                            (distance === 0 || diffInMonths % (distance + 1) === 0);
                        break;

                    case RecurringType.YEARLY:
                        const diffInYears = it.getDate()?.year() || 0 - date.year();
                        shouldRegister = it.getDate()?.dayOfYear() === date.dayOfYear() &&
                            (distance === 0 || diffInYears % (distance + 1) === 0);
                        break;
                }

                if (shouldRegister) {
                    registerOccurrence(it);
                }
            });

        // ---------------------------------------------------------------------------------------------------------
        // Parse lessons without recurring pattern
        switch (timetableDeco.getScheduling()) {
            case Scheduling.WEEKLY: {
                const numberOfWeeks = timetableDeco.getNumberOfWeeks()
                const startWeekDate = timetableDeco.getStartWeekDate();
                let queryWeek: number;
                if (startWeekDate !== null && startWeekDate !== undefined) {
                    queryWeek = indexOfWeek(
                        date.clone(),
                        startWeekDate.clone(),
                        timetableDeco.getStartWeek(),
                        numberOfWeeks,
                        holidayDeco
                    );
                } else {
                    queryWeek = -1;
                }

                occurrencesByLesson
                    .filter((occurrence) =>
                        occurrence.isRecurring() && !occurrence.getRecurringPattern()
                    )
                    .forEach((occurrence) => {
                        if (
                            occurrence.getDate()?.weekday() === date.weekday() &&
                            queryWeek === ((occurrence.getIndexOfWeek() % numberOfWeeks))
                        ) {
                            registerOccurrence(occurrence);
                        }
                    });

                break;
            }
            case Scheduling.SHIFT: {
                // Add recurring classes according to day rotation
                const shiftStartDay = timetableDeco.getShiftStartDay();
                let queryDay: number;
                if (shiftStartDay !== null && shiftStartDay !== undefined) {
                    queryDay = indexOfDay(
                        date.clone(),
                        timetableDeco.getShiftDaysOfWeek(),
                        shiftStartDay.clone(),
                        timetableDeco.getShiftNumberOfDays(),
                        holidayDeco
                    )
                } else {
                    queryDay = -1;
                }

                if (queryDay >= 0) {
                    occurrencesByLesson.filter((occurrence) =>
                        occurrence.isRecurring() && occurrence.getRecurringPattern() === null
                    ).forEach((occurrence) => {
                        if (queryDay === occurrence.getIndexOfDay()) {
                            registerOccurrence(occurrence)
                        }
                    });
                }
                break;
            }
        }
    });

    return result;
}

export function indexOfDayWithTimetable(
    query: moment.Moment,
    timetable: TimetableDecorator,
    holidays: Holiday[]
) {
    const shiftStartDay = timetable.getShiftStartDay();
    if (shiftStartDay === null || shiftStartDay === undefined) return -1;

    return indexOfDay(
        query.clone(),
        timetable.getShiftDaysOfWeek(),
        shiftStartDay,
        timetable.getShiftNumberOfDays(),
        holidays.map((holiday) => new HolidayDecorator(holiday))
    )
}

function indexOfDay(
    query: moment.Moment,
    daysOfWeek: Set<number>,
    startDay: moment.Moment,
    numberOfDays: number,
    holidays: HolidayDecorator[]
): number {
    // Check whether day of week is in the timetable
    if (!daysOfWeek.has(query.isoWeekday())) return -1;

    // Check whether query is a holiday with push schedule enabled
    const pushHolidays = holidays.filter((holiday) => holiday.getPushSchedule())
    if (
        pushHolidays.some((holiday) =>
            query.isSameOrAfter(holiday.getStartDate()) && query.isSameOrBefore(holiday.getEndDate())
        )
    ) {
        return -1;
    }

    // -----------------------------------------------------------------------------------------------------------------
    // -- Find actual start day
    let firstDayOfStartDay = startDay.clone();

    function skipToValidDayOfWeek() {
        // Find first day that is a valid dayOfWeek
        for (let days = 0; days < 7; days++) {
            const day = firstDayOfStartDay.clone().add(days, 'days');
            if (daysOfWeek.has(day.isoWeekday())) {
                firstDayOfStartDay = day;
                break;
            }
        }
    }

    // Start from first valid day of week
    skipToValidDayOfWeek()

    // Find first day that is not a holiday
    const isHolidayCondition = (holiday: HolidayDecorator) => {
        return firstDayOfStartDay.isSameOrAfter(holiday.getStartDate()) &&
            firstDayOfStartDay.isSameOrBefore(holiday.getEndDate())
    }
    let holidaysOnStartDay = pushHolidays.filter(isHolidayCondition);

    while (holidaysOnStartDay.length > 0) {
        // Start day is last day of holiday plus 1
        const maxEndDate = holidaysOnStartDay.reduce((prev, curr) =>
            prev.getEndDate()?.isSameOrAfter(curr.getEndDate()) ? prev : curr
        )?.getEndDate()?.clone().add(1, 'days');

        if (maxEndDate) {
            firstDayOfStartDay = maxEndDate;
            skipToValidDayOfWeek();
            holidaysOnStartDay = pushHolidays.filter(isHolidayCondition);
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // -- Updated during iteration
    let dayIndex = 0;
    let dayOfWeek = firstDayOfStartDay.isoWeekday();
    let diffInDays = query.diff(firstDayOfStartDay, 'days');
    let epochDay = firstDayOfStartDay.diff(moment(0), 'days');

    const isHoliday = (epochDay: number, ranges: HolidayDecorator[]) => {
        return ranges.some((holiday) => {
            const startDate = holiday.getStartDate();
            const endDate = holiday.getEndDate();
            if (startDate !== null && startDate !== undefined && endDate !== null && endDate !== undefined) {
                const startEpochDay = getEpochDay(startDate);
                const endEpochDay = getEpochDay(endDate);
                return epochDay >= startEpochDay && epochDay <= endEpochDay
            } else {
                return false;
            }
        })
    }

    while (diffInDays !== 0) {
        if (diffInDays > 0) {
            diffInDays--;

            epochDay += 1;
            dayOfWeek += 1;
            if (dayOfWeek > 7) dayOfWeek -= 7;

            if (daysOfWeek.has(dayOfWeek) && !isHoliday(epochDay, pushHolidays)) {
                dayIndex++;
            }
        } else {
            diffInDays++;

            epochDay -= 1;
            dayOfWeek -= 1;
            if (dayOfWeek < 1) dayOfWeek += 7;

            if (daysOfWeek.has(dayOfWeek) && !isHoliday(epochDay, pushHolidays)) {
                dayIndex--;
            }
        }
    }

    return ((numberOfDays + dayIndex % numberOfDays) % numberOfDays);
}

export function indexOfWeekWithTimetable(
    query: moment.Moment,
    timetable: TimetableDecorator,
    holidays: Holiday[]
) {
    const startWeekDate = timetable.getStartWeekDate();
    if (startWeekDate === null || startWeekDate === undefined) return -1;

    return indexOfWeek(
        query,
        startWeekDate,
        timetable.getStartWeek(),
        timetable.getNumberOfWeeks(),
        holidays.map((holiday) => new HolidayDecorator(holiday))
    )
}

function indexOfWeek(
    query: moment.Moment,
    startWeekDate: moment.Moment,
    startWeek: number,
    numOfWeeks: number,
    holidays: HolidayDecorator[]
): number {
    // Input validation
    if (startWeek < 0) return -1;
    if (numOfWeeks < 0) return -1;

    // -----------------------------------------------------------------------------------------------------------------
    // Find which weeks are skipped by push holidays (in terms of week start day)
    const skipWeeks = new Set<moment.Moment>();
    holidays.filter((holiday) => holiday.getPushSchedule())
        .forEach((holiday) => {
            let startStartOfWeek = holiday.getStartDate()?.startOf('week');
            const endStartOfWeek = holiday.getEndDate()?.startOf('week');

            function getWeekWorkDays(): moment.Moment[] {
                const list: moment.Moment[] = [];
                if (startStartOfWeek) {
                    list.push(nextOrSameDay(startStartOfWeek, 1));
                    list.push(nextOrSameDay(startStartOfWeek, 2));
                    list.push(nextOrSameDay(startStartOfWeek, 3));
                    list.push(nextOrSameDay(startStartOfWeek, 4));
                    list.push(nextOrSameDay(startStartOfWeek, 5));
                }
                return list;
            }

            while (startStartOfWeek?.isSameOrBefore(endStartOfWeek)) {
                // Check if holiday includes all days of this week
                if (
                    getWeekWorkDays().every((day) =>
                        holiday.getStartDate()?.isSameOrBefore(day) && day.isSameOrBefore(holiday.getEndDate())
                    )
                ) {
                    skipWeeks.add(startStartOfWeek);
                }
                startStartOfWeek = startStartOfWeek.add(1, "week");
            }
        });

    // -----------------------------------------------------------------------------------------------------------------
    // Find first week that is not a holiday
    let firstDayOfStartWeekDate = startWeekDate.startOf('week');
    while (skipWeeks.has(firstDayOfStartWeekDate)) {
        firstDayOfStartWeekDate = firstDayOfStartWeekDate.add(1, "week");
    }

    // -----------------------------------------------------------------------------------------------------------------
    const queryWithFirstDoW = query.startOf('week');

    // If week is skipped due to holidays, return a negative index
    if (skipWeeks.has(queryWithFirstDoW)) {
        return -1;
    }

    const startWithFirstDoW = firstDayOfStartWeekDate.startOf('week');
    const isQueryBeforeStart = queryWithFirstDoW.isSameOrBefore(startWithFirstDoW);

    let skipWeeksCount = 0;
    skipWeeks.forEach((skipWeek) => {
        if (isQueryBeforeStart) {
            if (queryWithFirstDoW.isSameOrBefore(skipWeek) && skipWeek.isSameOrBefore(startWithFirstDoW)) {
                skipWeeksCount++;
            }
        } else {
            if (startWithFirstDoW.isSameOrBefore(skipWeek) && skipWeek.isSameOrBefore(queryWithFirstDoW)) {
                skipWeeksCount++;
            }
        }
    });

    const diffInWeeks = queryWithFirstDoW.diff(startWithFirstDoW, 'weeks') +
        ((isQueryBeforeStart) ? skipWeeksCount : -skipWeeksCount);

    return ((numOfWeeks + diffInWeeks % numOfWeeks + startWeek) % numOfWeeks);
}

export function computeSpanAndLength(lessons: LessonWithOccurrenceIdAndDate[]): LessonWithSpanAndLength[] {
    const lessonWithSpanAndLength = lessons.sort((a, b) => {
        let start: number;
        let otherStart: number;

        if (a.getTimetable().getTimeFormat() === TimeFormat.PERIOD) {
            start = a.getOccurrence().getStartTimeInPeriod() ?? 0;
            otherStart = b.getOccurrence().getStartTimeInPeriod() ?? 0;
        } else {
            start = a.getOccurrence().getStartTimeInMinutes() ?? 0;
            otherStart = b.getOccurrence().getStartTimeInMinutes() ?? 0;
        }

        if (start === otherStart) {
            return a.getOccurrence().toProps()._id < b.getOccurrence().toProps()._id ? -1 : 1;
        } else {
            return start < otherStart ? -1 : 1;
        }
    }).map((lesson) => new LessonWithSpanAndLength(lesson, 0, 0, 0));

    lessonWithSpanAndLength.forEach((item, i) => {
        const spans = lessonWithSpanAndLength.filter((item1, i1) => {
            return i1 < i && item.getLesson().intersects(item1.getLesson());
        }).map((item) => item.getStartSpan());

        const numSpans = spans.length + 1;

        // Minimum assignable span?
        const assignableSpans = Array.from(Array(numSpans + 1).keys()).filter((span) => !spans.includes(span))
        if (assignableSpans.length > 0) {
            item.setStartSpan(Math.min(...assignableSpans));
        } else {
            item.setStartSpan(0);
        }
    });

    lessonWithSpanAndLength.forEach((item) => {
        const spans = lessonWithSpanAndLength.filter((item1) => {
            return item.getLesson().intersects(item1.getLesson());
        }).map((item) => item.getStartSpan());

        let numSpans: number
        if (spans.length === 0) {
            numSpans = 1;
        } else {
            numSpans = Math.max(...spans) + 1;
        }
        item.setNumberOfSpans(numSpans);

        let nextSpan: number
        if (spans.length === 0) {
            nextSpan = numSpans - 1;
        } else {
            nextSpan = Math.min(...spans.filter((span) => item.getStartSpan() < span));
        }

        item.setLengthInSpans(
            item.getStartSpan() >= numSpans - 1 ? 1 : nextSpan - item.getStartSpan()
        );
    });

    return lessonWithSpanAndLength;
}