// component
import SvgColor from '../../../common/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{width: 1, height: 1}}/>;

const navConfig = [
    {
        title: "FAQs",
        children: [
            {
                title: 'support.cloud_sync.drawer',
                path: '/support/cloud-sync',
                icon: icon('ic_cloud_sync_outline'),
            },
            {
                title: 'support.payments.drawer',
                path: '/support/payments',
                icon: icon('ic_credit_card_outline'),
            },
            {
                title: 'support.permissions.drawer',
                path: '/support/permissions',
                icon: icon('ic_security'),
            }
        ]
    },
    {
        title: "Legal",
        children: [
            {
                title: 'support.privacy.drawer',
                path: '/support/privacy',
                icon: icon('ic_shield_account'),
            }
        ]
    }
];

export default navConfig;
