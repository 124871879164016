import Box from '../../../common/components/Box';
import Drawer from '../../../common/components/Drawer';
import NavbarWrapper from '../../../common/components/Navbar';
import ScrollSpyMenu from '../../../common/components/ScrollSpyMenu';
import Container from '../../../common/components/UI/Container';
import {DrawerContext} from '../../../common/contexts/DrawerContext';
import {menu_items} from '../../../common/data';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import Logo from "../../../common/components/Logo";
import {GOOGLE_PLAY_APP_URL} from "../../../common/Constants";
import {useTranslation} from "react-i18next";
import Iconify from "../../../common/components/iconify";
import {useTheme} from "@mui/material/styles";
import {Button, IconButton, Stack} from "@mui/material";

const Navbar = ({navbarStyle, logoStyle, button, row, menuWrapper}) => {
    const theme = useTheme()
    const {t} = useTranslation()
    const {state, dispatch} = useContext(DrawerContext);

    // Toggle drawer
    const toggleHandler = () => {
        dispatch({
            type: 'TOGGLE',
        });
    };

    return (
        <NavbarWrapper {...navbarStyle}>
            <Container width="1400px">
                <Box {...row}>
                    <Logo linkTo={'/'}/>
                    <Box {...menuWrapper} className="mainMenuWrapper">
                        <ScrollSpyMenu
                            className="main_menu"
                            menuItems={menu_items}
                            offset={-70}
                        />
                        <Button
                            href="/login"
                            className={"navbar_button navbar_button_one"}
                            color={'inherit'}
                            size={"large"}
                            sx={{
                                height: "40px",
                                pl: "12px",
                                pr: "16px",
                                borderRadius: 1.5,
                                fontWeight: 600
                            }}
                        >
                            <Iconify
                                icon={"material-symbols:lock-open-outline"}
                                width={22}
                                height={22}
                                mr={1.5}
                                mb={0.5}
                                color={theme.palette.text.primary}
                            />
                            {t("landing.log_in")}
                        </Button>
                        <Button
                            href={GOOGLE_PLAY_APP_URL}
                            className={"navbar_button navbar_button_two"}
                            variant={"contained"}
                            size={"large"}
                            sx={{
                                height: "40px",
                                px: 2.5,
                                borderRadius: 1.5,
                                boxShadow: "none",
                                fontWeight: 600
                            }}
                        >
                            {t("landing.download_now")}
                        </Button>
                        <Drawer
                            width="420px"
                            placement="right"
                            drawerHandler={
                                <IconButton>
                                    <Iconify
                                        icon="material-symbols:menu-rounded"
                                        color={theme.palette.text.secondary}
                                        width={28}
                                        height={28}
                                    />
                                </IconButton>
                            }
                            open={state.isOpen}
                            toggleHandler={toggleHandler}
                        >
                            <ScrollSpyMenu
                                className="mobile_menu"
                                menuItems={menu_items}
                                drawerClose={true}
                                offset={-100}
                            />
                            <Stack className={"mobile-menu-drawer-bottom"} direction={"row"}>
                                <Button
                                    href="/login"
                                    size={"large"}
                                    className={"navbar_button"}
                                    color={"inherit"}
                                    variant={'contained'}
                                    sx={{
                                        borderRadius: 1.5,
                                        fontWeight: 600,
                                        boxShadow: "none"
                                    }}
                                >
                                    <Iconify
                                        icon={"material-symbols:lock-open-outline"}
                                        width={20}
                                        height={20}
                                        mr={1.5}
                                        color={theme.palette.text.primary}
                                    />
                                    {t("landing.log_in")}
                                </Button>
                                <Button
                                    href={GOOGLE_PLAY_APP_URL}
                                    size={"large"}
                                    className={"navbar_button"}
                                    variant={"contained"}
                                    sx={{
                                        borderRadius: 1.5,
                                        fontWeight: 600,
                                        boxShadow: "none"
                                    }}
                                >
                                    {t("landing.download_now")}
                                </Button>
                            </Stack>
                        </Drawer>
                    </Box>
                </Box>
            </Container>
        </NavbarWrapper>
    );
};

Navbar.propTypes = {
    navbarStyle: PropTypes.object,
    logoStyle: PropTypes.object,
    button: PropTypes.object,
    row: PropTypes.object,
    menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
    navbarStyle: {
        className: 'web_app_creative_navbar',
        minHeight: '70px',
        display: 'block',
    },
    row: {
        flexBox: true,
        alignItems: 'center',
        width: '100%',
    },
    logoStyle: {
        maxWidth: ['148px', '148px'],
    },
    button: {},
    menuWrapper: {
        flexBox: true,
        alignItems: 'center',
    },
};

export default Navbar;
