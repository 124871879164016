import Realm from "realm";

export interface EventStepProps {
    title: string;
    completed_on?: string | undefined;
}

export class EventStep extends Realm.Object {
    title!: string;
    completed_on?: string;

    static schema: Realm.ObjectSchema = {
        name: 'event_step',
        embedded: true,
        properties: {
            title: 'string',
            completed_on: 'string?',
        }
    }
}