import Realm from "realm";
import {Planner} from "./Planner";

export class Holiday extends Realm.Object {
    _id!: string;
    owner_id!: string;
    planner?: Planner;
    name!: string;
    start_date!: string;
    end_date!: string;
    push_schedule!: boolean;

    static schema: Realm.ObjectSchema = {
        name: 'holiday',
        primaryKey: '_id',
        properties: {
            _id: 'string',
            owner_id: 'string',
            planner: 'planner?',
            name: 'string',
            start_date: 'string',
            end_date: 'string',
            push_schedule: 'bool',
        }
    }
}