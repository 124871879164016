import {alpha, styled, useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useState} from "react";
import {BootstrapDialog, Transition} from "../../../../common/components/BootstrapDialog";
import {
    Button,
    DialogActions,
    IconButton,
    Snackbar,
    Stack,
    Switch,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import {ErrorState, LessonOccurrenceDecorator} from "../../../../model/LessonOccurrenceDecorator";
import useMediaQuery from "@mui/material/useMediaQuery";
import {TimetableDecorator} from "../../../../model/TimetableDecorator";
import moment from "moment";
import {MONGO_DATE_FORMAT, nextOrSameDay} from "../../../../utils/date";
import OccurrenceLayout from "./OccurrenceLayout/OccurrenceLayout";
import ObjectID from "bson-objectid";
import SubjectPopover from "../../../../common/components/SubjectPopover";
import {COLOR_DEFAULT, colorToHexColor, colorToSigned24Bit} from "../../../../utils/color";
import ColorPopover from "../../../../common/components/color-popover";
import OccurrenceRepeatDialog from "./OccurrenceRepeatDialog";
import {RecurringPatternDecorator} from "../../../../model/RecurringPatternDecorator";
import SubjectCommitDialog, {SubjectDialogUpdateOptionsProps} from "../../../task/dialog/SubjectCommitDialog";
import SaveButton from "../../../../common/components/SaveButton";
import {Subject} from "../../../../models/Subject";
import {Lesson} from "../../../../models/Lesson";
import {useLessonManager} from "../../../../hooks/useLessonManager";
import {useLessonOccurrenceManager} from "../../../../hooks/useLessonOccurrenceManager";
import {useSubjectManager} from "../../../../hooks/useSubjectManager";

export default function LessonCommitDialog(
    props: {
        open: boolean
        handleClose: () => void
        selectedPlannerId: string
        selectedTimetable: TimetableDecorator
        subjects: Subject[] | undefined
        updateOptions?: {
            lesson: Lesson | undefined
            occurrences: LessonOccurrenceDecorator[] | undefined
        } | null | undefined
    }
) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [
        stateOccurrences,
        setOccurrences
    ] = useState<LessonOccurrenceDecorator[] | undefined>(undefined)

    if (props.open) {
        if (stateOccurrences === undefined && props.updateOptions?.occurrences) {
            setOccurrences(props.updateOptions?.occurrences)
        } else if (stateOccurrences === undefined) {
            setOccurrences([new LessonOccurrenceDecorator(getDefaultOccurrence())])
        }
    }

    const occurrences = stateOccurrences ?? []

    const [
        selectedSubjectId,
        setSelectedSubjectId
    ] = useState<string | undefined | null>(undefined)

    if (selectedSubjectId === undefined && props.updateOptions?.lesson?.subject?._id) {
        setSelectedSubjectId(props.updateOptions?.lesson?.subject?._id)
    }

    const selectedSubject: Subject | undefined = props.subjects?.find(it => it._id === selectedSubjectId)

    const [
        title,
        setTitle
    ] = useState<string | undefined | null>(undefined)

    if (title === undefined && props.updateOptions?.lesson?.title) {
        setTitle(props.updateOptions?.lesson?.title)
    }

    const [
        stateColor,
        setColor
    ] = useState<number | undefined | null>(undefined)

    if (stateColor === undefined && props.updateOptions?.lesson?.color) {
        setColor(props.updateOptions?.lesson?.color)
    }

    const color = stateColor ?? colorToSigned24Bit(COLOR_DEFAULT)

    const [
        stateUseSubject,
        setUseSubject
    ] = useState<boolean | undefined>(undefined)

    if (stateUseSubject === undefined && props.updateOptions?.lesson?.subject) {
        setUseSubject(true)
    }

    if (stateUseSubject === undefined && props.updateOptions?.lesson?.title) {
        setUseSubject(false)
    }

    const useSubject = stateUseSubject ?? true

    const [
        room,
        setRoom
    ] = useState<string | undefined | null>(undefined)

    if (room === undefined && props.updateOptions?.lesson?.room) {
        setRoom(props.updateOptions?.lesson?.room)
    }

    const [
        note,
        setNote
    ] = useState<string | undefined | null>(undefined)

    if (note === undefined && props.updateOptions?.lesson?.note) {
        setNote(props.updateOptions?.lesson?.note)
    }

    const [
        repeatDialogOccurrence,
        setRepeatDialogOccurrence
    ] = useState<LessonOccurrenceDecorator | undefined | null>(undefined)

    // [SubjectPopover]
    const [
        subjectAnchorEl,
        setSubjectAnchorEl
    ] = React.useState<HTMLButtonElement | null>(null);

    const subjectOpen = Boolean(subjectAnchorEl);
    const popoverSubjectId = subjectOpen ? 'TimetablePopover' : undefined;

    const [
        subjectDialogOpen,
        setSubjectDialogOpen
    ] = React.useState(false)

    const [
        colorAnchorEl,
        setColorAnchorEl
    ] = React.useState<HTMLButtonElement | null>(null);

    const colorOpen = Boolean(colorAnchorEl);
    const popoverColorId = colorOpen ? 'color-popover' : undefined;

    const [
        subjectDialogUpdateOptions,
        setSubjectDialogUpdateOptions
    ] = useState<SubjectDialogUpdateOptionsProps | undefined | null>(null)

    // [Errors]
    const [
        error,
        setError
    ] = useState<string | undefined>(undefined)

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        createLesson,
        updateLesson
    } = useLessonManager()

    const {
        createLessonOccurrence,
        updateLessonOccurrencesForLesson
    } = useLessonOccurrenceManager()

    const {
        deleteSubject
    } = useSubjectManager()

    // -----------------------------------------------------------------------------------------------------------------
    // Variables
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // -----------------------------------------------------------------------------------------------------------------
    const createLessonCallback = () => {
        const result = validate_create_update();
        if (result) return result;

        const lessonId = createLesson({
            timetableId: props.selectedTimetable.getTimetableId(),
            ...(useSubject && selectedSubject) && {subjectId: selectedSubject._id},
            ...(!useSubject && title) && {title: title},
            color: color,
            ...(room) && {room: room},
            ...(note) && {note: note}
        });

        occurrences.forEach(it => {
            const pattern = it.getRecurringPattern()

            createLessonOccurrence({
                lessonId: lessonId,
                date: it.getDate()?.format(MONGO_DATE_FORMAT),
                index_of_day: it.getIndexOfDay(),
                index_of_week: it.getIndexOfWeek(),
                time_start_in_minutes: it.getStartTimeInMinutes() ?? undefined,
                time_end_in_minutes: it.getEndTimeInMinutes() ?? undefined,
                time_start_in_periods: it.getStartTimeInPeriod() ?? undefined,
                time_end_in_periods: it.getEndTimeInPeriod() ?? undefined,
                is_recurring: it.isRecurring(),
                ...(pattern) && {recurring_pattern: pattern}
            })
        })

        return Promise.resolve()
    }

    const updateLessonCallback = () => {
        const lessonId = props.updateOptions?.lesson?._id
        if (lessonId === undefined || lessonId === null) {
            return Promise.reject("An unexpected error occurred")
        }

        const result = validate_create_update();
        if (result) return result;

        const updateSubjectId = useSubject && selectedSubject ? selectedSubject._id : undefined;
        const updateSubjectTitle = !useSubject ? title : undefined;

        updateLesson(lessonId, {
            ...(updateSubjectId) && {subjectId: updateSubjectId},
            ...(updateSubjectTitle) && {title: updateSubjectTitle},
            color: color,
            room: room ?? undefined,
            note: note ?? undefined
        });

        const optionsList = occurrences.map(it => {
            const pattern = it.getRecurringPattern()

            return {
                lessonId: lessonId,
                date: it.getDate()?.format(MONGO_DATE_FORMAT),
                index_of_day: it.getIndexOfDay(),
                index_of_week: it.getIndexOfWeek(),
                time_start_in_minutes: it.getStartTimeInMinutes() ?? undefined,
                time_end_in_minutes: it.getEndTimeInMinutes() ?? undefined,
                time_start_in_periods: it.getStartTimeInPeriod() ?? undefined,
                time_end_in_periods: it.getEndTimeInPeriod() ?? undefined,
                is_recurring: it.isRecurring(),
                ...(pattern) && {recurring_pattern: pattern}
            }
        })

        updateLessonOccurrencesForLesson(lessonId, optionsList);

        return Promise.resolve()
    }

    const validate_create_update = (): Promise<never> | undefined => {
        // Subject
        if (useSubject) {
            if (!selectedSubject) {
                return Promise.reject(t("timetable.lesson.commit_dialog.error.subject_not_selected"))
            }
        } else {
            if (!title) {
                return Promise.reject(t("timetable.lesson.commit_dialog.error.subject_name_empty"))
            }
        }

        // Occurrences
        if (occurrences.length === 0) {
            return Promise.reject(t("timetable.lesson.commit_dialog.error.no_occurrences"))
        }

        const errorStates: number[] = []
        occurrences.forEach((occurrence) => {
            errorStates.push(...occurrence.getErrorStatesIfExists(props.selectedTimetable))
        })

        if (errorStates.length > 0) {
            switch (errorStates[0]) {
                case ErrorState.INVALID_START_DATE:
                    return Promise.reject(t("timetable.lesson.commit_dialog.error.invalid_start_date"))
                case ErrorState.START_TIME_MISSING:
                    return Promise.reject(t("timetable.lesson.commit_dialog.error.start_time_not_specified"))
                case ErrorState.END_TIME_MISSING:
                    return Promise.reject(t("timetable.lesson.commit_dialog.error.end_time_not_specified"))
                case ErrorState.START_TIME_OUT_OF_BOUNDS:
                    return Promise.reject(t("timetable.lesson.commit_dialog.error.invalid_start_time"))
                case ErrorState.END_TIME_OUT_OF_BOUNDS:
                    return Promise.reject(t("timetable.lesson.commit_dialog.error.invalid_end_time"))
                case ErrorState.END_TIME_BEFORE_START:
                    return Promise.reject(t("timetable.lesson.commit_dialog.error.start_date_after_end_date"))
            }
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Event handlers
    const addOccurrence = () => {
        const newOccurrences = occurrences.map(occurrence => {
            return new LessonOccurrenceDecorator(occurrence.toProps());
        })
        newOccurrences.push(new LessonOccurrenceDecorator(getDefaultOccurrence()))
        setOccurrences(newOccurrences)
    }

    const updateOccurrence = (
        occurrenceId: string,
        func: (occurrence: LessonOccurrenceDecorator) => void
    ) => {
        const newOccurrences = occurrences
            .map(it => new LessonOccurrenceDecorator(it.toProps()));

        const occurrence = newOccurrences
            .find(it => it.getId() === occurrenceId)

        if (occurrence) {
            func(occurrence)
            setOccurrences(newOccurrences)
        }
    }

    const handleDateChanged = (occurrenceId: string, date: moment.Moment) => {
        if (!date.isValid()) return;

        updateOccurrence(occurrenceId, it => it.setDate(date));
    }

    const handleDayOfWeekChange = (occurrenceId: string, dayOfWeek: number) => {
        if (!(dayOfWeek >= 1 && dayOfWeek <= 7)) return;

        const date = nextOrSameDay(moment().startOf('day'), dayOfWeek);
        updateOccurrence(occurrenceId, it => it.setDate(date));
    }

    const handleIndexOfWeekChange = (occurrenceId: string, indexOfWeek: number) => {
        if (!(indexOfWeek >= 0 && indexOfWeek < props.selectedTimetable.getNumberOfWeeks())) return;

        updateOccurrence(occurrenceId, it => it.setIndexOfWeek(indexOfWeek));
    }

    const handleIndexOfDayChange = (occurrenceId: string, indexOfDay: number) => {
        if (!(indexOfDay >= 0 && indexOfDay < props.selectedTimetable.getShiftNumberOfDays())) return;

        updateOccurrence(occurrenceId, it => it.setIndexOfDay(indexOfDay));
    }

    const handleStartTimeInMinutesChange = (occurrenceId: string, timeInMinutes: number) => {
        updateOccurrence(occurrenceId, it => it.setTimeStartInMinutes(timeInMinutes));
    }

    const handleEndTimeInMinutesChange = (occurrenceId: string, timeInMinutes: number) => {
        updateOccurrence(occurrenceId, it => it.setTimeEndInMinutes(timeInMinutes));
    }

    const handleStartTimeInPeriodsChange = (occurrenceId: string, timeInPeriods: number) => {
        updateOccurrence(occurrenceId, it => it.setTimeStartInPeriods(timeInPeriods));
    }

    const handleEndTimeInPeriodsChange = (occurrenceId: string, timeInPeriods: number) => {
        updateOccurrence(occurrenceId, it => it.setTimeEndInPeriods(timeInPeriods));
    }

    const handleOnOccurrenceDelete = (occurrenceId: string) => {
        setOccurrences(
            occurrences.filter(it => it.getId() !== occurrenceId)
        )
    }

    const getRepeatDialogPattern = () => {
        const pattern = repeatDialogOccurrence?.getRecurringPattern()
        return pattern ? new RecurringPatternDecorator(pattern) : undefined
    }

    const getRepeatDialogIsRecurring = () => {
        return repeatDialogOccurrence?.isRecurring()
    }

    const handleSaveClick = () => {
        if (props.updateOptions) {
            updateLessonCallback().then(
                () => {
                    handleClose()
                },
                (reason) => {
                    setError(reason.toString())
                }
            )
        } else {
            createLessonCallback().then(
                () => {
                    handleClose()
                },
                (reason) => {
                    setError(reason.toString())
                }
            )
        }
    }

    const handleClose = () => {
        setOccurrences(undefined)
        setSelectedSubjectId(undefined)
        setTitle(undefined)
        setUseSubject(undefined)
        setColor(undefined)
        setRoom(undefined)
        setNote(undefined)

        props.handleClose()
    }

    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            TransitionComponent={Transition}
            fullScreen={fullScreen}
            open={props.open}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{pt: 1.5, pb: 1.5, pl: 3, pr: 2, backgroundColor: theme.palette.primary.main}}
            >
                <Typography
                    flex={1}
                    fontWeight={600}
                    fontSize={'1.15em'}
                    noWrap={true}
                    color={theme.palette.primary.contrastText}
                    sx={{textTransform: 'capitalize'}}
                >

                    {t(props.updateOptions ? 'timetable.lesson.commit_dialog.title_edit' : 'timetable.lesson.commit_dialog.title_add')}
                </Typography>
                <IconButton onClick={handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <Divider/>

            <DialogContent>
                <Stack direction="column">
                    <Stack
                        direction={fullScreen ? "column" : "row"}
                        alignItems={fullScreen ? 'left' : 'center'}
                    >
                        <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                            {t('timetable.lesson.commit_dialog.subject_label')}
                        </Typography>
                        {
                            useSubject && <Button
                                variant={'outlined'}
                                color={'inherit'}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    setSubjectAnchorEl(event.currentTarget);
                                }}
                                sx={{
                                    mt: fullScreen ? 1 : 0,
                                    py: '8.5px'
                                }}
                            >
                                {
                                    selectedSubject && <Iconify
                                        // @ts-ignore
                                        icon="material-symbols:circle"
                                        color={colorToHexColor(selectedSubject?.color) ?? theme.palette.text.secondary}
                                        width={14}
                                        height={14}
                                        mr={1}
                                    />
                                }
                                <Typography variant={'body2'}>
                                    {
                                        selectedSubject ? selectedSubject.name : t('choose')
                                    }
                                </Typography>
                            </Button>
                        }
                        {
                            !useSubject && <TextField
                                variant={'outlined'}
                                size={'small'}
                                value={title}
                                placeholder={'Subject name'}
                                sx={{
                                    mt: fullScreen ? 1 : 0,
                                    '& .MuiOutlinedInput-input': {
                                        py: '10.5px',
                                        fontSize: theme.typography.body2.fontSize
                                    }
                                }}
                                onChange={(event) => {
                                    setTitle(event.target.value)
                                }}
                            />
                        }
                    </Stack>

                    {
                        !useSubject && <Stack direction={'row'} alignItems={'center'} mt={2}>
                            <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                                {t('timetable.lesson.commit_dialog.color_label')}
                            </Typography>
                            <Button
                                variant={'outlined'}
                                color={'inherit'}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    setColorAnchorEl(event.currentTarget);
                                }}
                                sx={{py: '8.5px'}}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon="material-symbols:circle"
                                    color={colorToHexColor(color)}
                                    width={14}
                                    height={14}
                                    mr={1}
                                />
                                <Typography variant={'body2'}>
                                    {t('timetable.lesson.commit_dialog.pick_color')}
                                </Typography>
                            </Button>
                        </Stack>
                    }

                    <Stack direction={'row'} alignItems={'center'} mt={2}>
                        <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                            {t('timetable.lesson.commit_dialog.use_subject_label')}
                        </Typography>
                        <Switch
                            value={useSubject}
                            checked={useSubject}
                            onChange={(event) => {
                                setUseSubject(event.target.checked)
                            }}
                        />
                    </Stack>

                    <Divider sx={{mt: 2}}/>

                    {
                        occurrences.map((occurrence, index) => {
                            return (
                                <OccurrenceLayout
                                    index={index}
                                    key={occurrence.getId()}
                                    timetable={props.selectedTimetable}
                                    occurrence={occurrence}
                                    // Date
                                    handleDateChange={(date) => {
                                        handleDateChanged(occurrence.getId(), date)
                                    }}
                                    handleDayOfWeekChange={(dayOfWeek) => {
                                        handleDayOfWeekChange(occurrence.getId(), dayOfWeek)
                                    }}
                                    handleIndexOfWeekChange={(indexOfWeek) => {
                                        handleIndexOfWeekChange(occurrence.getId(), indexOfWeek)
                                    }}
                                    handleIndexOfDayChange={(indexOfDay) => {
                                        handleIndexOfDayChange(occurrence.getId(), indexOfDay)
                                    }}
                                    // Time
                                    handleStartTimeInMinutesChange={(timeInMinutes) => {
                                        handleStartTimeInMinutesChange(occurrence.getId(), timeInMinutes)
                                    }}
                                    handleEndTimeInMinutesChange={(timeInMinutes) => {
                                        handleEndTimeInMinutesChange(occurrence.getId(), timeInMinutes)
                                    }}
                                    handleStartTimeInPeriodsChange={(timeInPeriods) => {
                                        handleStartTimeInPeriodsChange(occurrence.getId(), timeInPeriods)
                                    }}
                                    handleEndTimeInPeriodsChange={(timeInPeriods) => {
                                        handleEndTimeInPeriodsChange(occurrence.getId(), timeInPeriods)
                                    }}
                                    // Events
                                    handleOnOccurrenceDelete={() => {
                                        handleOnOccurrenceDelete(occurrence.getId())
                                    }}
                                    handleOnUpdateRepeat={() => {
                                        setRepeatDialogOccurrence(occurrence)
                                    }}
                                />
                            )
                        })
                    }

                    <Stack direction={'row'} alignItems={'center'} mt={2}>
                        <Button
                            color={'inherit'}
                            variant={'outlined'}
                            sx={{pl: 1, pr: 1.5}}
                            onClick={() => addOccurrence()}
                        >
                            <Iconify
                                // @ts-ignore
                                icon="material-symbols:add"
                                color={theme.palette.text.primary}
                                width={18}
                                height={18}
                                mr={0.5}
                            />
                            <Typography variant={'body2'}>
                                {t('timetable.lesson.commit_dialog.occurrence.add_multiple')}
                            </Typography>
                        </Button>
                    </Stack>

                    <Divider sx={{mt: 2}}/>

                    <Stack
                        direction={fullScreen ? "column" : "row"}
                        alignItems={fullScreen ? 'left' : 'center'}
                        mt={2}
                    >
                        <Typography noWrap={true} flex={1} variant={'body2'} mr={2}>
                            {t('timetable.lesson.commit_dialog.room_label')}
                        </Typography>
                        <TextField
                            variant={'outlined'}
                            size={'small'}
                            value={room}
                            placeholder={t('timetable.lesson.commit_dialog.room_label') ?? ''}
                            sx={{
                                mt: fullScreen ? 1 : 0,
                                '& .MuiOutlinedInput-input': {
                                    py: '10.5px',
                                    fontSize: theme.typography.body2.fontSize
                                }
                            }}
                            onChange={(event) => {
                                setRoom(event.target.value)
                            }}
                        />
                    </Stack>

                    <Stack
                        direction="row"
                        mt={3}
                        style={{
                            border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
                            borderRadius: '8px'
                        }}
                    >
                        <NoteTextArea
                            placeholder={t('task_dialog.add_note') || ''}
                            value={note || ''}
                            onChange={(event: React.ChangeEvent) => {
                                // @ts-ignore
                                setNote(event.target.value)
                            }}
                        />
                    </Stack>

                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={1.5}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <SaveButton
                        handleSaveClick={handleSaveClick}
                        isSaving={false}
                        text={t("save")}/>
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='inherit'
                        sx={{boxShadow: 'none'}}
                    >
                        {t("cancel")}
                    </Button>
                </Stack>
            </DialogActions>

            <SubjectPopover
                id={popoverSubjectId}
                subjects={props.subjects}
                open={subjectOpen}
                anchorEl={subjectAnchorEl}
                handleClose={() => {
                    setSubjectAnchorEl(null)
                }}
                handleOnSubjectAdd={() => {
                    setSubjectAnchorEl(null)
                    setSubjectDialogOpen(true)
                }}
                handleOnSubjectUpdate={(subjectId: string) => {
                    setSubjectAnchorEl(null)

                    const subject = props.subjects?.find(it => it._id === subjectId)
                    if (subject) {
                        setSubjectDialogUpdateOptions({subject: subject});
                        setSubjectDialogOpen(true)
                    }
                }}
                handleOnSubjectSelect={(subjectId) => {
                    setSubjectAnchorEl(null)
                    setSelectedSubjectId(subjectId)
                }}
                handleOnSubjectDelete={(subjectId: string) => {
                    setSubjectAnchorEl(null)
                    setSelectedSubjectId(null)

                    deleteSubject(subjectId);
                }}
            />

            <SubjectCommitDialog
                plannerId={props.selectedPlannerId}
                open={subjectDialogOpen}
                updateOptions={subjectDialogUpdateOptions}
                handleClose={() => {
                    setSubjectDialogOpen(false)
                    setSubjectDialogUpdateOptions(null)
                }}
            />

            <ColorPopover
                id={popoverColorId}
                color={colorToHexColor(color) || COLOR_DEFAULT}
                open={colorOpen}
                anchorEl={colorAnchorEl}
                handleClose={() => setColorAnchorEl(null)}
                onChange={(color) => setColor(colorToSigned24Bit(color.hex))}
            />

            <OccurrenceRepeatDialog
                open={!!repeatDialogOccurrence}
                handleClose={() => setRepeatDialogOccurrence(undefined)}
                timetable={props.selectedTimetable}
                isRecurring={getRepeatDialogIsRecurring()}
                pattern={getRepeatDialogPattern()}
                handleOnRepeatChanged={(isRecurring, pattern) => {
                    updateOccurrence(repeatDialogOccurrence?.getId() || '', it => {
                        it.setRecurring(isRecurring);
                        it.setRecurringPattern(pattern?.get());
                    })
                }}
            />

            <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => {
                    setError(undefined)
                }}
                message={error}
            />

        </BootstrapDialog>
    )
}


// ---------------------------------------------------------------------------------------------------------------------
const getDefaultOccurrence = () => {
    return {
        _id: ObjectID().toHexString(),
        owner_id: "",
        date: moment().format(MONGO_DATE_FORMAT),
        index_of_week: 0,
        index_of_day: 0,
        time_start_in_minutes: 9 * 60,
        time_end_in_minutes: 10 * 60,
        time_start_in_periods: 1,
        time_end_in_periods: 2,
        is_recurring: true
    }
}


// ---------------------------------------------------------------------------------------------------------------------
const NoteTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    margin: '0px 16px',
    paddingTop: '12px',
    paddingBottom: '12px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    minHeight: '48px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight
}));