import * as React from "react";
import moment from "moment";
import {Button, IconButton, Stack, TextareaAutosize, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import {styled, useTheme} from "@mui/material/styles";
import {CloseButton} from "../TaskDialog";
import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../../common/components/SaveButton";
import {EventStepProps} from "../../../../models/EventStep";

// ----------------------------------------------------------------------

const SubTaskTextArea = styled(TextareaAutosize)(({theme}) => ({
    marginTop: '12px',
    marginBottom: '8px',
    marginLeft: '16px',
    marginRight: '16px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
}));

const SubTaskButton = styled(Button)(({theme}) => ({
    textTransform: 'none'
}));


// ----------------------------------------------------------------------

interface SubTaskRowProps {
    subTask: EventStepProps
    handleOnToggleSubTaskCompletedOn: () => void
    setChangeSubTaskUiVisibleAtIndex: () => void
    handleRemoveSubTaskAt: () => void
}

const SubTaskRow = (props: SubTaskRowProps) => {
    const theme = useTheme()

    return (
        <>
            <Stack direction='row' alignItems='center'>
                <IconButton sx={{color: 'text.primary'}} onClick={props.handleOnToggleSubTaskCompletedOn}>
                    <Iconify
                        // @ts-ignore
                        icon={
                            props.subTask.completed_on ?
                                "material-symbols:check-circle-rounded" :
                                "material-symbols:circle-outline"
                        }
                        color={props.subTask.completed_on ? theme.palette.primary.main : theme.palette.text.secondary}
                        width={18}
                        height={18}
                    />
                </IconButton>
                <SubTaskButton
                    onClick={props.setChangeSubTaskUiVisibleAtIndex}
                    color='inherit'
                    sx={{width: '100%', height: '48px'}}
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        sx={{width: '100%'}}
                    >
                        <Typography
                            variant='body2'
                            color={theme.palette.text.primary}
                            noWrap={true}
                        >
                            {props.subTask.title}
                        </Typography>
                    </Stack>
                </SubTaskButton>
                <CloseButton onClick={props.handleRemoveSubTaskAt}/>
            </Stack>
            <Divider/>
        </>
    )
}


// ----------------------------------------------------------------------

interface Props {
    steps: EventStepProps[] | undefined
    setSteps: (subTasks: EventStepProps[] | undefined) => void
}

export default function SubTasksLayout(props: Props) {
    const theme = useTheme()

    /* Translation */
    const {t} = useTranslation()

    const [subTaskAddTitle, setSubTaskAddTitle] = React.useState('');
    const [subTaskChangeTitle, setSubTaskChangeTitle] = React.useState('');
    const [addSubTaskUiVisible, setAddSubTaskUiVisible] = React.useState(false);
    const [changeSubTaskUiVisibleAtIndex, setChangeSubTaskUiVisibleAtIndex] = React.useState(-1)

    const handleOnAddSubTaskClick = () => {
        setChangeSubTaskUiVisibleAtIndex(-1)
        setAddSubTaskUiVisible(true)
    }

    const handleOnAddSubTaskCancelClick = () => {
        setAddSubTaskUiVisible(false)
    }

    const handleOnAddSubTaskSaveClick = () => {
        if (subTaskAddTitle) {
            const newSubTasks = [...props.steps || []]      // clone array for changes to take effect
            newSubTasks.push({title: subTaskAddTitle, completed_on: undefined})
            props.setSteps(newSubTasks)
            setSubTaskAddTitle("")  // reset
            setAddSubTaskUiVisible(false)
        }
    }

    const handleOnChangeSubTaskSaveClick = () => {
        const subTaskAtIndex = props.steps?.at(changeSubTaskUiVisibleAtIndex)
        if (subTaskAtIndex) {
            if (subTaskChangeTitle) {
                subTaskAtIndex.title = subTaskChangeTitle
            }
            props.setSteps(
                props.steps?.map((value, index) => {
                    return index === changeSubTaskUiVisibleAtIndex ? subTaskAtIndex : value
                })
            )
            setChangeSubTaskUiVisibleAtIndex(-1)
        }
    }

    const handleRemoveSubTaskAt = (i: number) => {
        props.setSteps(
            props.steps?.filter((value, index) => index !== i)
        )
    }

    const handleOnToggleSubTaskCompletedOn = (i: number) => {
        const subTaskAtIndex = props.steps?.at(i)
        if (subTaskAtIndex) {
            if (subTaskAtIndex.completed_on) {
                subTaskAtIndex.completed_on = undefined
            } else {
                subTaskAtIndex.completed_on = moment().format()
            }
            props.setSteps(
                props.steps?.map((value, index) => index === i ? subTaskAtIndex : value)
            )
        }
    }

    const isEditing = (index: number) => changeSubTaskUiVisibleAtIndex === index

    return (
        <Stack direction='column'>
            {
                props.steps?.map((subTask, index) => {
                    return (
                        isEditing(index) ?
                            <Stack
                                key={index}
                                direction='column'
                                mt={1}
                                style={{border: `1px solid ${theme.palette.divider}`, borderRadius: '8px'}}
                            >
                                <SubTaskTextArea
                                    placeholder={t("task_dialog.add_sub_task") || undefined}
                                    value={subTaskChangeTitle}
                                    onChange={(event: React.ChangeEvent) => {
                                        // @ts-ignore
                                        setSubTaskChangeTitle(event.target.value)
                                    }}
                                />
                                <Stack direction='row-reverse' pb={1} pr={2} pl={2}>
                                    <Button
                                        variant='text'
                                        disabled={!subTaskChangeTitle}
                                        onClick={handleOnChangeSubTaskSaveClick}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        color='inherit'
                                        variant='text'
                                        onClick={() => setChangeSubTaskUiVisibleAtIndex(-1)}
                                    >
                                        Cancel
                                    </Button>
                                </Stack>
                            </Stack>
                            : <SubTaskRow
                                key={index}
                                subTask={subTask}
                                handleOnToggleSubTaskCompletedOn={() => handleOnToggleSubTaskCompletedOn(index)}
                                setChangeSubTaskUiVisibleAtIndex={() => {
                                    setSubTaskChangeTitle(subTask.title)
                                    setChangeSubTaskUiVisibleAtIndex(index)
                                }}
                                handleRemoveSubTaskAt={() => handleRemoveSubTaskAt(index)}
                            />

                    )
                })
            }
            {
                addSubTaskUiVisible &&
                <Stack
                    direction='column'
                    mt={2}
                    style={{border: `1px solid ${theme.palette.divider}`, borderRadius: '8px'}}
                >
                    <SubTaskTextArea
                        placeholder={t("task_dialog.add_sub_task") || undefined}
                        onChange={(event: React.ChangeEvent) => {
                            // @ts-ignore
                            setSubTaskAddTitle(event.target.value)
                        }}
                    />
                    <Stack direction='row-reverse' spacing={1.5} pb={1} pr={2} pl={2}>
                        <SaveButton
                            handleSaveClick={handleOnAddSubTaskSaveClick}
                            isSaving={false}
                            disabled={!subTaskAddTitle}
                            text={t("save")}
                        />
                        <Button
                            onClick={handleOnAddSubTaskCancelClick}
                            variant='contained'
                            color='inherit'
                            sx={{boxShadow: 'none'}}
                        >
                            {t("cancel")}
                        </Button>
                    </Stack>
                </Stack>
            }
            {
                !addSubTaskUiVisible &&
                <Button
                    onClick={handleOnAddSubTaskClick}
                    color='inherit'
                    sx={{width: '100%', height: '48px'}}
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        sx={{width: '100%'}}
                    >
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:add"
                            color={theme.palette.text.secondary}
                            width={18}
                            height={18}
                            sx={{flexShrink: 0}}
                        />
                        <Typography
                            variant='body2'
                            noWrap={true}
                            color={theme.palette.text.secondary}
                            ml={2}
                        >
                            {t("task_dialog.add_sub_task")}
                        </Typography>
                    </Stack>
                </Button>
            }
        </Stack>
    )
}