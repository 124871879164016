// @mui
import {styled, useTheme} from '@mui/material/styles';
import {AppBar, Box, IconButton, Stack, Toolbar} from '@mui/material';
// utils
// components
import Iconify from '../../../common/components/iconify';
//
import AccountPopover from './AccountPopover';
import * as React from "react";
import LanguagePopover from "./LanguagePopover";

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;
const HEADER_MOBILE = 48;
const HEADER_DESKTOP = 48;
const MENU_BUTTON_SIZE = 42;

// ----------------------------------------------------------------------

// @ts-ignore
const StyledRoot = styled(AppBar, {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    flex: 1,
    width: 'auto',
    left: 0,
    [theme.breakpoints.up('md')]: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${NAV_WIDTH}px`,
        })
    },
}));

const StyledToolbar = styled(Toolbar, {shouldForwardProp: (prop) => prop !== 'open'})<{
    open?: boolean;
}>(({theme, open}) => ({
    minHeight: HEADER_MOBILE + 'px !important',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
        minHeight: HEADER_DESKTOP + 'px !important',
    },
}));

// ----------------------------------------------------------------------

export default function Header(
    props: {
        open: boolean,
        onOpenNav: () => void
    }
) {
    const theme = useTheme()
    const className = 'webkit-drag' + (props.open ? ' open' : '')

    return (
        <StyledRoot className={className} open={props.open}>
            <StyledToolbar open={props.open}>
                <IconButton
                    onClick={props.onOpenNav}
                    className={'webkit-no-drag'}
                    sx={{width: MENU_BUTTON_SIZE + 'px', height: MENU_BUTTON_SIZE + 'px', mr: 1}}
                >
                    {
                        // @ts-ignore
                        <Iconify icon="material-symbols:menu-rounded" color={theme.palette.text.secondary} width={28} height={28}/>
                    }
                </IconButton>

                <Box sx={{flexGrow: 1}}/>

                <Stack direction="row" alignItems="center">
                    <AccountPopover/>
                </Stack>
            </StyledToolbar>
        </StyledRoot>
    );
}
