import {Button, Popover, Stack, Typography} from "@mui/material";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Iconify from "../../../../common/components/iconify";
import {COLOR_DEFAULT, colorToHexColor} from "../../../../utils/color";
import IconButton from "@mui/material/IconButton";
import TaskRowPopover from "../../../task/components/TaskRowPopover";
import Divider from "@mui/material/Divider";
import {Timetable} from "../../../../models/Timetable";

const BUTTON_HEIGHT = '40px'
const POPOVER_MIN_WIDTH = '192px'

interface Props {
    id: string | undefined
    timetables: Timetable[] | undefined | null
    open: boolean
    anchorEl: Element | null | undefined
    handleClose: () => void
    handleOnTimetableAdd: () => void
    handleOnTimetableUpdate: (timetableId: string) => void
    handleOnTimetableDelete: (timetableId: string) => void
    handleOnTimetableSelect: (timetableId: string | undefined | null) => void
}

export default function TimetablePopover(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    const [selectedTimetableId, setSelectedTimetableId] = React.useState<string | undefined | null>(undefined)
    const [rowAnchorEl, setRowAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const rowOpen = Boolean(rowAnchorEl) && Boolean(selectedTimetableId);
    const popoverRowId = rowOpen ? 'TimetableRowPopover' : undefined;

    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Stack direction='column' pt={1} sx={{minWidth: POPOVER_MIN_WIDTH}}>
                {
                    props.timetables?.map(timetable => (
                        <Stack
                            direction={'row'}
                            key={timetable?._id}
                            sx={{width: '100%', px: 1, height: BUTTON_HEIGHT}}
                        >
                            <Button
                                color='inherit'
                                onClick={() => props.handleOnTimetableSelect(timetable?._id)}
                                sx={{flex: 1, pr: 2}}
                            >
                                <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                                    <Iconify
                                        // @ts-ignore
                                        icon="material-symbols:circle"
                                        color={timetable?.color ? colorToHexColor(timetable?.color) : COLOR_DEFAULT}
                                        width={14}
                                        height={14}
                                    />
                                    <Typography
                                        variant='body2'
                                        color={theme.palette.text.primary}
                                        sx={{marginLeft: '12px', textTransform: 'none'}}
                                    >
                                        {timetable?.title}
                                    </Typography>
                                </Stack>
                            </Button>
                            <IconButton
                                onClick={(event) => {
                                    event.stopPropagation()
                                    setSelectedTimetableId(timetable?._id)
                                    setRowAnchorEl(event.currentTarget)
                                }}
                                sx={{
                                    flex: 0,
                                    width: '38px',
                                    height: '38px',
                                    color: theme.palette.text.secondary
                                }}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon="carbon:overflow-menu-horizontal"
                                    width={24}
                                    height={24}
                                />
                            </IconButton>
                        </Stack>
                    ))
                }
                {
                    (props.timetables?.length ?? 0) > 0 && <Divider sx={{mt: 0.75}}/>
                }
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    sx={{width: '100%', px: 1, pb: 1, pt: 0.75, backgroundColor: '#fbfbfa'}}
                >
                    <Button
                        onClick={props.handleOnTimetableAdd}
                        color='inherit'
                        sx={{width: '100%', height: BUTTON_HEIGHT}}
                    >
                        <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                            <Iconify
                                // @ts-ignore
                                icon="material-symbols:add"
                                color={theme.palette.text.secondary}
                                width={18}
                                height={18}
                            />
                            <Typography
                                variant='body2'
                                color={theme.palette.text.secondary}
                                sx={{marginLeft: '12px'}}
                            >
                                {t('timetable.selector.popover.add')}
                            </Typography>
                        </Stack>
                    </Button>
                </Stack>
            </Stack>

            <TaskRowPopover
                id={popoverRowId}
                open={rowOpen}
                anchorEl={rowAnchorEl}
                handleClose={() => setRowAnchorEl(null)}
                handleOnUpdate={() => {
                    setRowAnchorEl(null)
                    if (selectedTimetableId) {
                        props.handleOnTimetableUpdate(selectedTimetableId)
                    }
                }}
                handleOnDelete={() => {
                    setRowAnchorEl(null)
                    if (selectedTimetableId) {
                        props.handleOnTimetableDelete(selectedTimetableId)
                    }
                }}
            />
        </Popover>
    )
}