import React, {useEffect, useState} from 'react';
import {styled} from "@mui/material/styles";
import {Alert, Button, Collapse, Container, Link, Stack, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import useResponsive from '../hooks/useResponsive';
import {useAuthToken} from "../hooks/AuthToken";
import {firebaseAuth, googleAuthProvider} from "../App";
import {useNavigate} from "react-router-dom";
import Logo from "../common/components/Logo";
import {GOOGLE_PLAY_APP_URL, MAC_OS_ELECTRON_LATEST_URL, WINDOWS_ELECTRON_LATEST_URL} from "../common/Constants";
import {useTranslation} from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Iconify from "../common/components/iconify";
import {useApp} from "@realm/react";
import {Credentials} from "realm";
import {getAuth, OAuthProvider, signInWithPopup} from "firebase/auth";

// ----------------------------------------------------------------------
const StyledSection = styled('div')(({theme}) => ({}));

const StyledContent = styled('div')(({theme}) => ({}));
styled('img')(({theme}) => ({
    height: "80px",
    objectFit: 'contain'
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
    const atlasApp = useApp();

    const navigate = useNavigate()
    const {t} = useTranslation()

    const mdUp = useResponsive('up', 'md')

    const [authToken, setAuthToken] = useAuthToken()

    useEffect(() => {
        const login = async (jwtToken: string) => {
            if (atlasApp.currentUser) {
                await atlasApp.currentUser.logOut()
            }

            const credentials = Credentials.jwt(jwtToken)
            try {
                await atlasApp.logIn(credentials)
                setAuthToken(authToken)
                navigate('/app/agenda', {replace: true})
            } catch (reason) {
                console.error(reason)
            }
        }

        if (authToken) {
            setErrorState(false)
            login(authToken)
        }

        return () => {
            // cleanup
        }
    }, [authToken, navigate, setAuthToken]);

    const signInWithGoogle = async () => {
        try {
            const credential = await signInWithPopup(firebaseAuth, googleAuthProvider);
            const token = await credential.user.getIdToken()
            setAuthToken(token)
        } catch (err) {
            console.error(err);
        }
    };

    const signInWithApple = async () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');

        try {
            const auth = getAuth();
            const result = await signInWithPopup(auth, provider);
            const token = await result.user.getIdToken();
            setAuthToken(token);
        } catch (error) {
            console.error(error)
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // state
    const [errorState, setErrorState] = useState(false);

    return (
        <>
            <Container maxWidth={mdUp ? "md" : "xs"}>
                <Stack direction={"row"} sx={{pt: 2, pb: 5}}>
                    <Logo linkTo={'/'}/>
                </Stack>

                <Stack direction={"row"}>
                    <StyledContent sx={{flex: 1, mt: mdUp ? 8 : 2, mb: 5}}>
                        <Typography variant="h3" gutterBottom>
                            {t('login.sign_in')}
                        </Typography>

                        <Typography variant="body2" sx={{mb: 5}}>
                            {t("login.dont_have_account")}
                            <Link
                                href={GOOGLE_PLAY_APP_URL}
                                variant="subtitle2"
                                sx={{ml: 1}}
                            >
                                {t("login.get_started")}
                            </Link>
                        </Typography>

                        <Collapse in={errorState}>
                            <Alert variant="filled" severity="error" sx={{mt: 3, mb: 3}}>
                                {t("login.sign_in_failed")}
                            </Alert>
                        </Collapse>

                        <Stack
                            id={'google-login-button-container'}
                            direction="column"
                            alignItems={"center"}
                            spacing={1}
                        >
                            <Button
                                variant="outlined"
                                color={'inherit'}
                                onClick={signInWithGoogle}
                                fullWidth={true}
                                size={'large'}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon={'flat-color-icons:google'}
                                    width={20}
                                    height={20}
                                />
                                <Typography
                                    variant={"subtitle2"}
                                    sx={{ml: 1}}
                                >
                                    Continue With Google
                                </Typography>
                            </Button>

                            <Button
                                variant="outlined"
                                color={'inherit'}
                                onClick={signInWithApple}
                                fullWidth={true}
                                size={'large'}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon={'ic:baseline-apple'}
                                    width={20}
                                    height={20}
                                />
                                <Typography
                                    variant="subtitle2"
                                    sx={{ml: 1}}
                                >
                                    Continue With Apple
                                </Typography>
                            </Button>
                        </Stack>

                        <Divider sx={{mt: 3, mb: 2}}/>

                        <Stack
                            direction="row"
                            alignItems={"center"}
                        >
                            <IconButton
                                onClick={() => {
                                    window.open(GOOGLE_PLAY_APP_URL, '_blank', 'noreferrer')
                                }}
                                sx={{
                                    width: 48,
                                    height: 48
                                }}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon={'ph:android-logo-fill'}
                                    width={24}
                                    height={24}
                                    color={'#65db84'}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(MAC_OS_ELECTRON_LATEST_URL, '_blank', 'noreferrer')
                                }}
                                sx={{
                                    width: 48,
                                    height: 48,
                                    ml: 2
                                }}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon={'mdi:apple'}
                                    width={24}
                                    height={24}
                                    color={'#8c8c8c'}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    window.open(WINDOWS_ELECTRON_LATEST_URL, '_blank', 'noreferrer')
                                }}
                                sx={{
                                    width: 48,
                                    height: 48,
                                    ml: 2
                                }}
                            >
                                <Iconify
                                    // @ts-ignore
                                    icon={'simple-icons:windows11'}
                                    width={18}
                                    height={18}
                                    color={'#3376cc'}
                                />
                            </IconButton>
                        </Stack>
                    </StyledContent>

                    {mdUp && (
                        <StyledSection sx={{flex: 1, mt: 10, mb: 5}}>
                            <img src="/assets/illustrations/illustration_login.png" alt="login"/>
                        </StyledSection>
                    )}
                </Stack>
            </Container>
        </>
    )
}
