import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Iconify from "../../../common/components/iconify";
import {
    Button,
    DialogActions,
    IconButton,
    MenuItem,
    Popover,
    Select,
    SelectChangeEvent,
    Stack,
    TextareaAutosize,
    TextField,
    Typography
} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import moment from "moment/moment";
import {MobileDateTimePicker, StaticDatePicker} from "@mui/x-date-pickers";
import SubjectPopover from "../../../common/components/SubjectPopover";
import SubTasks from "./components/SubTasksLayout";
import useMediaQuery from "@mui/material/useMediaQuery";
import SubjectButton from "./components/SubjectButton";
import DateButton from "./components/DateButton";
import FormControl from "@mui/material/FormControl";
import CategoryButton from "./components/CategoryButton";
import CategoryPopover from "./components/CategoryPopover";
import {Category} from "../../../model/ExamModel";
import ColorPopover from "../../../common/components/color-popover";
import ColorButton from "./components/ColorButton";
import {COLOR_DEFAULT, colorToSigned24Bit} from "../../../utils/color";
import SubjectCommitDialog, {SubjectDialogUpdateOptionsProps} from "./SubjectCommitDialog";
import {useTranslation} from "react-i18next";
import RemindPopover from "../components/RemindPopover";
import RemindAtButton from "./components/RemindAtButton";
import {BootstrapDialog, Transition} from "../../../common/components/BootstrapDialog";
import {rgba} from "polished";
import SaveButton from "../../../common/components/SaveButton";
import {useHomeworkManager} from "../../../hooks/useHomeworkManager";
import {MONGO_DATE_FORMAT, MONGO_DATE_TIME_FORMAT} from "../../../utils/date";
import {EventRemindAtProps} from "../../../models/EventRemindAt";
import {EventStepProps} from "../../../models/EventStep";
import {useExamManager} from "../../../hooks/useExamManager";
import {useReminderManager} from "../../../hooks/useReminderManager";
import {useSubjectManager} from "../../../hooks/useSubjectManager";

// =====================================================================================================================
export const HOMEWORK_VALUE = "homework"
export const EXAM_VALUE = "exam"
export const REMINDER_VALUE = "reminder"

const TASK_OPTIONS = [
    {value: HOMEWORK_VALUE, label: 'task_dialog.homework', icon: 'material-symbols:book-outline-rounded'},
    {value: EXAM_VALUE, label: 'task_dialog.exam', icon: 'material-symbols:book-outline-rounded'},
    {value: REMINDER_VALUE, label: 'task_dialog.reminder', icon: 'material-symbols:book-outline-rounded'}
];

// =====================================================================================================================
const TitleTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    margin: '5px 0px 4px 2px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600
}));

const NoteTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    margin: '0px 16px',
    paddingTop: '12px',
    paddingBottom: '12px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    minHeight: '48px',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight
}));

type CloseButtonProps = {
    onClick: () => void
};

export const CloseButton = ({onClick}: CloseButtonProps) => {
    const theme = useTheme()

    return (
        <IconButton
            onClick={onClick}
            sx={{
                width: '32px',
                height: '32px',
                color: theme.palette.text.secondary
            }}
        >
            <Iconify
                // @ts-ignore
                icon="material-symbols:close"
                width={18}
                height={18}
            />
        </IconButton>
    )
}

type SubHeaderProps = {
    header: string
}
const SubHeader = (props: SubHeaderProps) => {
    const theme = useTheme()

    return (
        <>
            <Typography
                variant='body2'
                fontWeight={'700'}
                noWrap={true}
                color={theme.palette.text.disabled}
                sx={{mt: 2, ml: 1}}
            >
                {props.header}
            </Typography>

            <Divider sx={{mt: 1}}/>
        </>
    )
}

// =====================================================================================================================
export interface TaskDialogInsertOptionsProps {
    date?: moment.Moment | undefined | null
}

export interface TaskDialogUpdateOptionsProps {
    task: string
    taskId: string
    title?: string | undefined
    date?: moment.Moment | undefined
    remindAt?: EventRemindAtProps | undefined
    completedOn?: moment.Moment | undefined
    category?: Category | undefined
    color?: string | undefined
    selectedSubjectId?: string | undefined
    steps?: EventStepProps[] | undefined
    note?: string | undefined
}

interface Props {
    open: boolean
    handleClose: () => void
    selectedPlannerId: string
    insertOptions?: TaskDialogInsertOptionsProps | undefined
    updateOptions?: TaskDialogUpdateOptionsProps | undefined
}

// =====================================================================================================================
export default function TaskDialog(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        createHomework,
        updateHomework
    } = useHomeworkManager()

    const {
        createExam,
        updateExam
    } = useExamManager()

    const {
        createReminder,
        updateReminder
    } = useReminderManager()

    const {
        subjects,
        deleteSubject
    } = useSubjectManager()

    const subjectsByPlanner = subjects.filter(it => it.planner?._id === props.selectedPlannerId)

    // -----------------------------------------------------------------------------------------------------------------
    // State [Model]
    const [
        title,
        setTitle
    ] = React.useState<string | undefined | null>(undefined);

    if (title === undefined && props.updateOptions?.title) {
        setTitle(props.updateOptions.title)
    }

    const [
        date,
        setDate
    ] = React.useState<moment.Moment | undefined | null>(undefined);

    if (date === undefined) {
        if (props.updateOptions?.date) {
            setDate(props.updateOptions.date)
        } else if (props.insertOptions?.date) {
            setDate(props.insertOptions.date)
        }
    }

    const [
        remindAt,
        setRemindAt
    ] = React.useState<EventRemindAtProps | null | undefined>(undefined);
    if (remindAt === undefined && props.updateOptions?.remindAt) {
        setRemindAt(props.updateOptions.remindAt)
    }

    const [
        completedOn,
        setCompletedOn
    ] = React.useState<moment.Moment | undefined | null>(undefined)

    if (completedOn === undefined && props.updateOptions?.completedOn) {
        setCompletedOn(props.updateOptions.completedOn)
    }

    const [
        category,
        setCategory
    ] = React.useState<Category | null | undefined>(undefined);

    if (category === undefined && props.updateOptions?.category) {
        setCategory(props.updateOptions?.category)
    }

    const [
        color,
        setColor
    ] = React.useState<string | undefined | null>(undefined);

    if (color === undefined && props.updateOptions?.color) {
        setColor(props.updateOptions?.color)
    }

    const [
        selectedSubjectId,
        setSelectedSubjectId
    ] = React.useState<string | undefined | null>();

    if (selectedSubjectId === undefined && props.updateOptions?.selectedSubjectId) {
        setSelectedSubjectId(props.updateOptions.selectedSubjectId)
    }

    const [
        steps,
        setSteps
    ] = React.useState<EventStepProps[] | undefined | null>(undefined);
    if (steps === undefined && props.updateOptions?.steps) {
        setSteps(props.updateOptions.steps)
    }

    const [
        note,
        setNote
    ] = React.useState<string | undefined | null>(undefined);

    if (note === undefined && props.updateOptions?.note) {
        setNote(props.updateOptions.note)
    }

    // -----------------------------------------------------------------------------------------------------------------
    // State [UI]
    const [taskTabState, setTaskTab] = React.useState(TASK_OPTIONS[0].value);
    const taskTab = props.updateOptions?.task || taskTabState

    const [subjectDialogOpen, setSubjectDialogOpen] = React.useState(false)
    const [dateAnchorEl, setDateAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [subjectAnchorEl, setSubjectAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [categoryAnchorEl, setCategoryAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [colorAnchorEl, setColorAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [remindAnchorEl, setRemindAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const [remindDialogOpen, setRemindDialogOpen] = React.useState(false)

    const [subjectDialogUpdateOptions, setSubjectDialogUpdateOptions] =
        useState<SubjectDialogUpdateOptionsProps | undefined | null>(null)

    // -----------------------------------------------------------------------------------------------------------------
    const selectedSubject = subjectsByPlanner?.find(it => it?._id === selectedSubjectId)

    // -----------------------------------------------------------------------------------------------------------------
    // Const [UI]
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const dateOpen = Boolean(dateAnchorEl);
    const popoverDateId = dateOpen ? 'date-popover' : undefined;
    const subjectOpen = Boolean(subjectAnchorEl);
    const popoverSubjectId = subjectOpen ? 'TimetablePopover' : undefined;
    const categoryOpen = Boolean(categoryAnchorEl);
    const popoverCategoryId = categoryOpen ? 'popover' : undefined;
    const colorOpen = Boolean(colorAnchorEl);
    const popoverColorId = colorOpen ? 'color-popover' : undefined;
    const remindOpen = Boolean(remindAnchorEl);
    const popoverRemindId = colorOpen ? 'remind-popover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    // Callbacks
    const handleClose = () => {
        setTitle(undefined)
        setDate(undefined)
        setCompletedOn(undefined)
        setCategory(undefined)
        setColor(undefined)
        setSelectedSubjectId(undefined)
        setSteps(undefined)
        setRemindAt(undefined)
        setNote(undefined)

        props.handleClose()
    }


    // -----------------------------------------------------------------------------------------------------------------
    const createHomeworkCallback = () => {
        if (!title || !date) return

        const isRemindAtValid = (remindAt?.date?.isValid() && remindAt?.time?.isValid()) ?? false

        createHomework({
            planner_id: props.selectedPlannerId,
            subject_id: selectedSubjectId ?? undefined,
            title: title,
            ...(date) && {date: date.format(MONGO_DATE_FORMAT)},
            archived: false,
            created_on: moment().format(MONGO_DATE_TIME_FORMAT),
            steps: steps ?? undefined,
            ...(isRemindAtValid) && {remind_at: remindAt ?? undefined},
            ...(note) && {note: note}
        })

        handleClose()
    }

    const updateHomeworkCallback = () => {
        if (!props.updateOptions || !title || !date) return

        const completedOnAsString = completedOn?.isValid() ? completedOn?.toISOString() : undefined
        const isRemindAtValid = (remindAt?.date?.isValid() && remindAt?.time?.isValid()) ?? false

        updateHomework(props.updateOptions.taskId, {
            subject_id: selectedSubjectId ?? undefined,
            title: title,
            ...(date) && {date: date.format(MONGO_DATE_FORMAT)},
            ...(completedOnAsString) && {completed_on: completedOnAsString},
            archived: false,
            steps: steps ?? undefined,
            ...(isRemindAtValid) && {remind_at: remindAt ?? undefined},
            ...(note) && {note: note}
        })

        handleClose()
    }

    const createExamCallback = () => {
        if (!title || !date) return

        const isRemindAtValid = (remindAt?.date?.isValid() && remindAt?.time?.isValid()) ?? false

        createExam({
            planner_id: props.selectedPlannerId,
            subject_id: selectedSubjectId ?? undefined,
            title: title,
            ...(date) && {date: date.format(MONGO_DATE_FORMAT)},
            category: category ?? undefined,
            archived: false,
            created_on: moment().format(MONGO_DATE_TIME_FORMAT),
            steps: steps ?? undefined,
            ...(isRemindAtValid) && {remind_at: remindAt ?? undefined},
            ...(note) && {note: note}
        })

        handleClose()
    }

    const updateExamCallback = () => {
        if (!props.updateOptions || !title || !date) return

        const isRemindAtValid = (remindAt?.date?.isValid() && remindAt?.time?.isValid()) ?? false

        updateExam(props.updateOptions.taskId, {
            subject_id: selectedSubjectId ?? undefined,
            title: title,
            ...(date) && {date: date.format(MONGO_DATE_FORMAT)},
            category: category ?? undefined,
            archived: false,
            steps: steps ?? undefined,
            ...(isRemindAtValid) && {remind_at: remindAt ?? undefined},
            ...(note) && {note: note}
        })

        handleClose()
    }

    const createReminderCallback = () => {
        if (!title || !date) return

        const isRemindAtValid = (remindAt?.date?.isValid() && remindAt?.time?.isValid()) ?? false

        createReminder({
            planner_id: props.selectedPlannerId,
            title: title,
            ...(date) && {date: date.format(MONGO_DATE_FORMAT)},
            ...(color) && {color: colorToSigned24Bit(color) ?? COLOR_DEFAULT},
            archived: false,
            created_on: moment().format(MONGO_DATE_TIME_FORMAT),
            steps: steps ?? undefined,
            ...(isRemindAtValid) && {remind_at: remindAt ?? undefined},
            ...(note) && {note: note}
        })

        handleClose()
    }

    const updateReminderCallback = () => {
        if (!props.updateOptions || !title || !date) return

        const completedOnAsString = completedOn?.isValid() ? completedOn?.format(MONGO_DATE_TIME_FORMAT) : undefined
        const isRemindAtValid = (remindAt?.date?.isValid() && remindAt?.time?.isValid()) ?? false

        updateReminder(props.updateOptions.taskId, {
            title: title,
            ...(date) && {date: date.format(MONGO_DATE_FORMAT)},
            ...(completedOnAsString) && {completed_on: completedOnAsString},
            ...(color) && {color: colorToSigned24Bit(color) ?? COLOR_DEFAULT},
            archived: false,
            steps: steps ?? undefined,
            ...(isRemindAtValid) && {remind_at: remindAt ?? undefined},
            ...(note) && {note: note}
        })

        handleClose()
    }

    const handleSaveClick = () => {
        if (props.updateOptions?.task === HOMEWORK_VALUE) {
            updateHomeworkCallback()
            return
        }

        if (props.updateOptions?.task === EXAM_VALUE) {
            updateExamCallback()
            return
        }

        if (props.updateOptions?.task === REMINDER_VALUE) {
            updateReminderCallback()
            return
        }

        if (taskTab === HOMEWORK_VALUE) {
            createHomeworkCallback()
        } else if (taskTab === EXAM_VALUE) {
            createExamCallback()
        } else if (taskTab === REMINDER_VALUE) {
            createReminderCallback()
        }
    }

    const handleToggleCompletedOn = () => {
        if (completedOn) {
            setCompletedOn(null)
        } else {
            setCompletedOn(moment())
        }
    }


    // -----------------------------------------------------------------------------------------------------------------
    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            open={props.open}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{
                    py: props.updateOptions ? 1.5 : 0.5,
                    pl: props.updateOptions ? 3 : 1.5,
                    pr: 2,
                    backgroundColor: theme.palette.primary.main
                }}
            >
                {
                    !props.updateOptions ? <FormControl
                        size="small"
                        sx={{m: 1, flex: 0, minWidth: 180}}
                    >
                        <Select
                            labelId="task-option-select-small"
                            id="task-option-select-small"
                            value={taskTab}
                            onChange={(event: SelectChangeEvent) => setTaskTab(event.target.value)}
                            sx={{
                                "& .MuiSelect-select": {
                                    color: rgba(theme.palette.primary.contrastText, 1.0)
                                },
                                "& .MuiSvgIcon-root": {
                                    color: rgba(theme.palette.primary.contrastText, 1.0),
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    color: rgba(theme.palette.primary.contrastText, 1.0),
                                    borderWidth: '1px !important',
                                    backgroundColor: rgba(theme.palette.primary.contrastText, 0.1) + " !important",
                                    borderColor: rgba(theme.palette.primary.contrastText, 0.2) + " !important",
                                },
                            }}
                        >
                            {
                                TASK_OPTIONS.map((value) => {
                                    return (
                                        <MenuItem key={value.value} value={value.value}>
                                            <Typography variant='body2'>
                                                {t(value.label)}
                                            </Typography>
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl> : <div style={{flex: 1}}></div>
                }

                <IconButton onClick={handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <Divider/>

            <DialogContent>
                <Stack direction="row" mb={1}>
                    {(taskTab === HOMEWORK_VALUE || taskTab === REMINDER_VALUE) &&
                        <Box>
                            <IconButton onClick={handleToggleCompletedOn}>
                                <Iconify
                                    // @ts-ignore
                                    icon={
                                        completedOn ?
                                            "material-symbols:check-circle-rounded" :
                                            "material-symbols:circle-outline"
                                    }
                                    color={
                                        completedOn ? theme.palette.primary.main : theme.palette.text.secondary
                                    }
                                    width={24}
                                    height={24}
                                />
                            </IconButton>
                        </Box>
                    }

                    <TitleTextArea
                        placeholder={t("task_dialog.add_title") || undefined}
                        value={title || ''}
                        sx={{mt: 0.8}}
                        onChange={(event: React.ChangeEvent) => {
                            // @ts-ignore
                            setTitle(event.target.value)
                        }}
                    />
                </Stack>

                <DateButton
                    date={date}
                    clearDate={() => setDate(null)}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setDateAnchorEl(event.currentTarget);
                    }}
                />

                <Divider/>

                <RemindAtButton
                    remindAt={remindAt ?? undefined}
                    clearRemindAt={() => {
                        setRemindAt(null)
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        setRemindAnchorEl(event.currentTarget);
                    }}
                />

                <Divider/>

                {(taskTab === HOMEWORK_VALUE || taskTab === EXAM_VALUE) &&
                    <SubjectButton
                        selectedSubjectId={selectedSubjectId}
                        name={selectedSubject?.name}
                        color={selectedSubject?.color}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setSubjectAnchorEl(event.currentTarget);
                        }}
                        clearSelectedSubjectId={() => setSelectedSubjectId(null)}/>
                }

                {taskTab === EXAM_VALUE &&
                    <>
                        <Divider/>
                        <CategoryButton
                            category={category}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                setCategoryAnchorEl(event.currentTarget)
                            }}
                            clearCategory={() => setCategory(null)}/>
                    </>
                }

                {taskTab === REMINDER_VALUE &&
                    <ColorButton
                        color={color || COLOR_DEFAULT}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setColorAnchorEl(event.currentTarget)
                        }}/>
                }

                <SubHeader header={t("task_dialog.sub_tasks")}/>

                <SubTasks
                    steps={steps ?? undefined}
                    setSteps={it => setSteps(it)}
                />

                <Stack
                    direction="row"
                    mt={3}
                    style={{
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: '8px'
                    }}
                >
                    <NoteTextArea
                        placeholder={t('task_dialog.add_note') || ''}
                        value={note || ''}
                        onChange={(event: React.ChangeEvent) => {
                            // @ts-ignore
                            setNote(event.target.value)
                        }}
                    />
                </Stack>

            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={1.5}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <SaveButton
                        handleSaveClick={handleSaveClick}
                        isSaving={false}
                        disabled={!date || !title}
                        text={t("save")}
                    />
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='inherit'
                        sx={{boxShadow: 'none'}}
                    >
                        {t("cancel")}
                    </Button>
                </Stack>
            </DialogActions>

            <Popover
                id={popoverDateId}
                open={dateOpen}
                anchorEl={dateAnchorEl}
                onClose={() => setDateAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={date}
                    onChange={(newDate: moment.Moment | null) => {
                        setDate(newDate)
                        setDateAnchorEl(null)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Popover>

            <SubjectPopover
                id={popoverSubjectId}
                subjects={subjectsByPlanner}
                open={subjectOpen}
                anchorEl={subjectAnchorEl}
                handleClose={() =>
                    setSubjectAnchorEl(null)
                }
                handleOnSubjectAdd={() => {
                    setSubjectAnchorEl(null)
                    setSubjectDialogOpen(true)
                }}
                handleOnSubjectUpdate={(subjectId: string) => {
                    setSubjectAnchorEl(null)

                    const subject = subjects?.find(it => it._id === subjectId)
                    if (subject) {
                        setSubjectDialogUpdateOptions({subject: subject});
                        setSubjectDialogOpen(true)
                    }
                }}
                handleOnSubjectSelect={(subjectId: string | undefined) => {
                    setSubjectAnchorEl(null)
                    setSelectedSubjectId(subjectId ? subjectId : null)
                }}
                handleOnSubjectDelete={(subjectId: string) => {
                    setSubjectAnchorEl(null)
                    setSelectedSubjectId(null)

                    deleteSubject(subjectId);
                }}
            />

            <CategoryPopover
                id={popoverCategoryId}
                open={categoryOpen}
                anchorEl={categoryAnchorEl}
                handleClose={() => setCategoryAnchorEl(null)}
                handleOnCategorySelect={(category: Category | null | undefined) => {
                    setCategoryAnchorEl(null)
                    setCategory(category)
                }}/>

            <ColorPopover
                id={popoverColorId}
                color={color || COLOR_DEFAULT}
                open={colorOpen}
                anchorEl={colorAnchorEl}
                handleClose={() => setColorAnchorEl(null)}
                onChange={(color) => setColor(color.hex)}/>

            <SubjectCommitDialog
                plannerId={props.selectedPlannerId}
                open={subjectDialogOpen}
                updateOptions={subjectDialogUpdateOptions}
                handleClose={() => {
                    setSubjectDialogOpen(false)
                    setSubjectDialogUpdateOptions(null)
                }}
            />

            <RemindPopover
                id={popoverRemindId}
                open={remindOpen}
                anchorEl={remindAnchorEl}
                handleClose={() => setRemindAnchorEl(null)}
                handleOnRemindAtSelect={(date, time) => {
                    setRemindAnchorEl(null)
                    setRemindAt({date: date, time: time})
                }}
                handleOnPickDateTime={() => {
                    setRemindAnchorEl(null)
                    setRemindDialogOpen(true)
                }}
            />

            <MobileDateTimePicker
                open={remindDialogOpen}
                onClose={() => setRemindDialogOpen(false)}
                value={remindAt?.date ?? moment()}
                onChange={(value) => {
                    if (value && value.isValid()) {
                        setRemindAt({date: value, time: value})
                    }
                }}
                renderInput={() => <></>}
            />

        </BootstrapDialog>
    );
}