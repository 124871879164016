import Container from '../../../common/components/UI/Container';
import React from 'react';
import Fade from 'react-reveal/Fade';
import Section, {Content, Features, Figure, Grid} from './analytics.style';
import Box from "@mui/material/Box";
import {Stack, Typography} from "@mui/material";
import {Content as RightContent, Figure as RightFigure, Grid as RightGrid, SectionHeading,} from "./technology.style";
import Iconify from "../../../common/components/iconify";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

const AboutLeft = (props) => {
    const theme = useTheme()

    return (
        <Container width="1024px">
            <Grid>
                <Figure>
                    <Fade up>
                        <Box component="img" src={props.src}/>
                    </Fade>
                </Figure>
                <Content>
                    <Stack direction={"column"} sx={{flex: 1}}>
                        <SectionHeading>
                            <Typography
                                color={"rgb(138,180,248)"}
                                fontWeight={600}
                                fontSize={theme.typography.body1.fontSize}
                            >
                                {props?.subtitle}
                            </Typography>
                            <Typography
                                color={"rgba(255, 255, 255, 1.0)"}
                                variant={'h3'}
                                mt={1}
                                mb={2}
                            >
                                {props?.title}
                            </Typography>
                            <Typography
                                color={"rgba(255, 255, 255, 0.7)"}
                                variant={'body1'}
                            >
                                {props?.desc}
                            </Typography>
                            {props.features?.length > 0 ?
                                <Features>
                                    {props.features?.map((feat, i) => (
                                        <li key={i}>
                                            <Iconify
                                                icon="material-symbols:check-circle-rounded"
                                                width={22}
                                                height={22}
                                                color={"rgb(138,180,248)"}
                                                sx={{mr: 2, flexShrink: 0}}
                                            />
                                            <Typography
                                                color={"rgba(255, 255, 255, 1.0)"}
                                                fontWeight={600}
                                                variant={'body1'}
                                            >
                                                {feat}
                                            </Typography>
                                        </li>
                                    ))}
                                </Features>
                                :
                                ""
                            }
                        </SectionHeading>
                    </Stack>
                </Content>
            </Grid>
        </Container>
    )
}

const AboutRight = (props) => {
    const theme = useTheme()

    return (
        <Container width="1024px">
            <RightGrid>
                <RightContent>
                    <Stack direction={"column"} sx={{flex: 1}}>
                        <SectionHeading>
                            <Typography
                                color={"rgb(138,180,248)"}
                                fontWeight={600}
                                fontSize={theme.typography.body1.fontSize}
                            >
                                {props?.subtitle}
                            </Typography>
                            <Typography
                                color={"rgba(255, 255, 255, 1.0)"}
                                variant={'h3'}
                                mt={1}
                                mb={2}
                            >
                                {props?.title}
                            </Typography>
                            <Typography
                                color={"rgba(255, 255, 255, 0.7)"}
                                variant={'body1'}
                            >
                                {props?.desc}
                            </Typography>
                            {props.features?.length > 0 ?
                                <Features>
                                    {props.features?.map((feat, i) => (
                                        <li key={i}>
                                            <Iconify
                                                icon="material-symbols:check-circle-rounded"
                                                width={22}
                                                height={22}
                                                color={"rgb(138,180,248)"}
                                                sx={{mr: 2, flexShrink: 0}}
                                            />
                                            <Typography
                                                color={"rgba(255, 255, 255, 1.0)"}
                                                fontWeight={600}
                                                variant={'body1'}
                                            >
                                                {feat}
                                            </Typography>
                                        </li>
                                    ))}
                                </Features>
                                :
                                ""
                            }
                        </SectionHeading>
                    </Stack>
                </RightContent>
                <RightFigure>
                    <Fade up>
                        <Box component="img" src={props.src}/>
                    </Fade>
                </RightFigure>
            </RightGrid>
        </Container>
    );
};

const AboutSection = () => {
    const {t} = useTranslation()

    return (
        <Box sx={{position: "relative", marginTop: 8}}>
            <Box className="custom-shape-divider-top-1680342428">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                     preserveAspectRatio="none">
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill"></path>
                </svg>
            </Box>
            <Section
                id="about"
                style={{backgroundColor: "#373752"}}
            >
                <AboutLeft
                    src={"/assets/landing/about-1.webp"}
                    subtitle={t("landing.about.tasks.subtitle")}
                    title={t("landing.about.tasks.title")}
                    desc={t("landing.about.tasks.body")}
                    features={[
                        t("landing.about.tasks.feature.title1"),
                        t("landing.about.tasks.feature.title2"),
                        t("landing.about.tasks.feature.title3"),
                    ]}
                />
                <AboutRight
                    src={"/assets/landing/about-2.webp"}
                    subtitle={t("landing.about.timetable.subtitle")}
                    title={t("landing.about.timetable.title")}
                    desc={t("landing.about.timetable.body")}
                    features={[
                        t("landing.about.timetable.feature.title1"),
                        t("landing.about.timetable.feature.title2"),
                        t("landing.about.timetable.feature.title3"),
                    ]}
                />
                <AboutLeft
                    src={"/assets/landing/about-3.webp"}
                    subtitle={t("landing.about.overview.subtitle")}
                    title={t("landing.about.overview.title")}
                    desc={t("landing.about.overview.body")}
                    features={[
                        t("landing.about.overview.feature.title1"),
                        t("landing.about.overview.feature.title2"),
                        t("landing.about.overview.feature.title3"),
                    ]}
                />
            </Section>
            <Box className="custom-shape-divider-bottom-1680343255">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                     preserveAspectRatio="none">
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill"></path>
                </svg>
            </Box>
        </Box>
    );
};

export default AboutSection;
