import Realm from "realm";
import {Lesson} from "./Lesson";
import {RecurringPattern, RecurringPatternProperties} from "./RecurringPattern";
import {LessonInstanceException, LessonInstanceExceptionProperties} from "./LessonInstanceException";

export interface LessonOccurrenceInsertOptions {
    lessonId: string;
    date?: string;
    index_of_week: number;
    index_of_day: number;
    time_start_in_minutes?: number;
    time_end_in_minutes?: number;
    time_start_in_periods?: number;
    time_end_in_periods?: number;
    is_recurring: boolean;
    recurring_pattern?: RecurringPatternProperties;
    instance_exceptions?: LessonInstanceExceptionProperties[];
}

export interface LessonOccurrenceUpdateOptions {
    date?: string;
    index_of_week: number;
    index_of_day: number;
    time_start_in_minutes?: number;
    time_end_in_minutes?: number;
    time_start_in_periods?: number;
    time_end_in_periods?: number;
    is_recurring: boolean;
    recurring_pattern?: RecurringPatternProperties;
    instance_exceptions?: LessonInstanceExceptionProperties[];
}

export interface LessonOccurrenceProperties {
    _id: string;
    owner_id: string;
    date?: string;
    index_of_week: number;
    index_of_day: number;
    time_start_in_minutes?: number;
    time_end_in_minutes?: number;
    time_start_in_periods?: number;
    time_end_in_periods?: number;
    is_recurring: boolean;
    recurring_pattern?: RecurringPatternProperties;
    instance_exceptions?: Realm.List<LessonInstanceException>;
}

export class LessonOccurrence extends Realm.Object implements LessonOccurrenceProperties {
    _id!: string;
    date?: string;
    index_of_day!: number;
    index_of_week!: number;
    is_recurring!: boolean;
    lesson?: Lesson;
    owner_id!: string;
    time_end_in_minutes?: number;
    time_end_in_periods?: number;
    time_start_in_minutes?: number;
    time_start_in_periods?: number;
    recurring_pattern?: RecurringPattern;
    instance_exceptions!: Realm.List<LessonInstanceException>;

    static schema: Realm.ObjectSchema = {
        name: 'lesson_occurrence',
        primaryKey: '_id',
        properties: {
            _id: 'string',
            date: 'string?',
            index_of_day: 'int',
            index_of_week: 'int',
            is_recurring: 'bool',
            lesson: 'lesson',
            owner_id: 'string',
            time_end_in_minutes: 'int?',
            time_end_in_periods: 'int?',
            time_start_in_minutes: 'int?',
            time_start_in_periods: 'int?',
            recurring_pattern: 'lesson_recurring_pattern',
            instance_exceptions: 'lesson_instance_exception[]',
        }
    }
}
