import React, {Suspense, useState} from 'react';
import {alpha, styled} from '@mui/material/styles';
import {Avatar, Box, Divider, IconButton, MenuItem, Popover, Stack, Typography} from '@mui/material';
import {useAuthToken} from "../../../hooks/AuthToken";
import {useTranslation} from "react-i18next";
import {googleLogout} from "@react-oauth/google";
import {DESKTOP_CLIENT_URL, GOOGLE_PLAY_APP_URL} from "../../../common/Constants";
import LanguagePopover from "./LanguagePopover";
import {useApp} from "@realm/react";

export default function AccountPopover() {
    const atlasApp = useApp();
    const {t} = useTranslation()

    /* State */
    const [open, setOpen] = useState<HTMLButtonElement | undefined | null>(null);
    const [authToken, , removeAuthToken] = useAuthToken()

    let email: string | undefined | null
    let displayName: string | undefined | null
    let photoURL: string | undefined | null
    const tokens = authToken?.split(".")
    if (tokens) {
        try {
            const payload = JSON.parse(atob(tokens[1]));
            email = payload?.email
            displayName = payload?.name
            photoURL = payload?.picture
        } catch (e) {
            console.log(e)
        }
    }

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleLogout = () => {
        removeAuthToken()
        googleLogout()
        atlasApp.currentUser?.logOut()
    }

    return (
        <>
            <Suspense fallback="loading">
                <IconButton
                    className={'webkit-no-drag'}
                    onClick={(event) => handleOpen(event)}
                    sx={{
                        p: 0,
                        // @ts-ignore
                        ...(open && {
                            '&:before': {
                                zIndex: 1,
                                content: "''",
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                position: 'absolute',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                            },
                        }),
                    }}
                >
                    <Avatar src={photoURL || ""} alt="photoURL"/>
                </IconButton>

                <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleClose}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    transformOrigin={{vertical: 'top', horizontal: 'right'}}
                    PaperProps={{
                        sx: {
                            p: 0,
                            mt: 1.5,
                            ml: 0.75,
                            minWidth: '240px',
                            '& .MuiMenuItem-root': {
                                typography: 'body2',
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >
                    <Box sx={{my: 1.5, px: 2.5}}>
                        <Typography variant="subtitle2" noWrap>
                            {displayName || ''}
                        </Typography>
                        <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                            {email || ''}
                        </Typography>
                    </Box>

                    <Divider/>

                    <Stack sx={{backgroundColor: '#fbfbfa', py: 1}}>
                        <LanguagePopover/>

                        <StyledMenuItem onClick={handleLogout} sx={{mx: 1}}>
                            {t('logout')}
                        </StyledMenuItem>

                        <Divider/>

                        <StyledMenuItem
                            id={'get_desktop_client'}
                            onClick={() => {
                                window.open(DESKTOP_CLIENT_URL, "_blank", "noreferrer");
                            }}
                            sx={{mx: 1}}
                        >
                            {t('app.header.account_settings.get_desktop_client')}
                        </StyledMenuItem>

                        <StyledMenuItem
                            onClick={() => {
                                window.open(GOOGLE_PLAY_APP_URL, "_blank", "noreferrer");
                            }}
                            sx={{mx: 1}}
                        >
                            {t('app.header.account_settings.get_mobile_client')}
                        </StyledMenuItem>
                    </Stack>
                </Popover>
            </Suspense>
        </>
    );
}

export const StyledMenuItem = styled(MenuItem)(({theme}) => ({
    color: theme.palette.text.secondary
}));