import moment from "moment/moment";
import {MONGO_DATE_FORMAT} from "../utils/date";
import {LessonInstanceExceptionProperties} from "../models/LessonInstanceException";

export class LessonInstanceExceptionDecorator {
    private exception: LessonInstanceExceptionProperties;

    constructor(exception: LessonInstanceExceptionProperties) {
        this.exception = {...exception};
    }

    getInstanceDate(): moment.Moment | null {
        const date = moment(this.exception.instance_date, MONGO_DATE_FORMAT, true);
        return date.isValid() ? date : null;
    }
}