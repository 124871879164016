import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import {
    LessonOccurrence,
    LessonOccurrenceInsertOptions,
    LessonOccurrenceUpdateOptions
} from "../models/LessonOccurrence";
import ObjectID from "bson-objectid";
import {Lesson} from "../models/Lesson";

export function useLessonOccurrenceManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const lessonOccurrences =
        useQuery(LessonOccurrence, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a lesson_occurrence in the database.
     */
    const createLessonOccurrence = useCallback((options: LessonOccurrenceInsertOptions) => {
        const {lessonId, recurring_pattern, ...otherOptions} = options;

        realm.write(() => {
            realm.create("lesson_occurrence", {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                lesson: realm.objectForPrimaryKey(Lesson, lessonId) ?? undefined,
                recurring_pattern: recurring_pattern,
                ...otherOptions
            });
        });
    }, [realm, user.id]);

    /**
     * Updates a lesson_occurrence in the database.
     */
    const updateLessonOccurrence = useCallback((objectId: string, options: LessonOccurrenceUpdateOptions) => {
        realm.write(() => {
            const object = realm.objectForPrimaryKey(LessonOccurrence, objectId)
            if (object) {
                object.date = options.date;
                object.index_of_week = options.index_of_week;
                object.index_of_day = options.index_of_day;
                object.time_start_in_minutes = options.time_start_in_minutes;
                object.time_end_in_minutes = options.time_end_in_minutes;
                object.time_start_in_periods = options.time_start_in_periods;
                object.time_end_in_periods = options.time_end_in_periods;
                object.is_recurring = options.is_recurring;
                // @ts-ignore
                object.recurring_pattern = options.recurring_pattern;

                while (object.instance_exceptions.length > 0) {
                    object.instance_exceptions.pop();
                }
                if (options.instance_exceptions) {
                    options.instance_exceptions.forEach(it => {
                        // @ts-ignore
                        object.instance_exceptions.push(it);
                    });
                }
            }
        });
    }, [realm]);

    /**
     * Set lesson_occurrences for a lesson in the database.
     */
    const updateLessonOccurrencesForLesson = useCallback((lessonId: string, optionsList: LessonOccurrenceUpdateOptions[]) => {
        realm.write(() => {
            const occurrencesByLesson = realm
                .objects(LessonOccurrence)
                .filter(it => it?.lesson?._id === lessonId);

            realm.delete(occurrencesByLesson);

            optionsList.forEach(options => {
                const {recurring_pattern, ...otherOptions} = options;

                realm.create("lesson_occurrence", {
                    _id: ObjectID().toHexString(),
                    owner_id: user.id,
                    lesson: realm.objectForPrimaryKey(Lesson, lessonId) ?? undefined,
                    recurring_pattern: recurring_pattern,
                    ...otherOptions
                });
            });
        });
    }, [realm, user.id]);

    /**
     * Deletes a homework from the database.
     */
    /*
    const deleteSubject = useCallback((objectId: string) => {
        realm.write(() => {
            const objects = realm.objects(Subject).filter(object => object._id === objectId)
            realm.delete(objects);
        });
    }, [realm]);
     */

    return {
        lessonOccurrences,
        createLessonOccurrence,
        updateLessonOccurrence,
        updateLessonOccurrencesForLesson
    }
}