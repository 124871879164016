import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Iconify from "../../../common/components/iconify";
import {Button, DialogActions, IconButton, Stack, TextareaAutosize, Typography} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import useMediaQuery from "@mui/material/useMediaQuery";
import ColorPopover from "../../../common/components/color-popover";
import ColorButton from "./components/ColorButton";
import {COLOR_DEFAULT, colorToHexColor, colorToSigned24Bit} from "../../../utils/color";
import {useTranslation} from "react-i18next";
import {BootstrapDialog, Transition} from "../../../common/components/BootstrapDialog";
import SaveButton from "../../../common/components/SaveButton";
import {useSubjectManager} from "../../../hooks/useSubjectManager";
import {Subject} from "../../../models/Subject";

// ---------------------------------------------------------------------------------------------------------------------
const NameTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    margin: '3px 0px 8px 8px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 600
}));

const SingleLineTextField = styled('input')(({theme}) => ({
    flex: 1,
    border: 'none',
    outline: 'none',
    placeholderColor: theme.palette.text.disabled,
    color: theme.palette.text.primary,
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight
}));

const NoteTextArea = styled(TextareaAutosize)(({theme}) => ({
    flex: 1,
    margin: '8px 16px',
    border: 'none',
    outline: 'none',
    resize: 'none',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight
}));


// ---------------------------------------------------------------------------------------------------------------------
export interface SubjectDialogUpdateOptionsProps {
    subject: Subject | undefined
}

export interface Props {
    plannerId: string
    open: boolean
    handleClose: () => void
    updateOptions?: SubjectDialogUpdateOptionsProps | undefined | null
}


// ---------------------------------------------------------------------------------------------------------------------
export default function SubjectCommitDialog(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        createSubject,
        updateSubject
    } = useSubjectManager();

    // -----------------------------------------------------------------------------------------------------------------
    // State (Model)
    const subject = props.updateOptions?.subject

    const [name, setName] = React.useState<string | undefined>(undefined);
    if (name === undefined && subject?.name) {
        setName(subject.name)
    }

    const [color, setColor] = React.useState<string | undefined>(undefined);
    if (color === undefined && subject?.color) {
        setColor(colorToHexColor(subject.color))
    }

    const [room, setRoom] = React.useState<string | undefined>(undefined);
    if (room === undefined && subject?.room) {
        setRoom(subject.room)
    }

    const [note, setNote] = React.useState<string | undefined>(undefined);
    if (note === undefined && subject?.note) {
        setNote(subject.note)
    }

    // -----------------------------------------------------------------------------------------------------------------
    const [colorAnchorEl, setColorAnchorEl] = React.useState<HTMLButtonElement | undefined>(undefined);

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const colorOpen = Boolean(colorAnchorEl);
    const popoverColorId = colorOpen ? 'color-popover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    const createSubjectCallback = () => {
        if (!name) return

        createSubject({
            plannerId: props.plannerId,
            name: name,
            color: colorToSigned24Bit(color ? color : COLOR_DEFAULT),
            ...(room) && {room: room},
            ...(note) && {note: note}
        });

        handleClose()
    }

    const updateSubjectCallback = () => {
        if (!subject || !name) return

        updateSubject(subject, {
                name: name,
                color: colorToSigned24Bit(color ? color : COLOR_DEFAULT),
                room: room ?? undefined,
                note: note ?? undefined
        })

        handleClose()
    }

    const onSaveClick = () => {
        if (props.updateOptions) {
            updateSubjectCallback()
        } else {
            createSubjectCallback()
        }
    }

    const handleClose = () => {
        setName(undefined)
        setColor(undefined)
        setRoom(undefined)
        setNote(undefined)

        props.handleClose()
    }

    // -----------------------------------------------------------------------------------------------------------------
    return (
        <BootstrapDialog
            fullWidth={true}
            maxWidth='sm'
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            open={props.open}
        >
            <Stack
                direction="row"
                alignItems="center"
                sx={{pt: 1.5, pb: 1.5, pl: 3, pr: 2, backgroundColor: theme.palette.primary.main}}
            >
                <Typography
                    flex={1}
                    fontWeight={600}
                    fontSize={'1.15em'}
                    noWrap={true}
                    color={theme.palette.primary.contrastText}
                    sx={{textTransform: 'capitalize'}}
                >
                    {t(props.updateOptions ? 'subject.commit_dialog.title_edit' : 'subject.commit_dialog.title_add')}
                </Typography>
                <IconButton onClick={handleClose}>
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:close"
                            color={theme.palette.primary.contrastText}
                            width={24}
                            height={24}
                        />
                    }
                </IconButton>
            </Stack>

            <Divider/>

            <DialogContent>
                <Stack direction="column">
                    <Stack direction={'row'} sx={{mb: 1}}>
                        <NameTextArea
                            placeholder={t("task_dialog.add_name") || ""}
                            value={name || ''}
                            theme={theme}
                            onChange={(event: React.ChangeEvent) => {
                                // @ts-ignore
                                setName(event.target.value)
                            }}
                        />
                    </Stack>

                    <ColorButton
                        color={color || COLOR_DEFAULT}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            setColorAnchorEl(event.currentTarget)
                        }}
                    />

                    <Divider/>

                    <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                            width: '100%',
                            height: '48px',
                            marginLeft: '8px',
                            marginRight: '8px'
                        }}
                    >
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:location-on-outline"
                            width={18}
                            height={18}
                            color={theme.palette.text.secondary}
                            sx={{flexShrink: 0}}
                        />

                        <SingleLineTextField
                            theme={theme}
                            value={room || ""}
                            placeholder={t("task_dialog.add_room") || ""}
                            onChange={(event) => setRoom(event.target.value)}
                            sx={{height: '48px', ml: 2}}
                        />
                    </Stack>

                    <Stack
                        direction="row"
                        mt={3}
                        style={{border: `1px solid ${theme.palette.divider}`, minHeight: '80px', borderRadius: '8px'}}
                    >
                        <NoteTextArea
                            placeholder={t("task_dialog.add_note") || ""}
                            value={note || ''}
                            onChange={(event: React.ChangeEvent) => {
                                // @ts-ignore
                                setNote(event.target.value)
                            }}
                        />
                    </Stack>
                </Stack>
            </DialogContent>

            <Divider/>

            <DialogActions>
                <Stack
                    direction='row-reverse'
                    spacing={2}
                    sx={{marginTop: '8px', marginBottom: '12px', marginRight: '12px'}}
                >
                    <SaveButton
                        handleSaveClick={onSaveClick}
                        isSaving={false}
                        disabled={!name}
                        text={t("save")}
                    />
                    <Button
                        onClick={handleClose}
                        variant='contained'
                        color='inherit'
                        sx={{boxShadow: 'none'}}
                    >
                        {t("cancel")}
                    </Button>
                </Stack>
            </DialogActions>

            <ColorPopover
                id={popoverColorId}
                color={color || COLOR_DEFAULT}
                open={colorOpen}
                anchorEl={colorAnchorEl}
                handleClose={() => setColorAnchorEl(undefined)}
                onChange={(color) => setColor(color.hex)}/>

        </BootstrapDialog>
    );
}