export const COLOR_DEFAULT: string = '#2065D1'
const COLOR_DEFAULT_24BIT: number = 0xFF2065D1

export function colorToHexColor(color: number) {
    const c = (color & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "#" + "00000".substring(0, 6 - c.length) + c;
}

export function colorToSigned24Bit(s: string, defaultColor: number = COLOR_DEFAULT_24BIT) {
    if (s.length < 9) {
        s = s.replace('#', '#FF')
    }

    const parsed = parseInt(s.substr(1), 16);
    if (isNaN(parsed)) {
        return COLOR_DEFAULT_24BIT
    }

    return (parsed << 8) / 256;
}
