import {TimeFormat, TimetableDecorator} from "../../../../model/TimetableDecorator";
import moment from "moment/moment";
import {useTheme} from "@mui/material/styles";
import {Stack, Typography} from "@mui/material";
import {isTwelveHourClock} from "../../../../utils/date";
import React from "react";
import {HEIGHT_HOUR_BLOCK, HOUR_COUNT, WIDTH_HOUR_COLUMN, WIDTH_HOUR_COLUMN_PERIOD} from "../../TimetableLayout";

export default function HourColumn(
    props: {
        timetable: TimetableDecorator | undefined | null,
        dayOfWeek: moment.Moment
    }
) {
    const theme = useTheme()

    if (props.timetable?.getTimeFormat() === TimeFormat.PERIOD) {
        const numberOfPeriods = props.timetable.getNumberOfPeriods()
        return (
            <Stack direction={'column'} sx={{width: WIDTH_HOUR_COLUMN_PERIOD + 'px'}}>
                {
                    Array.from(Array(numberOfPeriods).keys()).map((value, index) => {
                        return (
                            <Stack
                                key={index}
                                direction="column"
                                alignContent={'center'}
                                justifyContent={'center'}
                                sx={{
                                    width: '100%',
                                    height: (index === HOUR_COUNT - 1) ?
                                        (HEIGHT_HOUR_BLOCK / 2) + 'px' : HEIGHT_HOUR_BLOCK + 'px'
                                }}
                            >
                                {
                                    value !== HOUR_COUNT - 1 &&
                                    <Typography
                                        variant="caption"
                                        textAlign={'end'}
                                        color={theme.palette.text.secondary}
                                        sx={{mr: 1, fontSize: '0.65em'}}
                                    >
                                        {index + 1}
                                    </Typography>
                                }
                            </Stack>
                        )
                    })
                }
            </Stack>
        )
    }

    return (
        <Stack direction={'column'} sx={{width: WIDTH_HOUR_COLUMN + 'px'}}>
            {
                Array.from(Array(HOUR_COUNT - 1).keys()).map((value, index) => {
                    return (
                        <Stack
                            key={index}
                            direction="column"
                            alignContent={'center'}
                            justifyContent={'center'}
                            sx={{
                                width: '100%',
                                height: (index === HOUR_COUNT - 1) ?
                                    (HEIGHT_HOUR_BLOCK / 2) + 'px' : HEIGHT_HOUR_BLOCK + 'px',
                                ...(index === 0) && {
                                    mt: HEIGHT_HOUR_BLOCK / 2 + 'px'
                                }
                            }}
                        >
                            {
                                <Typography
                                    variant="caption"
                                    textAlign={'center'}
                                    color={theme.palette.text.secondary}
                                    sx={{mr: 1, fontSize: '0.65em'}}
                                >
                                    {index + 1 % 12 === 0 ? 12 : index + 1 % 12}
                                </Typography>
                            }
                            {
                                isTwelveHourClock() && <Typography
                                    variant="caption"
                                    textAlign={'center'}
                                    color={theme.palette.text.secondary}
                                    sx={{mr: 1, fontSize: '0.65em'}}
                                >
                                    {index + 1 >= 12 ? 'pm' : 'am'}
                                </Typography>
                            }
                        </Stack>
                    )
                })
            }
        </Stack>
    )
}