/*===================

==== AppCreative2 dummy data list ====

- Navbar
- Banner
- Why Choose Us
- Analytics Tool
- Ever Need
- Testimonial
- Wallet Experience
- Technology Community
- Pricing
- FAQ's
- CTA
- Footer Top
- Footer

====================*/

/* ------------------------------------ */
// Navbar data section
/* ------------------------------------ */
export const menu_items = [
    {
        label: 'landing.menu.home',
        path: '#home',
        offset: '70',
        id: 1,
    },
    {
        label: 'landing.menu.about',
        path: '#about',
        offset: '70',
        id: 2,
    }
];

/* ------------------------------------ */
// Banner data section
/* ------------------------------------ */
export const bannerData = {
    news: "landing.banner.news",
    newsLabel: "landing.banner.news_label",
    title: "landing.banner.title",
    text: "landing.banner.text",
    get: "landing.banner.get"
};

/* ------------------------------------ */
// Footer Top data section
/* ------------------------------------ */
export const footerTop = {
    about: {
        text: "landing.footer.catchphrase",
    },
    widgets: [
        {
            id: 1,
            title: 'landing.footer.company',
            list: [
                {
                    id: 1,
                    title: 'landing.footer.about_us',
                    link: '/support',
                },
                {
                    id: 2,
                    title: 'landing.footer.support_center',
                    link: '/support',
                },
                {
                    id: 3,
                    title: 'landing.footer.customer_support',
                    link: 'mailto:support@schoolplanner.io',
                },
                {
                    id: 4,
                    title: 'landing.footer.privacy_policy',
                    link: '/support/privacy',
                }
            ],
        }
    ],
    contactInfo: {
        title: 'landing.footer.contact',
        address: 'landing.footer.address',
        email: `support@schoolplanner.io`,
    },
};

/* ------------------------------------ */
// Footer data section
/* ------------------------------------ */
export const footer = {
    copyright: "landing.footer.copyright",
    nav: [
        {
            id: 1,
            title: 'landing.footer.support',
            link: '/support',
        },
        {
            id: 2,
            title: 'landing.footer.privacy',
            link: '/support/privacy',
        }
    ],
    socialLinks: [
        {
            id: 1,
            link: 'http://facebook.com',
            label: 'Facebook',
        },
        {
            id: 2,
            link: 'http://twitter.com',
            label: 'Twitter',
        },
        {
            id: 3,
            link: 'http://dribbble.com',
            label: 'Dribbble',
        },
    ],
};
