import {useQuery, useRealm, useUser} from "@realm/react";
import {useCallback, useEffect, useState} from "react";
import ObjectID from "bson-objectid";
import {Planner} from "../models/Planner";
import {Subject} from "../models/Subject";

interface SubjectInsertOptions {
    plannerId: string;
    name: string;
    color: number;
    note?: string;
    room?: string;
    created_on?: string;
}

interface SubjectUpdateOptions {
    name: string;
    color: number;
    note?: string;
    room?: string;
}

export function useSubjectManager() {
    const realm = useRealm()
    const user = useUser()
    const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
    const subjects = useQuery(Subject, (collection) => collection, [requeryFlag]);

    useEffect(() => {
        setRequeryFlag(true);
    }, []);

    /**
     * Inserts a homework in the database.
     */
    const createSubject = useCallback((options: SubjectInsertOptions) => {
        realm.write(() => {
            realm.create(Subject, {
                _id: ObjectID().toHexString(),
                owner_id: user.id,
                planner: realm.objectForPrimaryKey(Planner, options.plannerId) ?? undefined,
                ...options
            });
        });
    }, [realm, user.id]);

    /**
     * Update a homework in the database.
     */
    const updateSubject = useCallback((object: Subject, options: SubjectUpdateOptions) => {
        realm.write(() => {
            object.name = options.name;
            object.color = options.color;
            object.note = options.note;
            object.room = options.room;
        });
    }, [realm]);

    /**
     * Deletes a homework from the database.
     */
    const deleteSubject = useCallback((objectId: string) => {
        realm.write(() => {
            const objects = realm.objects(Subject).filter(object => object._id === objectId)
            realm.delete(objects);
        });
    }, [realm]);

    return {
        subjects,
        createSubject,
        updateSubject,
        deleteSubject
    }
}