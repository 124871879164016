import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {Button, Popover, Stack, Typography} from "@mui/material";
import Iconify from "../../iconify";
import * as React from "react";

const BUTTON_HEIGHT = '40px'
const POPOVER_MIN_WIDTH = '224px'

export default function SubjectOptionsPopover(
    props: {
        id: string | undefined
        open: boolean
        anchorEl: Element | null | undefined
        handleClose: () => void
        handleOnUpdate: () => void
        handleOnDelete: () => void
    }
) {
    const theme = useTheme()
    const {t} = useTranslation()

    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Stack direction='column' padding={1} sx={{minWidth: POPOVER_MIN_WIDTH}}>
                <Button
                    onClick={props.handleOnUpdate}
                    color='inherit'
                    sx={{width: '100%', height: BUTTON_HEIGHT}}
                >
                    <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:edit"
                            color={theme.palette.text.primary}
                            width={18}
                            height={18}
                        />
                        <Typography
                            variant='body2'
                            fontWeight={600}
                            color={theme.palette.text.primary}
                            sx={{marginLeft: '12px'}}
                        >
                            {t("edit")}
                        </Typography>
                    </Stack>
                </Button>
                <Button
                    onClick={props.handleOnDelete}
                    color='inherit'
                    sx={{width: '100%', height: BUTTON_HEIGHT}}
                >
                    <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:delete"
                            color={theme.palette.error.main}
                            width={18}
                            height={18}
                        />
                        <Typography
                            variant='body2'
                            fontWeight={600}
                            color={theme.palette.error.main}
                            sx={{marginLeft: '12px'}}
                        >
                            {
                                t("delete")
                            }
                        </Typography>
                    </Stack>
                </Button>
            </Stack>
        </Popover>
    )
}