import {MenuItem, Select, SelectChangeEvent, SxProps, Theme, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import FormControl from "@mui/material/FormControl";
import * as React from "react";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Divider from "@mui/material/Divider";
import {Planner} from "../../../../models/Planner";

const LIST_ITEM_HEIGHT = '36px'

export const PlannerSelector = (
    props: {
        planners: Planner[] | undefined | null,
        selectedPlannerId: string | undefined | null

        handleAddPlanner: () => void
        handleOnSetSelectedPlannerId: (plannerId: string) => void
        sx?: SxProps<Theme> | undefined
    }
) => {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <FormControl sx={{m: 1, minWidth: 128, ...(props.sx)}} size="small">
            <Select
                labelId="task-option-select-small"
                id="task-option-select-small"
                value={props.selectedPlannerId || ''}
                onChange={(event: SelectChangeEvent) => props.handleOnSetSelectedPlannerId(event.target.value)}
            >
                {
                    props.planners?.map((planner) => {
                        return (
                            <MenuItem
                                key={planner?._id}
                                value={planner?._id}
                                sx={{height: LIST_ITEM_HEIGHT, borderRadius: "4px"}}
                            >
                                <Typography variant='body2'>
                                    {planner?.title}
                                </Typography>
                            </MenuItem>
                        )
                    })
                }
                {
                    (props.planners?.length ?? 0) > 0 && <Divider sx={{my: 2}} />
                }
                <MenuItem
                    onClick={props.handleAddPlanner}
                    sx={{height: LIST_ITEM_HEIGHT, pr: "24px", borderRadius: "4px"}}
                >
                    {
                        <Iconify
                            // @ts-ignore
                            icon="material-symbols:add"
                            color={theme.palette.primary.main}
                        />
                    }
                    <Typography
                        variant='body2'
                        color={theme.palette.primary.main}
                        ml={1}
                    >
                        {t("add_planner")}
                    </Typography>
                </MenuItem>
            </Select>
        </FormControl>
    )
}