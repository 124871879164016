import React from "react";
import {Button, Container, Stack, Typography} from "@mui/material";

import Divider from "@mui/material/Divider";
import {useTranslation} from "react-i18next";
import {LinkIndex} from "../SupportLayout";
import Iconify from "../../../common/components/iconify";
import {useTheme} from "@mui/material/styles";

export default function PermissionsLayout() {
    const {t} = useTranslation()
    const theme = useTheme()

    return (
        <Container
            maxWidth={"md"}
            style={{flex: 1, display: 'flex', flexDirection: 'column'}}
        >
            <Typography variant={"h3"}>
                {t("support.permissions.drawer")}
            </Typography>

            <Typography variant={"h4"} mt={2}>Android app</Typography>

            <Typography variant={"body1"} mt={2}>
                {t("support.permissions.sub_header")}
            </Typography>

            <LinkIndex className={"icon-list"} href={"#location"} underline="none" mt={2}>
                {
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:arrow-circle-right-rounded"}
                        width={24}
                        color={theme.palette.primary.main}
                    />
                }
                {t("support.permissions.location.title")}
            </LinkIndex>

            <LinkIndex className={"icon-list"} href={"#camera"} underline="none">
                {
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:arrow-circle-right-rounded"}
                        width={24}
                        color={theme.palette.primary.main}
                    />
                }
                {t("support.permissions.camera.title")}
            </LinkIndex>

            <LinkIndex className={"icon-list"} href={"#microphone"} underline="none">
                {
                    <Iconify
                        // @ts-ignore
                        icon={"material-symbols:arrow-circle-right-rounded"}
                        width={24}
                        color={theme.palette.primary.main}
                    />
                }
                {t("support.permissions.microphone.title")}
            </LinkIndex>

            <Divider sx={{mt: 2}} />

            <Typography variant={'h4'} id="location" mt={3}>
                {t("support.permissions.location.title")}
            </Typography>

            <Typography variant={"body1"} mt={1}>
                {t("support.permissions.location.body1")}
            </Typography>

            <Typography variant={'h4'} id="camera" mt={3}>
                {t("support.permissions.camera.title")}
            </Typography>

            <Typography variant={"body1"} mt={1}>
                {t("support.permissions.camera.body1")}
            </Typography>

            <Typography variant={'h4'} id="microphone" mt={3}>
                {t("support.permissions.microphone.title")}
            </Typography>

            <Typography variant={"body1"} mt={1}>
                {t("support.permissions.microphone.body1")}
            </Typography>

            <Stack direction={"row"} sx={{mt: 5}}>
                <Button
                    variant={'contained'}
                    size={'large'}
                    href="mailto:support@schoolplanner.io?subject=Request:%20Payment%20issues"
                    sx={{textTransform: 'none'}}
                >
                    {t("support.contact_support")}
                </Button>
            </Stack>

            <div style={{minHeight: '64px'}}></div>

        </Container>
    )
}