import {useTheme} from "@mui/material/styles";
import {Button, Popover, Stack, Typography} from "@mui/material";
import Iconify from "../../../../common/components/iconify";
import * as React from "react";
import {Category} from "../../../../model/ExamModel";
import {useTranslation} from "react-i18next";

const BUTTON_HEIGHT = '40px'
const POPOVER_MIN_WIDTH = '192px'

interface Props {
    id: string | undefined
    open: boolean
    anchorEl: Element | null | undefined
    handleClose: () => void
    handleOnCategorySelect: (category: Category | null | undefined) => void
}

export default function CategoryPopover(props: Props) {
    const theme = useTheme()
    const {t} = useTranslation()

    const categoriesWithLabels = [
        {category: Category.WRITTEN, label: "task_dialog.written_exam"},
        {category: Category.ORAL, label: "task_dialog.oral_exam"},
        {category: Category.PRACTICAL, label: "task_dialog.practical_exam"}
    ]

    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Stack direction='column' padding={1} sx={{minWidth: POPOVER_MIN_WIDTH}}>
                {
                    categoriesWithLabels.map(categoryWithLabel => (
                        <Button
                            key={categoryWithLabel.label}
                            onClick={() => props.handleOnCategorySelect(categoryWithLabel.category)}
                            color='inherit'
                            sx={{width: '100%', height: BUTTON_HEIGHT}}
                        >
                            <Stack direction='row' alignItems='center' sx={{width: '100%'}}>
                                <Typography
                                    variant='body2'
                                    color={theme.palette.text.primary}
                                    sx={{marginLeft: '12px'}}
                                >
                                    {t(categoryWithLabel.label)}
                                </Typography>
                            </Stack>
                        </Button>
                    ))
                }
            </Stack>
        </Popover>
    )
}