import React, {useRef, useState} from "react";
import {ListOnItemsRenderedProps, VariableSizeList as List} from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import "./task.css"
import moment from "moment/moment";
import {Container, Fab, Popover} from "@mui/material";
import TaskHeader from "./header";
import GroupRow, {ADD_HEIGHT, HEADER_HEIGHT, ROW_HEIGHT} from "./components/GroupRow";
import TaskDialog, {
    EXAM_VALUE,
    HOMEWORK_VALUE,
    REMINDER_VALUE,
    TaskDialogInsertOptionsProps,
    TaskDialogUpdateOptionsProps
} from "./dialog/TaskDialog";
import {useOutletContext} from "react-router-dom";
import {MONGO_DATE_FORMAT, MONGO_TIME_FORMAT} from "../../utils/date";
import TaskDeleteConfirmationDialog from "./dialog/TaskDeleteConfirmationDialog";
import SubjectCommitDialog, {SubjectDialogUpdateOptionsProps} from "./dialog/SubjectCommitDialog";
import AddIcon from '@mui/icons-material/Add';
import Box from "@mui/material/Box";
import {StaticDatePicker} from "@mui/x-date-pickers";
import {Homework} from "../../models/Homework";
import {colorToHexColor} from "../../utils/color";
import {Reminder} from "../../models/Reminder";
import {useTaskManager} from "./hooks/useTaskManager";

// ---------------------------------------------------------------------------------------------------------------------
const MAX_LOOKAHEAD_DAYS = 365;

// ---------------------------------------------------------------------------------------------------------------------
const TaskLayout = () => {
    // @ts-ignore
    const [selectedPlannerId] = useOutletContext();

    // -----------------------------------------------------------------------------------------------------------------
    // Realm
    const {
        subjectsByPlanner,
        homeworkByPlanner,
        examsByPlanner,
        remindersByPlanner,
        homeworkByDate,
        examsByDate,
        remindersByDate,
        toggleHomeworkCompletedOn,
        deleteHomework,
        deleteExam,
        toggleReminderCompletedOn,
        deleteReminder
    } = useTaskManager(selectedPlannerId);

    // -----------------------------------------------------------------------------------------------------------------
    // State
    const [
        taskDialogOpen,
        setTaskDialogOpen
    ] = React.useState(false);

    const [
        subjectDialogOpen,
        setSubjectDialogOpen
    ] = React.useState(false);

    const [
        selectedDay,
        setSelectedDay
    ] = useState<moment.Moment | null>(null)

    const [
        weekOffset,
        setWeekOffset
    ] = useState(0);

    const [
        taskDialogInsertOptions,
        setTaskDialogInsertOptions
    ] = useState<TaskDialogInsertOptionsProps | undefined>(undefined)

    const [
        taskDialogUpdateOptions,
        setTaskDialogUpdateOptions
    ] = useState<TaskDialogUpdateOptionsProps | undefined>(undefined)

    const [
        subjectDialogUpdateOptions,
        setSubjectDialogUpdateOptions
    ] = useState<SubjectDialogUpdateOptionsProps | undefined | null>(null)

    const [
        deleteHomeworkId,
        setDeleteHomeworkId
    ] = React.useState<string | undefined | null>();

    const [
        deleteExamId,
        setDeleteExamId
    ] = React.useState<string | undefined | null>();

    const [
        deleteReminderId,
        setDeleteReminderId
    ] = React.useState<string | undefined | null>();

    const [
        dateAnchorEl,
        setDateAnchorEl
    ] = React.useState<HTMLButtonElement | null>(null);

    // -----------------------------------------------------------------------------------------------------------------
    // Refs
    const listRef = useRef()
    const dateOpen = Boolean(dateAnchorEl);
    const popoverDateId = dateOpen ? 'date-popover' : undefined;

    // -----------------------------------------------------------------------------------------------------------------
    const listData: {
        date: moment.Moment;
        homeworkList: Array<any>;
        examList: Array<any>;
        reminderList: Array<any>;
    }[] = []

    for (let i = 0; i < MAX_LOOKAHEAD_DAYS; i++) {
        const date = moment().add(i, 'days').startOf('day')
        listData.push({
            date: date,
            homeworkList: homeworkByDate[date.format('YYYY-MM-DD')] || [],
            examList: examsByDate[date.format('YYYY-MM-DD')] || [],
            reminderList: remindersByDate[date.format('YYYY-MM-DD')] || []
        })
    }

    // -----------------------------------------------------------------------------------------------------------------
    const toggleHomeworkCompletedOnCallback = (homework: Homework) => {
        toggleHomeworkCompletedOn(homework._id)
    }

    const toggleReminderCompletedOnCallback = (reminder: Reminder) => {
        toggleReminderCompletedOn(reminder._id)
    }

    const updateHomeworkCallback = (objectId: string) => {
        const object = homeworkByPlanner.find(it => it?._id === objectId);
        if (!object) return;

        const date = moment(object.date, MONGO_DATE_FORMAT, true)
        const completedOn = moment(object.completed_on, true)
        const remindAt = object.remind_at_list?.length ? object.remind_at_list[0] : undefined
        const remindAtDate = remindAt ? moment(remindAt.date, MONGO_DATE_FORMAT, true) : undefined
        const remindAtTime = remindAt ? moment(remindAt.time, MONGO_TIME_FORMAT, true) : undefined

        setTaskDialogUpdateOptions({
            task: HOMEWORK_VALUE,
            taskId: objectId,
            title: object.title,
            date: date.isValid() ? date : undefined,
            completedOn: completedOn.isValid() ? completedOn : undefined,
            selectedSubjectId: object?.subject?._id,
            steps: object?.steps.map(it => ({...it})),
            ...(remindAtDate?.isValid() && remindAtTime?.isValid()) && {
                remindAt: {date: remindAtDate, time: remindAtTime}
            },
            note: object.note
        })

        setTaskDialogOpen(true)
    }

    const updateExamCallback = (objectId: string) => {
        const object = examsByPlanner.find(it => it?._id === objectId)
        if (!object) return;

        const date = moment(object.date, MONGO_DATE_FORMAT, true)
        const remindAt = object.remind_at_list?.length ? object.remind_at_list[0] : undefined
        const remindAtDate = remindAt ? moment(remindAt.date, MONGO_DATE_FORMAT, true) : undefined
        const remindAtTime = remindAt ? moment(remindAt.time, MONGO_TIME_FORMAT, true) : undefined

        setTaskDialogUpdateOptions({
            task: EXAM_VALUE,
            taskId: objectId,
            title: object.title,
            date: date.isValid() ? date : undefined,
            selectedSubjectId: object.subject?._id,
            category: object.category,
            steps: object?.steps.map(it => ({...it})),
            ...(remindAtDate?.isValid() && remindAtTime?.isValid()) && {
                remindAt: {date: remindAtDate, time: remindAtTime}
            },
            note: object.note
        })

        setTaskDialogOpen(true)
    }

    const updateReminderCallback = (objectId: string) => {
        const object = remindersByPlanner.find(it => it?._id === objectId)
        if (!object) return;

        const date = moment(object.date, MONGO_DATE_FORMAT, true)
        const completedOn = moment(object.completed_on, true)
        const remindAt = object.remind_at_list?.length ? object.remind_at_list[0] : undefined
        const remindAtDate = remindAt ? moment(remindAt.date, MONGO_DATE_FORMAT, true) : undefined
        const remindAtTime = remindAt ? moment(remindAt.time, MONGO_TIME_FORMAT, true) : undefined

        setTaskDialogUpdateOptions({
            task: REMINDER_VALUE,
            taskId: objectId,
            title: object.title,
            date: date.isValid() ? date : undefined,
            completedOn: completedOn.isValid() ? completedOn : undefined,
            color: object.color ? colorToHexColor(object.color) : undefined,
            steps: object?.steps.map(it => ({...it})),
            ...(remindAtDate?.isValid() && remindAtTime?.isValid()) && {
                remindAt: {date: remindAtDate, time: remindAtTime}
            },
            note: object.note
        })

        setTaskDialogOpen(true)
    }

    const updateSubjectCallback = (objectId: string) => {
        const subject = subjectsByPlanner.find(it => it?._id === objectId)
        if (!subject) return;

        setSubjectDialogUpdateOptions({subject: subject})
        setSubjectDialogOpen(true)
    }

    const deleteHomeworkCallback = (objectId: string) => {
        deleteHomework(objectId)
        setDeleteHomeworkId(null)
    }

    const deleteExamCallback = (objectId: string) => {
        deleteExam(objectId)
        setDeleteExamId(null)
    }

    const deleteReminderCallback = (objectId: string) => {
        deleteReminder(objectId)
        setDeleteReminderId(null)
    }

    // -----------------------------------------------------------------------------------------------------------------
    const goToNextWeek = () => {
        setWeekOffset(weekOffset + 1)
    }

    const goToPreviousWeek = () => {
        setWeekOffset(Math.max(0, weekOffset - 1))
    }

    const goToToday = () => {
        setSelectedDay(moment().startOf('day'))
        setWeekOffset(0)
    }

    const handleAddTask = (date: moment.Moment | undefined | null) => {
        setTaskDialogUpdateOptions(undefined)
        setTaskDialogInsertOptions({date: date})
        setTaskDialogOpen(true)
    }

    const handleTaskDialogClose = () => {
        setTaskDialogUpdateOptions(undefined)
        setTaskDialogInsertOptions(undefined)
        setTaskDialogOpen(false)
    }

    const handleSubjectDialogClose = () => {
        setSubjectDialogUpdateOptions(undefined)
        setSubjectDialogOpen(false)
    }

    const handleOnItemsRendered = (props: ListOnItemsRenderedProps) => {
        setSelectedDay(
            moment().add(props.visibleStartIndex, 'days').startOf('day')
        )
        setWeekOffset(
            Math.max(0, props.visibleStartIndex / 7)
        )
    }

    // -----------------------------------------------------------------------------------------------------------------
    // @ts-ignore
    listRef.current?.resetAfterIndex(0);

    return (
        <Box sx={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <Container maxWidth={"md"}>
                <TaskHeader
                    weekOffset={weekOffset}
                    goToToday={() => {
                        goToToday()
                        // @ts-ignore
                        listRef.current.scrollToItem(0, "start");
                    }}
                    goToNextWeek={goToNextWeek}
                    goToPreviousWeek={goToPreviousWeek}
                    selectedDay={selectedDay}
                    handleOnShowDaySelector={(event) => {
                        // @ts-ignore
                        setDateAnchorEl(event.target)
                    }}
                    setSelectedDay={(day) => {
                        setSelectedDay(day)
                        const today = moment().startOf('day')
                        const diffInDays = Math.max(0, day?.diff(today, 'days') || 0)
                        // @ts-ignore
                        listRef.current.scrollToItem(diffInDays, "start");
                    }}
                />
            </Container>

            <Container maxWidth={"md"} style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                <Box style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <AutoSizer>
                        {({height, width}) => (
                            <List
                                className="List"
                                // @ts-ignore
                                ref={listRef}
                                width={width}
                                height={height}
                                itemCount={listData.length}
                                itemData={{
                                    items: listData,
                                    handleToggleHomeworkCompletedOn: toggleHomeworkCompletedOnCallback,
                                    handleToggleReminderCompletedOn: toggleReminderCompletedOnCallback,
                                    handleOnUpdateHomework: updateHomeworkCallback,
                                    handleOnUpdateExam: updateExamCallback,
                                    handleOnUpdateReminder: updateReminderCallback,
                                    handleOnUpdateSubject: updateSubjectCallback,
                                    handleOnDeleteHomework: setDeleteHomeworkId,
                                    handleOnDeleteExam: setDeleteExamId,
                                    handleOnDeleteReminder: setDeleteReminderId,
                                    handleAddTask: handleAddTask
                                }}
                                onItemsRendered={handleOnItemsRendered}
                                itemSize={index => {
                                    return (
                                            listData[index].homeworkList.length +
                                            listData[index].examList.length +
                                            listData[index].reminderList.length)
                                        * ROW_HEIGHT +
                                        HEADER_HEIGHT +     // header
                                        ADD_HEIGHT;         // add
                                }}
                            >
                                {GroupRow}
                            </List>
                        )}
                    </AutoSizer>
                </Box>
            </Container>

            <TaskDialog
                open={taskDialogOpen}
                handleClose={handleTaskDialogClose}
                selectedPlannerId={selectedPlannerId}
                insertOptions={taskDialogInsertOptions}
                updateOptions={taskDialogUpdateOptions}
            />

            <SubjectCommitDialog
                open={subjectDialogOpen}
                plannerId={selectedPlannerId}
                updateOptions={subjectDialogUpdateOptions}
                handleClose={handleSubjectDialogClose}
            />

            <TaskDeleteConfirmationDialog
                open={!!deleteHomeworkId}
                onClose={() => setDeleteHomeworkId(null)}
                onPositive={() => {
                    if (deleteHomeworkId) {
                        deleteHomeworkCallback(deleteHomeworkId)
                    }
                }}/>

            <TaskDeleteConfirmationDialog
                open={!!deleteExamId}
                onClose={() => setDeleteExamId(null)}
                onPositive={() => {
                    if (deleteExamId) {
                        deleteExamCallback(deleteExamId)
                    }
                }}/>

            <TaskDeleteConfirmationDialog
                open={!!deleteReminderId}
                onClose={() => setDeleteReminderId(null)}
                onPositive={() => {
                    if (deleteReminderId) {
                        deleteReminderCallback(deleteReminderId)
                    }
                }}/>

            <Popover
                id={popoverDateId}
                open={dateOpen}
                anchorEl={dateAnchorEl}
                onClose={() => setDateAnchorEl(null)}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
            >
                <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={selectedDay || moment()}
                    onChange={(day: moment.Moment | null) => {
                        setSelectedDay(day)
                        const today = moment().startOf('day')
                        const diffInDays = Math.max(0, day?.diff(today, 'days') || 0)
                        // @ts-ignore
                        listRef.current.scrollToItem(diffInDays, "start");
                        setDateAnchorEl(null)
                    }}
                    renderInput={() => <></>}
                />
            </Popover>

            <Fab
                onClick={() => handleAddTask(undefined)}
                color="primary"
                aria-label="add"
                sx={{position: 'absolute', bottom: '24px', right: '24px'}}
            >
                <AddIcon/>
            </Fab>

        </Box>
    )
}

export default TaskLayout;