import moment from "moment/moment";
import {Button, Stack, SxProps, Theme, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {styled, useTheme} from "@mui/material/styles";
import {Scheduling, TimetableDecorator} from "../../../../model/TimetableDecorator";
import {indexOfDayWithTimetable, indexOfWeekWithTimetable} from "../../../../utils/timetable";
import Iconify from "../../../../common/components/iconify";
import React from "react";
import {WIDTH_HOUR_COLUMN} from "../../TimetableLayout";
import {Holiday} from "../../../../models/Holiday";
import {Timetable} from "../../../../models/Timetable";

export default function TimetableHeader(
    props: {
        timetable: Timetable | undefined | null,
        holidays: Holiday[],
        startOfWeek: moment.Moment,
        daysOfWeek: moment.Moment[],
        goToToday: () => void,
        goToPreviousWeek: () => void,
        goToNextWeek: () => void,
        sx?: SxProps<Theme> | undefined
    }
) {
    const {t} = useTranslation()
    const theme = useTheme()
    const borderColor = theme.palette.divider

    const timetable = props.timetable ? new TimetableDecorator(props.timetable) : undefined;
    const indexOfWeek = timetable && timetable.getScheduling() === Scheduling.WEEKLY && timetable.getNumberOfWeeks() > 1
        ? indexOfWeekWithTimetable(props.startOfWeek, timetable, props.holidays)
        : -1;

    return (
        <Stack direction={'column'} sx={{...props.sx}}>
            <Stack direction='row' alignItems='center' sx={{mx: 2, mb: 2}}>
                <Button color="inherit" sx={{
                    [theme.breakpoints.only('xs')]: {
                        maxWidth: '160px',
                    },
                    maxWidth: '100%',
                    height: '36px',
                    mr: 1
                }}>
                    <Typography
                        noWrap={true}
                        variant="h5"
                        sx={{whiteSpace: 'pre'}}
                    >
                        {timetable && indexOfWeek >= 0 ?
                            props.startOfWeek.format("MMMM yyyy") + "  (" + getMonthLabel(timetable, indexOfWeek) + ")" :
                            props.startOfWeek.format("MMMM yyyy")}
                    </Typography>

                </Button>
                <Stack direction={'row'} flex={1} style={{alignItems: 'flex-end'}}>
                    <div style={{flex: 1}}/>
                    <LeftArrowButton
                        color="inherit"
                        variant="outlined"
                        onClick={props.goToPreviousWeek}
                    >
                        {
                            // @ts-ignore
                            <Iconify icon="material-symbols:chevron-left"
                                     color={theme.palette.text.secondary}
                                     width={18}
                                     height={18}
                            />
                        }
                    </LeftArrowButton>
                    <RightArrowButton
                        color="inherit"
                        variant="outlined"
                        onClick={props.goToNextWeek}
                        sx={{mr: 1}}
                    >
                        {
                            // @ts-ignore
                            <Iconify icon="material-symbols:chevron-right"
                                     color={theme.palette.text.secondary}
                                     width={18}
                                     height={18}
                            />
                        }
                    </RightArrowButton>
                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={props.goToToday}
                        sx={{height: '32px'}}
                    >
                        <Typography variant='body2' color={theme.palette.text.secondary}>
                            {t("today")}
                        </Typography>
                    </Button>
                </Stack>
            </Stack>

            <Stack
                direction="row"
                sx={{borderBottom: `1px solid ${borderColor}`, ml: WIDTH_HOUR_COLUMN + 'px'}}
            >
                {
                    props.daysOfWeek.map(dayOfWeek => (
                        <Stack
                            key={dayOfWeek.format('YYYY-MM-DD')}
                            direction={'column'}
                            sx={{flex: 1}} pb={1}
                        >
                            <Typography
                                variant="caption"
                                textAlign={'center'}
                                textTransform={'capitalize'}
                                color={isToday(dayOfWeek) ? theme.palette.primary.main : theme.palette.text.secondary}
                                sx={{flex: 1}}
                            >
                                {
                                    timetable && getDayOfWeekLabel(timetable, props.holidays, dayOfWeek)}
                            </Typography>
                            <Typography
                                variant="h5"
                                textAlign={'center'}
                                fontWeight={theme.typography.body1.fontWeight}
                                color={isToday(dayOfWeek) ? theme.palette.primary.main : theme.palette.text.primary}
                                sx={{flex: 1}}
                            >
                                {dayOfWeek.format('D')}
                            </Typography>
                        </Stack>
                    ))
                }
            </Stack>
        </Stack>
    )
}


// ---------------------------------------------------------------------------------------------------------------------
const isToday = (dayOfWeek: moment.Moment) => {
    return dayOfWeek.isSame(moment().startOf('day'), 'day')
}

const getMonthLabel = (timetable: TimetableDecorator, indexOfWeek: number) => {
    if (timetable?.getWeekNames().has(indexOfWeek)) {
        return timetable?.getWeekNames().get(indexOfWeek)
    } else {
        return ("Week " + (indexOfWeek + 1))
    }
}

const getDayOfWeekLabel = (timetable: TimetableDecorator, holidays: Holiday[], dayOfWeek: moment.Moment) => {
    let indexOfDay = -1
    if (timetable && timetable.getScheduling() === Scheduling.SHIFT) {
        indexOfDay = indexOfDayWithTimetable(dayOfWeek.clone(), timetable, holidays)
    }

    if (indexOfDay >= 0) {
        return dayOfWeek.format('ddd') + " - " + (indexOfDay + 1)
    } else {
        return dayOfWeek.format('ddd')
    }
}


// ---------------------------------------------------------------------------------------------------------------------
const LeftArrowButton = styled(Button)(({theme}) => ({
    padding: 0,
    height: '32px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: '0.5px',
    minWidth: '36px'
}));

const RightArrowButton = styled(Button)(({theme}) => ({
    padding: 0,
    height: '32px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: '0.5px',
    minWidth: '36px'
}));