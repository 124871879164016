import {createBrowserRouter, Navigate} from 'react-router-dom';
import LoginScreen from "./pages/LoginPage";
import Task from "./layouts/task/TaskLayout";
import TimetableLayout from "./layouts/timetable/TimetableLayout";
import SupportLayout from "./layouts/support/SupportLayout";
import PaymentsLayout from "./layouts/support/payments/PaymentsLayout";
import PermissionsLayout from "./layouts/support/permissions/PermissionsLayout";
import LandingPage from "./pages/LandingPage";
import PrivacyLayout from "./layouts/support/privacy/PrivacyLayout";
import HolidayLayout from "./layouts/holiday/HolidayLayout";
import CloudSyncLayout from "./layouts/support/cloud-sync/CloudSyncLayout";
import AuthenticatedApp from "./AuthenticatedApp";
import RedirectToApp from "./pages/RedirectToApp";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <LandingPage/>
    },
    {
        path: '/app',
        element: <AuthenticatedApp/>,
        children: [
            {element: <Navigate to="/app/agenda"/>, index: true},
            {path: 'agenda', element: <Task/>},
            {path: 'timetable', element: <TimetableLayout/>},
            {path: 'holidays', element: <HolidayLayout/>}
        ],
    },
    {
        path: '/login',
        element: <LoginScreen/>
    },
    {
        path: '/support',
        element: <SupportLayout/>,
        children: [
            {element: <Navigate to="/support/cloud-sync"/>, index: true},
            {path: 'cloud-sync', element: <CloudSyncLayout/>},
            {path: 'payments', element: <PaymentsLayout/>},
            {path: 'permissions', element: <PermissionsLayout/>},
            {path: 'privacy', element: <PrivacyLayout/>},
        ],
    },
    {
        path: '/join/:timetableId',
        element: <RedirectToApp />,
    },
])