import moment from "moment/moment";

export const MONGO_DATE_FORMAT = "YYYY-MM-DD"
export const MONGO_TIME_FORMAT = "HH:mm:ss"
export const MONGO_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss"

export const isTwelveHourClock = () => {
    return moment().format('LT').length > 5;
}

export const nextOrSameDay = (date: moment.Moment, dayOfWeek: number) => {
    if (dayOfWeek < 1 || dayOfWeek > 7) {
        throw new Error(`Invalid day of week: ${dayOfWeek}`);
    }

    if (date.isoWeekday() === dayOfWeek) {
        return date;
    }

    const weekDayToFind = moment().day(dayOfWeek).isoWeekday();
    let searchDate = date;
    while (searchDate.isoWeekday() !== weekDayToFind){
        searchDate = searchDate.add(1, 'day');
    }
    return searchDate;
}

export const getEpochDay = (date: moment.Moment) => {
    return moment(0).diff(date.clone().startOf('day'), 'days');
}