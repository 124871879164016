import {Button, DialogActions, DialogContentText, DialogTitle} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    open: boolean
    onClose: () => void
    onPositive: () => void
}

export default function TaskDeleteConfirmationDialog(props: Props) {
    const {t} = useTranslation()

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-delete-title"
            aria-describedby="alert-dialog-delete-description"
        >
            <DialogTitle id="alert-dialog-delete-title">
                {t("task.delete_dialog.title")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-delete-description">
                    {t("task.delete_dialog.content")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color='inherit' onClick={props.onClose}>{t("cancel")}</Button>
                <Button autoFocus onClick={props.onPositive}>{t("delete")}</Button>
            </DialogActions>
        </Dialog>
    )
}