import React, {useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';

const RedirectToApp = () => {
    const {timetableId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const getMobileOperatingSystem = () => {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // macOS
            if (navigator.platform.indexOf('Mac') !== -1) {
                return 'MacOS';
            }

            // Android
            if (/android/i.test(userAgent)) {
                return 'Android';
            }

            // iOS
            if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                return 'iOS';
            }

            return 'unknown';
        };

        const redirectToApp = () => {
            const os = getMobileOperatingSystem();
            let appStoreURL;

            if (os === 'iOS' || os === 'MacOS') {
                appStoreURL = 'https://apps.apple.com/app/id6474174333';
            } else if (os === 'Android') {
                appStoreURL = 'https://play.google.com/store/apps/details?id=daldev.android.gradehelper';
            } else {
                alert('Unsupported operating system.');
                return;
            }

            // Try to open the app using the custom URL scheme
            const start = new Date().getTime();
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            /*
            iframe.src = appScheme;
            */
            document.body.appendChild(iframe);

            setTimeout(() => {
                const end = new Date().getTime();
                if (end - start < 1500) {
                    // If the app is not installed, redirect to the appropriate app store
                    window.location.href = appStoreURL;
                }
                document.body.removeChild(iframe);
            }, 1000);
        };

        redirectToApp();
    }, [timetableId, navigate]);

    return <div>Redirecting...</div>;
};

export default RedirectToApp;